import { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  FormControl,
  FormLabel,
  Switch,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  Divider,
  Avatar,
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Center,
  Textarea,
  Icon,
} from '@chakra-ui/react';
import { useApi } from '../api'
import { ICargo, IChatMessage, IGuestbook, IProfile } from "../interfaces";
import { DateTime } from "luxon";
import { BottomNavigation } from '../components/BottomNavigation'
import { t } from 'i18next'
import { usePrivateApi } from '../hooks/usePrivateApi';
import { Utils } from '../utils';
import { LuTrash2, LuHistory, LuMessagesSquare, LuEyeOff } from 'react-icons/lu';
import { MdOutlineDeleteSweep } from 'react-icons/md';
import { LastChatroomMessageBlock } from '../components/LastChatroomMessageBlock';
import { useRecoilState } from 'recoil';
import { profileAtom } from '../stores/profile';

export const MessagePage = () => {
  const chatroomId = 'b3de29d2072b317fc5f8c87a5fbd9564';
  const navigate = useNavigate()
  const { projectCode } = useParams()
  const api = useApi(projectCode)
  const privateApi = usePrivateApi();
  const [guestbookItems, setGuestbookItems] = useState<[]>()
  const [messageItems, setMessageItems] = useState<[]>()
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [historyMessages, setHistoryMessages] = useState<IGuestbook[]>([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const [replyProfile, setReplyProfile] = useState<IProfile|null>(null)
  const [replyMessage, setReplyMessage] = useState<string>('')
  const [processing, setProcessing] = useState<boolean>(false)
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [confirmDel, setConfirmDel] = useState<boolean>(false)
  const [unreadChatroomMessageCount, setUnreadChatroomMessageCount] = useState<number>(0);

  const rollToBottom = () => {
    const history = document.getElementById('history')
    if (!history) return;
    if (window.localStorage.getItem('disableRollToBottom') === 'true') return;
    history.scrollTo({
      left: 0,
      top: history.scrollHeight,
      behavior: 'smooth'
    });
  }

  const loadHistories = (memberNo?: string, cb?: Function) => {
    if (!memberNo) return;
    privateApi.getGuestbooksHistories(memberNo, (cargo: ICargo) => {
      if (cb) {
        cb();
      }
      setHistoryMessages(cargo.info)
      setTimeout(rollToBottom, 100)
    })
    onOpen()
  }

  const getGuestBooks = useCallback(() => {
    api.getGuestbooks((cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        let myGuestbook = cargo.info
        const items = myGuestbook.map((guestbook: IGuestbook) => 
          <div
            key={`gbm_${guestbook['idGuestbook']}`}
            style={{whiteSpace: 'pre-wrap'}}
            className='p-3 '
          >
            <div className='flex justify-between'>
              <Avatar
                src={Utils().avatorUrlBuilder(guestbook.profile)}
                size={'sm'}
                onClick={() => {
                  navigate(`/${projectCode}/s?c=${guestbook['profile']['simpleCode']}`)
                }}
                className={`${(guestbook.profile.blur || guestbook.profile.blocked) ? 'blur-sm' : ''} cursor-pointer`} 
              />
              <div
                className='text-right text-sm '
              >
                {Utils().datetimeFormatter(guestbook.timelog)}
              </div>
            </div>
            <div className='text-gray-600 text-ellipsis overflow-y-scroll mt-1'>{guestbook['message']}</div>
            <div className='flex justify-end gap-3'>
              <div className='text-right text-sm italic mt-1 mb-3'>
                By {guestbook['profile']['nickname'] ? guestbook['profile']['nickname'] : '...'}
              </div>
            </div>
            <div className='flex justify-end gap-3'>
              <Icon
                as={LuMessagesSquare}
                fontSize={'2xl'}
                bgColor={'gray.400'}
                textColor={'white'}
                className="rounded-full p-1 cursor-pointer"
                onClick={() => {
                  setReplyProfile(guestbook.profile)
                }}
              />
              <Icon
                as={LuHistory}
                fontSize={'2xl'}
                bgColor={'gray.400'}
                textColor={'white'}
                className="rounded-full p-1 cursor-pointer"
                onClick={() => {
                  loadHistories(guestbook.profile?.simpleCode)
                }}
              />
              <Icon
                as={LuTrash2}
                fontSize={'2xl'}
                bgColor={'gray.400'}
                textColor={'white'}
                className="rounded-full p-1 cursor-pointer"
                onClick={() => {
                  if (!guestbook.idGuestbook) return;
                  toast({
                    title: '刪除訊息',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                  })
                  privateApi.delGuestbook(guestbook.idGuestbook, () => {
                    getGuestBooks()
                  })
                }}
              />
            </div>
            <div className='p-3' onDoubleClick={() => {
              window.navigator.clipboard.writeText(guestbook.message)
              toast({
                title: 'Copied',
                description: guestbook.message,
                status: 'success',
                duration: 1000,
                isClosable: true,
              })
            }} >
              <Divider />
            </div>
          </div>
        )
        setGuestbookItems(items)
      }
    })
  }, [api, navigate, projectCode])

  const getMessages = useCallback(() => {
    api.getMessages((cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        const items = cargo.info.map((message: any) => 
          <div
            key={message['idMessage']}
            style={{whiteSpace: 'pre-wrap'}}
            className='p-3 shadow-lg rounded-lg mt-[-5px] pt-5'
          >
            <div
              className='text-right text-xs text-gray-400'
            >
              {DateTime.fromISO(message['generateTime']).toISODate()}
            </div>
            <div className='text-gray-600 max-h-28 text-ellipsis overflow-y-scroll'>{message['message']}</div>
          </div>
        )
        setMessageItems(items)
      }
    })
  }, [api])

  const goChatroom = (userPath: string, chatroomId: string) => {
    navigate(`/${projectCode}/chatroom${userPath}/${chatroomId}`)
  }

  const fetchChatroomMessages = useCallback(() => {
    privateApi.getChatroomsMessages((cargo: ICargo) => {
      const profileSet = new Set();
      const localMemberNo = window.localStorage.getItem('memberNo');
      const items = cargo.info.map((chatroomMessage: IChatMessage) => {
        if (!localMemberNo || !chatroomMessage.profile?.simpleCode) return null;
        if (profileSet.has(chatroomMessage.profile?.idProfile)) return null;
        profileSet.add(chatroomMessage.profile?.idProfile)
        const userPath = chatroomMessage.chatroomId === chatroomId ? '' : `/${localMemberNo}/${chatroomMessage.profile?.simpleCode}`
        const chatMessage: IChatMessage = chatroomMessage;
        return (
          <div
            key={`chm_${chatroomMessage.idChatroomMessage}`}
            style={{ whiteSpace: 'pre-wrap' }}
            className="p-3 shadow-lg rounded-b-xl mt-[-15px] pt-5 cursor-pointer"
          >
            <div
              className="flex justify-between mt-3"
              onClick={() => {
                goChatroom(userPath, chatroomMessage.chatroomId);
              }}
            >
              <Avatar
                src={Utils().avatorUrlBuilder(chatroomMessage.profile)}
                size={'xs'}
                className={
                  replyProfile?.blur || replyProfile?.blocked
                    ? 'blur-sm mb-1'
                    : 'mb-1'
                }
              />
              <div
                className="text-right text-xs text-gray-400"
                onClick={() => {
                  goChatroom(userPath, chatroomMessage.chatroomId);
                }}
              >
                {Utils().datetimeFormatter(`${chatroomMessage.dateAdd}Z`)}
              </div>
            </div>
            <div className="text-stone-600 max-h-28 text-ellipsis overflow-y-scroll flex justify-between">
              {chatMessage.payload && (
                <div
                  className="w-full"
                  onClick={() => {
                    goChatroom(userPath, chatroomMessage.chatroomId);
                  }}
                >
                  {chatMessage.payload.type === 'text' && (
                    <div>{chatMessage.payload?.content}</div>
                  )}
                  {chatMessage.payload.type === 'emoji' &&
                    chatMessage.payload.unified && (
                      <span>{chatMessage.payload.emoji}</span>
                    )}
                  {chatMessage.payload.type === 'img' && (
                    <img
                      className="flex flex-row-revers rounded-xl w-1/2 md:w-1/4 lg:w-1/6"
                      src={Utils().normalizeImagePath(
                        chatMessage.payload.src,
                        projectCode
                      )}
                    />
                  )}
                </div>
              )}
              {!chatMessage.payload && (
                <span
                  onClick={() => {
                    goChatroom(userPath, chatroomMessage.chatroomId);
                  }}
                >
                  {chatroomMessage.message}
                </span>
              )}
              <div className="flex justify-end gap-3">
                <Icon
                  as={MdOutlineDeleteSweep}
                  fontSize={'2xl'}
                  bgColor={'gray.400'}
                  textColor={'white'}
                  className="rounded-full p-1 cursor-pointer"
                  onClick={() => {
                    if (!chatroomMessage.idChatroomMessage) return;
                    toast({
                      title: '刪除訊息',
                      status: 'error',
                      duration: 3000,
                      isClosable: true,
                      position: 'top',
                    });
                    privateApi.delChatroomMessage(
                      chatroomMessage.idChatroomMessage,
                      true,
                      () => {
                        fetchChatroomMessages();
                      }
                    );
                  }}
                />
                <Icon
                  as={LuEyeOff}
                  fontSize={'2xl'}
                  bgColor={'gray.400'}
                  textColor={'white'}
                  className="rounded-full p-1 cursor-pointer"
                  onClick={() => {
                    if (!chatroomMessage.idChatroomMessage) return;
                    toast({
                      title: '刪除訊息',
                      status: 'error',
                      duration: 3000,
                      isClosable: true,
                      position: 'top',
                    });
                    privateApi.delChatroomMessage(
                      chatroomMessage.idChatroomMessage,
                      false,
                      () => {
                        fetchChatroomMessages();
                      }
                    );
                  }}
                />
              </div>
            </div>
          </div>
        );
      })
      setMessageItems(items);
    })
  }, [privateApi])

  // useEffect(() => {
  //   if (!guestbookItems) {
  //     getGuestBooks();
  //   }
  // }, [getGuestBooks, guestbookItems])

  useEffect(() => {
    api.getProfile((cargo: ICargo) => {
      setProfile(cargo.info)
    })
    if (window.localStorage.getItem('lastMessagePageTab') === 'message') {
      fetchChatroomMessages();
      setTabIndex(1);
    } else {
      getGuestBooks();
      setTabIndex(0);
    }
    privateApi.getUnreadChatroomMessageCount((cargo:ICargo) => {
      if (cargo.success) {
        setUnreadChatroomMessageCount(cargo.info);
      }
    })
  }, [])

  return (
    <>
      {/* <Flex>
        <Box maxW='36'>
          <h1 className="text-3xl font-bold">
          <Logo></Logo>
          </h1>
        </Box>
        <Box p='4'>
          <h1 className="text-3xl font-bold">&nbsp;</h1>
        </Box>
        <Spacer />
        <Box p='4'>
        </Box>
      </Flex> */}
      <LastChatroomMessageBlock />
      <div className="p-3">
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="email-alerts" mb="0" className='p-1'>
            {t('enableEmailAlert')}
          </FormLabel>
          <Spacer />
          {profile && <Switch
            id="email-alerts"
            size={'lg'}
            colorScheme={'yellow'}
            isDisabled={!profile}
            isChecked={profile && !profile.muted}
            onChange={(e) => {
              api.updateMuted(!e.target.checked);
              setProfile({ ...profile, muted: !e.target.checked });
              if (e.target.checked) {
                if (window.Notification) {
                  Notification.requestPermission();
                }
                if (!('serviceWorker' in navigator)) {
                  // Service Worker isn't supported on this browser, disable or hide UI.
                  return;
                }

                if (!('PushManager' in window)) {
                  // Push isn't supported on this browser, disable or hide UI.
                  return;
                }
              }
            }}
          />}
        </FormControl>
      </div>
      <Tabs
        isFitted
        variant="enclosed"
        className="shadow rounded-b-xl mb-5"
        index={tabIndex}
      >
        <TabList mb="1em">
          <Tab
            onClick={() => {
              setTabIndex(0);
              getGuestBooks();
              window.localStorage.setItem('lastMessagePageTab', 'guestbook');
            }}
          >
            {t('guestbook')}
          </Tab>
          <Tab
            onClick={() => {
              setTabIndex(1);
              fetchChatroomMessages();
              window.localStorage.setItem('lastMessagePageTab', 'message');
            }}
            className=" relative"
          >
            {t('notification')}
            {unreadChatroomMessageCount > 0 && (
              <span className="absolute right-0 bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                New
              </span>
            )}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>{guestbookItems}</TabPanel>
          <TabPanel>
            <Button
              colorScheme={confirmDel ? 'red' : 'white'}
              shadow="lg"
              color={confirmDel ? 'white' : 'green.900'}
              width="100%"
              onClick={() => {
                if (!confirmDel) {
                  setConfirmDel(true);
                } else {
                  setConfirmDel(false);
                  privateApi.deleteChatroomMessagesByChatroomId(
                    chatroomId,
                    () => {
                      fetchChatroomMessages();
                    }
                  );
                }
              }}
            >
              {confirmDel ? '再按一次清除' : '清空聊天室通知'}
            </Button>
            {messageItems}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Drawer isOpen={isOpen} placement="bottom" size="full" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader className="mb-3"></DrawerHeader>

          <DrawerBody id="history">
            <div>
              {historyMessages.map((guestbook) => {
                return (
                  <div
                    className="p-3 rounded-lg mb-2 text-sm text-gray-600 border whitespace-pre-wrap"
                    key={guestbook.idGuestbook}
                  >
                    <span className="bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400">
                      {guestbook.profile.nickname}
                    </span>
                    <div className="mt-3 bg-slate-100 p-3 rounded-lg">
                      {guestbook.message}
                    </div>
                    <div className="text-right text-xs mt-1">
                      {Utils().datetimeFormatter(guestbook.timelog)}
                    </div>
                  </div>
                );
              })}
            </div>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              {t('close')}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Modal
        isOpen={replyProfile !== null}
        onClose={() => setReplyProfile(null)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Avatar
              src={Utils().normalizeImagePath(
                replyProfile?.avator,
                projectCode
              )}
              className={
                replyProfile?.blur || replyProfile?.blocked ? 'blur-sm' : ''
              }
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Textarea
                onChange={(e) => {
                  setReplyMessage(e.target.value);
                }}
                value={replyMessage}
                rows={10}
              />
            </Center>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="teal"
              variant="outline"
              onClick={() => {
                if (!replyProfile?.simpleCode) return;
                setProcessing(true);
                api.leaveMessage(
                  replyProfile.simpleCode,
                  replyMessage,
                  (cargo: ICargo) => {
                    setProcessing(false);
                    if (cargo.success) {
                      setReplyMessage('');
                      loadHistories(replyProfile.simpleCode, () => {
                        setReplyProfile(null);
                      });
                    }
                  }
                );
              }}
              isLoading={processing}
            >
              送出
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <div className="h-[130px]"></div>
      <BottomNavigation projectCode={projectCode}></BottomNavigation>
    </>
  );
};