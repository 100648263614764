import { AddIcon } from "@chakra-ui/icons";
import { Flex, Spacer, Button, Box, CloseButton, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, useToast } from "@chakra-ui/react";
import { Translation } from "react-i18next";
import { BottomNavigation } from "../../components/BottomNavigation";
import { useProjectCode } from "../../hooks/useProjectCode";
import { useEffect, useState } from "react";
import { t } from "i18next";
import { HintBlock } from "../../components/HintBlock";
import { ImageUploader } from "../../components/ImageUploader";
import { ICargo } from "../../interfaces";
import { useQuery } from "../../hooks/useQuery";
import { PrivateCrudApi } from "../../apis/private-crud-api";
import { IPhoto } from "../../interfaces/IPhoto";
import { useParams } from "react-router-dom";
import { IAlbum } from "../../interfaces/IAlbum";
import { ImageBox } from "./ImageBox";
import { MdOutlineCopyAll } from 'react-icons/md';

export const AlbumManagementPage = () => {
  const isHalfLayout = false;
  const projectCode = useProjectCode();
  const { idAlbum } = useParams();
  const { secretKey } = useQuery();
  const [showDrawer, setDrawer] = useState<boolean>(false);
  const albumApi = PrivateCrudApi('sns', 'albums');
  const photoApi = PrivateCrudApi('sns', 'photos');
  const [album, setAlbum] = useState<IAlbum>();
  const [photos, setPhotos] = useState<IPhoto[]>([]);
  const [publicUrl, setPublicUrl] = useState<string>();
  const toast = useToast();

  useEffect(() => {
    if (!idAlbum) return;
    albumApi.getById(idAlbum).then(data => {
      setAlbum(data as IAlbum);
    })
    setPublicUrl(
      `${window.location.protocol}//${
        window.location.host
      }/${projectCode.toLocaleLowerCase()}/albums/${idAlbum}`
    );
  }, [idAlbum])

  useEffect(() => {
    if(!album) return;
    if(!album.photos) return;

    setPhotos(album.photos)
  }, [album])

  return (
    <>
      <Flex className="justify-end">
        <Box p="4">
          <Button
            rightIcon={<AddIcon />}
            colorScheme="teal"
            variant="outline"
            onClick={() => setDrawer(true)}
          >
            <Translation>{(t) => t('uploadPhoto')}</Translation>
          </Button>
        </Box>
      </Flex>
      <div className="p-2 shadow-lg mb-3 rounded-lg text-gray-500">
        <div className="mb-1">
          <div className="flex items-center gap-3">
            <div>相簿位址</div>
            {publicUrl && <MdOutlineCopyAll className="cursor-pointer" onClick={() => {
              window.navigator.clipboard.writeText(publicUrl);
              toast({
                title: 'Copied',
                description: publicUrl,
                status: 'success',
                duration: 1000,
                isClosable: true,
              });
            }} />}
          </div>
        </div>
        <div className="cursor-pointer" onClick={() => {
          window.location.href = `${publicUrl}`
        }}>{publicUrl}</div>
      </div>
      <div>
        <div
          className={`gap-3 ${
            isHalfLayout
              ? 'columns-2 lg:columns-3'
              : 'columns-2 md:columns-4 lg:columns-6'
          }`}
        >
          {photos.map((photo) => {
            return (
              <ImageBox
                key={photo.idPhoto}
                photo={photo}
                secretKey={secretKey}
                onDeleted={(deletedPhoto) => {
                  setPhotos(
                    photos.filter((p) => p.idPhoto != deletedPhoto.idPhoto)
                  );
                }}
              />
            );
          })}
        </div>
      </div>
      <Drawer
        placement={'bottom'}
        onClose={() => setDrawer(false)}
        isOpen={showDrawer}
      >
        <DrawerOverlay />
        <DrawerContent className="rounded-t">
          <DrawerHeader borderBottomWidth="1px">
            <Flex>
              <Spacer></Spacer>
              <CloseButton onClick={() => setDrawer(false)} />
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            {idAlbum && (
              <ImageUploader
                onUploaded={(cargo: ICargo) => {
                  const file: string = cargo.info['file'];
                  setDrawer(false);
                  photoApi
                    .create<IPhoto>({
                      idAlbum: Number(idAlbum),
                      filePath: `${projectCode.toLocaleLowerCase()}/${file}`,
                    })
                    .then((data) => {
                      setPhotos([...photos, data as IPhoto]);
                    });
                }}
                secretKey={secretKey}
              />
            )}
            <HintBlock firstLine="圖片上傳完畢前，請勿關閉畫面" />
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" onClick={() => setDrawer(false)}>
              {t('close')}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <BottomNavigation projectCode={projectCode}></BottomNavigation>
    </>
  );
}