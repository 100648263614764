import { Avatar, Skeleton } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { IChatMessage, ICargo } from "../interfaces";
import { Utils } from "../utils";
import { useProjectCode } from "../hooks/useProjectCode";
import { usePublicApi } from "../hooks/usePublicApi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const LastChatroomMessageBlock = () => {
  const chatroomId = 'b3de29d2072b317fc5f8c87a5fbd9564';
  const { t } = useTranslation();
  const projectCode = useProjectCode();
  const publicApi = usePublicApi();
  const navigate = useNavigate();
  const [lastChatroomMessage, setLastChatroomMessage] = useState<IChatMessage>();
  useEffect(() => {
    publicApi.getLastChatroomLastMessage(chatroomId, (cargo: ICargo) => {
      setLastChatroomMessage(cargo.info);
    })
  }, [])
  return (
    <>
      {lastChatroomMessage &&
        !lastChatroomMessage.message.includes('emoji') &&
        !lastChatroomMessage.message.includes('img') && (
          <div className="my-10">
            <div
              className="inline-flex items-center bg-yellow-400 border-yellow-400 border-4 rounded-full w-full overflow-hidden whitespace-nowrap cursor-pointer"
              onClick={() => {
                navigate(
                  `/${projectCode.toLowerCase()}/public-chat/${
                    lastChatroomMessage.chatroomId
                  }`
                );
              }}
            >
              <Avatar
                src={Utils().avatorUrlBuilder(lastChatroomMessage.profile)}
                size={'xs'}
              />
              <div className="ml-1 select-none">
                {lastChatroomMessage.message}
              </div>
            </div>
            <div className="px-3 text-xs text-gray-500 text-right">
              {t('latestChatroomMessage')}
            </div>
          </div>
        )}
      {lastChatroomMessage === undefined && (
        <div className="my-10">
          <Skeleton height="32px" startColor="gray.300" endColor="gray.100" />
          <div className="h-[1px]"></div>
          <Skeleton height="16px" startColor="gray.400" endColor="gray.200" />
        </div>
      )}
    </>
  );
}