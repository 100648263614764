import { Button } from "@chakra-ui/react"

interface WhiteButtonProps {
  text: string,
  onClick?: () => void
}
export const WhiteButton = ({text, onClick}: WhiteButtonProps) => {
  return (
    <Button
      className='w-full rounded-xl'
      colorScheme='white'
      textColor={'black'}
      shadow={'lg'}
      rounded={'xl'}
      onClick={onClick}
    >
      {text}
    </Button>
  )
}