import { Image, Card, CardBody, Stack, Heading, Divider, CardFooter, ButtonGroup, Button, Text, Avatar, Box, Center, Flex, Spacer, VStack, AvatarGroup, HStack } from "@chakra-ui/react"
import { IProfile } from "../../interfaces"
import { CheckCircleIcon, Icon } from "@chakra-ui/icons";
import { Utils } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { LuGavel } from "react-icons/lu";
import { PrivateCrudApi } from "../../apis/private-crud-api";
import { useProjectCode } from "../../hooks/useProjectCode";
import { PublicCrudApi } from "../../apis/public-crud-api";
import { IBidding } from "../../interfaces/IBidding";

interface MarketCardProps {
  profile: IProfile,
  onPurchased?: () => void,
  onBidding?:() => void
}
export const MarketCard = ({ profile, onPurchased, onBidding }: MarketCardProps) => {
  const navigate = useNavigate();
  const projectCode = useProjectCode();
  if (!profile.nickname || !profile.introduction) return null;
  const [expand, setExpand] = useState<boolean>(false);
  const purchaseApi = PrivateCrudApi('game', 'market/slave/purchase');
  const biddingLoopApi = PrivateCrudApi('game', 'bidding-loops');
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [confirmedBidding, setConfirmedBidding] = useState<boolean>(false);
  const publicBiddingLoopApi = PublicCrudApi('game', 'bidding-loops');
  const [biddings, setBiddings] = useState<IBidding[]>([]);

  useEffect(() => {
    if (!profile.simpleCode) return;
    publicBiddingLoopApi
      .getById(profile.simpleCode)
      .then(data => {
        setBiddings(data as IBidding[])
      })
  }, [])

  return (
    <Card rounded={'2xl'} shadow={'xl'}>
      <CardBody>
        <Flex>
          {/* <Avatar src={Utils().normalizeImagePath(profile.avator, profile.projectCode)} /> */}
          {/* <Avatar size={'xl'} src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" /> */}
          <Box maxW='36'>
            <h1 className="text-3xl font-bold">
              <Center className='m-5'>
                <Box>
                  <Image
                    src={Utils().avatorUrlBuilder(profile)}
                    key={`${profile.idProfile}`}
                    className="cursor-pointer rounded-lg border border-red-600 shadow-lg w-full"
                    onClick={() => {
                      navigate(`/${profile.projectCode?.toLowerCase()}/share/${profile.simpleCode}`)
                    }}
                  />
                  {profile?.kycStatus === 1 && <CheckCircleIcon color={'green.400'} className='absolute top-7 left-7 bg-white rounded-full' />}
                </Box>
              </Center>
            </h1>
          </Box>
          <Spacer />
          <Box p='4' textAlign={"center"}>
            <div className="rounded-lg border px-3 pt-1">
              <Text fontSize={'xs'}>競標次數</Text>
              <Text color='red.600' fontSize='2xl'>
                {profile.biddingCount}
              </Text>
            </div>
          </Box>
        </Flex>
        <Stack mt='0' spacing='3'>
          <Heading size='md'>{profile.nickname}</Heading>
          {/* <div className="p-3 bg-stone-200 rounded-lg">
            <Text className={`text-sm whitespace-pre-wrap text-stone-600 cursor-pointer ${expand ? 'line-clamp-none' : 'line-clamp-6'}`} onClick={() => setExpand(expand => !expand)}>
              {profile.introduction}
            </Text>
          </div> */}
        </Stack>
        {profile.lastBidding && <div className="mt-3 bg-stone-200 rounded-lg p-3">
          <div className="flex justify-between items-baseline">
            <VStack spacing={0} align={'start'}>
              <Text fontSize={'xs'}>投標人</Text>
              <Divider className="my-1"></Divider>
              <HStack>
                <Avatar size={'sm'} src={Utils().normalizeImagePath(profile.lastBidding?.profile.avator, projectCode)} />
                <AvatarGroup size='sm' max={5}>
                  {
                    biddings.slice(1, biddings.length).map(bidding => {
                      if (!bidding.profile.avator) return null;
                      return <Avatar key={bidding.idBiddingLoop} src={Utils().avatorUrlBuilder(bidding.profile)} />
                    })
                  }
                </AvatarGroup>
              </HStack>
            </VStack>
            {/* <VStack spacing={0}>
              <Text fontSize={'xs'}>直購價</Text>
              <Text color='red.600' fontSize='2xl'>
                $ {profile.mcoin}
              </Text>
            </VStack> */}
            
            <VStack spacing={0} align={'center'}>
              <Text fontSize={'xs'}>結標時間</Text>
              <Divider className="my-1"></Divider>
              <Text color='red.600' fontSize='xs'>
                {Utils().datetimeFormatter(`${profile.lastBidding?.dueDate}Z`).split(' ')[0]}
              </Text>
              <Text color='red.600' fontSize='xs'>
                {Utils().datetimeFormatter(`${profile.lastBidding?.dueDate}Z`).split(' ')[1]}
              </Text>
            </VStack>
          </div>
        </div>}
      </CardBody>
      <Divider />
      <CardFooter className="justify-end">
        <ButtonGroup spacing='2'>
          {/* <Button variant='outline' colorScheme='teal' rightIcon={<ArrowForwardIcon />} onClick={() => {
            navigate(`/${profile.projectCode?.toLowerCase()}/s?c=${profile.simpleCode}`)
          }}>
            More
          </Button> */}
          {/* <Button variant={confirmed ? 'solid' : 'outline'} colorScheme='teal' rightIcon={<Icon as={LuDollarSign} />} onClick={() => {
            if (!confirmed) {
              setConfirmed(true)
            } else {
              purchaseApi.create({simpleCode: profile.simpleCode}).then(() => {
                if (onPurchased) {
                  onPurchased()
                }
                setConfirmed(false)
              })
            }
          }}>
            {confirmed ? '再點一次' : '直接購買'}
          </Button> */}
          <Button variant={confirmedBidding ? 'solid' : 'outline'} colorScheme='red' rightIcon={<Icon as={LuGavel} />} onClick={() => {
            
            if (!confirmedBidding) {
              setConfirmedBidding(true)
            } else {
              setConfirmedBidding(false)
              biddingLoopApi.create({ targetMemberNo: profile.simpleCode }).then(() => {
                if (onBidding) {
                  onBidding()
                }
              })
            }
          }}>
            {confirmedBidding ? '再點一次' : '我要競標'}
          </Button>
        </ButtonGroup>
      </CardFooter>
    </Card>
  )
}