import { useEffect, useRef, useState } from "react";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { IPosition } from "../../interfaces/IPosition";
import { IGeolocation } from "../../interfaces/IGeolocation";
import { useMapKey } from "../../hooks/useMapKey";
import { createRoot } from "react-dom/client";
import { AvatorMarker } from "../../components/AvatorMarker";
import { Utils } from "../../utils";
import { INearby } from "../../interfaces/INearby";
import { Modal, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Avatar, Divider, ModalOverlay } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useProjectCode } from "../../hooks/useProjectCode";
import { useRecoilValue } from "recoil";
import { profileAtom } from "../../stores/profile";

interface BottomDrawerProps {
  nearbyList: INearby[];
  geolocation?: IGeolocation;
  isOpen: boolean;
  onClose?: () => void;
}

export const BottomDrawer = ({
  nearbyList,
  geolocation,
  isOpen,
  onClose
}: BottomDrawerProps) => {
  const mapContainer = useRef<any>();
  const map = useRef<mapboxgl.Map | null>(null);
  const mapKey = useMapKey();
  const [currentPosition, setCurrentPosition] = useState<IPosition>();
  const [selectedNearby, setSelectedNearby] = useState<INearby | null>(null);
  const [hasGps, setHasGps] = useState<boolean>(true);
  const navigate = useNavigate();
  const projectCode = useProjectCode();
  const [collapse, setCollapse] = useState<boolean>(true);
  const [showMore, setShowMore] = useState<boolean>(false);
  const profile = useRecoilValue(profileAtom);

  useEffect(() => {
    setCollapse(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (collapse && onClose) {
      onClose();
    }
  }, [collapse])

  useEffect(() => {
    if (selectedNearby === null) {
      setShowMore(false);
    }
  }, [selectedNearby]);

  useEffect(() => {
    if (map.current || !currentPosition) return;
    mapboxgl.accessToken = mapKey;
    map.current = new mapboxgl.Map({
      container: mapContainer.current, // container ID
      style: 'mapbox://styles/mapbox/light-v11', // style URL
      center: [
        currentPosition?.coordinations.longitude,
        currentPosition?.coordinations.latitude,
      ], // starting position [lng, lat]
      zoom: 8, // starting zoom
      pitch: 0,
    });
    let lng = currentPosition?.coordinations.longitude;
    let lat = currentPosition?.coordinations.latitude;
    if (
      window.localStorage.getItem('restoreLatitude') &&
      window.localStorage.getItem('restoreLongitude')
    ) {
      lng = Number(window.localStorage.getItem('restoreLongitude'));
      lat = Number(window.localStorage.getItem('restoreLatitude'));
      window.localStorage.removeItem('restoreLatitude');
      window.localStorage.removeItem('restoreLongitude');
    }
  }, [currentPosition]);

  useEffect(() => {
    let lng = currentPosition?.coordinations.longitude;
    let lat = currentPosition?.coordinations.latitude;
    if (map.current && lng && lat) {
       map.current.flyTo({
         center: [lng, lat],
         zoom: 14,
         bearing: 0,
         pitch: 60,
       });
       const path = Utils().avatorUrlBuilder(profile);
       if (path && lng && lat) {
         const el = document.createElement('div');
         el.className = 'marker';
         const marker = createRoot(el);
         marker.render(
           <AvatorMarker
             size={48}
             onClick={() => {
               map.current?.flyTo({
                 center: [Number(lng), Number(lat)],
               });
             }}
             path={path}
             alt={'My'}
           />
         );
         new mapboxgl.Marker(el).setLngLat([lng, lat]).addTo(map.current);
       }
    }
  }, [currentPosition])

  useEffect(() => {
    if (!nearbyList) return;
    nearbyList
      .sort((a, b) => a.rank - b.rank)
      .map((nearby) => {
        const pubProfile = nearby.profile;
        const avatorPath = Utils().avatorUrlBuilder(pubProfile);
        if (avatorPath && map.current && nearby.latitude <= 90 && nearby.latitude >= -90 && nearby.longitude <= 180 && nearby.longitude >= -180) {
          let size = pubProfile.blocked ? 0 : pubProfile.blur ? 10 : !pubProfile.qualify ? 20 : 30 + Math.floor(nearby.rank / 10);
          const el = document.createElement('div');
          el.className = 'marker';
          const marker = createRoot(el);
          marker.render(
            <AvatorMarker
              size={size}
              path={avatorPath}
              alt={pubProfile?.nickname}
              onClick={() => {
                map.current?.flyTo({
                  center: [nearby.longitude, nearby.latitude],
                  zoom: 12,
                });
                setSelectedNearby(nearby);
              }}
            />
          );
          new mapboxgl.Marker(el)
            .setLngLat([Number(nearby.longitude), Number(nearby.latitude)])
            .addTo(map.current);
        }
      });
  }, [nearbyList])

  useEffect(() => {
    if (!geolocation) return;
    if (!geolocation.latitude || !geolocation.longitude) return;
    setCurrentPosition({
      timestamp: Utils().getTimestamp(),
      coordinations: {
        latitude: geolocation.latitude,
        longitude: geolocation.longitude,
      },
    });
  }, [geolocation]);

  return (
    <>
      <div
        className={`fixed bg-black bg-opacity-70 top-0 left-0 w-full ${
          !collapse ? 'h-full' : ''
        }`}
        onClick={(e: any) => {
          if (e.target.role === 'backdrop') {
            setCollapse(true);
          }
        }}
        role="backdrop"
      >
        <div
          id="drawer-swipe"
          className={`fixed z-40 w-full h-full overflow-y-auto bg-slate-100 border-t border-gray-200 rounded-t-2xl duration-300 left-0 right-0 translate-y-full ${
            collapse ? 'bottom-[90px]' : 'bottom-[80%]'
          }`}
          tabIndex={-1}
        >
          <div
            className="p-4 cursor-pointer hover:bg-gray-50"
            onClick={() => {
              setCollapse((collapse) => !collapse);
            }}
          >
            <span className="absolute w-20 h-1 -translate-x-1/2 bg-gray-300 rounded-lg top-3 left-1/2 dark:bg-gray-600"></span>
          </div>
          <div
            ref={mapContainer}
            className="map-container"
            style={{
              position: 'fixed',
              top: '30px',
              width: '100%',
              height: `${window.innerHeight - 140}px`,
              left: '0',
            }}
          />
        </div>
      </div>
      <Modal
        size={'xs'}
        isOpen={!!selectedNearby}
        onClose={() => {
          if (selectedNearby) {
            map.current?.flyTo({
              center: [selectedNearby.longitude, selectedNearby.latitude],
              zoom: 9,
            });
          }
          setSelectedNearby(null);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Avatar
              src={Utils().avatorUrlBuilder(selectedNearby?.profile)}
              className="cursor-pointer"
              onClick={() => {
                navigate(
                  `/${projectCode}/share/${selectedNearby?.profile.simpleCode}`
                );
              }}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="mt-[-20px] mb-3">
              <div>{selectedNearby?.profile.nickname}</div>
              <Divider />
            </div>
            <div
              className={`whitespace-pre-wrap text-xs text-gray-500 mb-3 cursor-pointer ${
                !showMore ? 'line-clamp-6' : 'max-h-72 overflow-y-scroll pb-5'
              }`}
              onClick={() => {
                setShowMore(true);
              }}
            >
              {selectedNearby?.profile.introduction}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};