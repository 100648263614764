import { UnlockIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Spacer, Textarea } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Translation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useApi } from "../api"
import { BottomNavigation } from "../components/BottomNavigation"
import { ImageUploader } from "../components/ImageUploader"
import { Logo } from "../components/Logo"
import { ICargo, IMission } from "../interfaces"
import { Utils } from "../utils"

export const MissionPage = () => {
    const { projectCode, idMission } = useParams()
    const navigate = useNavigate()
    const api = useApi(projectCode)
    const [isLoading, setIsLoading] = useState(false)
    const [subject, setSubject] = useState('')
    const [description, setDescription] = useState('')
    const [cover, setCover] = useState<string|null>(null)
    const [mission, setMission] = useState<IMission>()
    useEffect(() => {
        if (idMission && !mission) {
            api.getMyMission(idMission, (cargo: ICargo) => {
                if(cargo.returnCode === '000000') {
                    setMission(cargo.info);
                    setSubject(cargo.info['subject'])
                    setDescription(cargo.info['description'] === null ? '' : cargo.info['description'])
                    if (cargo.info['cover']) {
                        if (cargo.info['cover'].indexOf('http') > -1) {
                            setCover(cargo.info['cover'])
                        } else {
                            setCover(`https://bdsmtw.club/files/${projectCode?.toLowerCase()}/${cargo.info['cover']}`)
                        }
                    }
                }
            })
        }
    }, [api, idMission, mission]);
    
    const save = () => {
        setIsLoading(true)
        if (idMission) {
            api.saveMission(Number(idMission), description, (cargo: ICargo) => {
                setIsLoading(false)
            })
        }
    }
    return (
        <>
            <Flex>
                <Box maxW='36'>
                    <h1 className="text-3xl font-bold">
                        <Logo></Logo>
                    </h1>
                </Box>
                <Spacer />
                <Box p='4'>
                    {
                        mission?.status === 'P' && <Button
                            rightIcon={<UnlockIcon />}
                            colorScheme='teal'
                            variant='outline'
                            onClick={() => {
                                if (idMission) {
                                    setIsLoading(true)
                                    api.submitMission(Number(idMission), description, (cargo: ICargo) => {
                                        setIsLoading(false)
                                        mission.status = 'F'
                                        setMission(mission)
                                    })
                                }
                            }}
                        >
                            <Translation>
                                {t => (t('submitToReview'))}
                            </Translation>
                        </Button>
                    }
                </Box>
            </Flex>
            <div className='my-3 font-bold p-3'>{subject}</div>
            <div className='p-3 shadow-lg rounded-lg'>
                {
                    mission?.status === 'P' ? <Textarea
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }}
                    value={description}
                    rows={10}
                    placeholder='Please provide your feedback'
                /> : <div className='p-3 whitespace-pre-wrap'>{ description }</div>
                }
            </div>
            <div className='my-3 p-5 shadow-lg rounded-lg text-center'>
                {
                    cover === null ? <ImageUploader onUploaded={(cargo: ICargo) => {
                        if(cargo.returnCode === '000000') {
                            if (idMission) {
                                api.saveMissionCover(Number(idMission), cargo.info['file'])
                            }
                        }
                    }}></ImageUploader>
                    : <img className='rounded-lg' src={cover} alt='' />
                }
            </div>
            <div className='p-3 shadow-lg rounded-lg'>
                <div className='p-3 whitespace-pre-wrap'>{ mission?.remark }</div>
            </div>
            <div className='pt-3'>
                <Flex>
                    <Button
                        isLoading={isLoading}
                        colorScheme={'white'}
                        shadow="lg"
                        color={'green.900'}
                        width="100%"
                        onClick={() => {
                            navigate(`/${projectCode}/task/1`)
                        }}
                    >
                        <Translation>
                            {t => (t('back'))}
                        </Translation>
                    </Button>
                    {
                        mission?.status === 'P' && <Button
                            isLoading={isLoading}
                            colorScheme={'white'}
                            shadow="lg"
                            color={'green.900'}
                            width="100%"
                            onClick={save}
                        >
                            <Translation>
                                {t => (t('save'))}
                            </Translation>
                        </Button>
                    }
                </Flex>
            </div>
            <div className="h-[100px]"></div>
            <BottomNavigation projectCode={projectCode}></BottomNavigation>
        </>
    )
}