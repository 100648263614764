import { atom } from "recoil";
import { IFlame, IProfile } from "../interfaces";

export const profileAtom = atom<IProfile | null>({
  key: "profile",
  default: null
})

export const referralCodeAtom = atom<string>({
  key: "referralCode",
  default: "",
});

export const memberNoAtom = atom<string>({
  key: "memberNo",
  default: "",
});

export const avatorAtom = atom<string>({
  key: "avator",
  default: "",
});

export const nicknameAtom = atom<string>({
  key: "nickname",
  default: "",
});

export const flameAtom = atom<IFlame | null>({
  key: "lastFlame",
  default: null
})
