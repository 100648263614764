import { useNavigate } from "react-router-dom";
import { useProjectCode } from "../hooks/useProjectCode";
import { IProfile } from "../interfaces";
import { Utils } from "../utils";

interface HotProfilesGridProps {
  hotProfiles: IProfile[]
}
export const HotProfilesGrid = ({ hotProfiles }: HotProfilesGridProps) => {
  const projectCode = useProjectCode();
  const navigate = useNavigate();
  return (
    <div className='my-5'>
      <div className='grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2'>
        {hotProfiles.map(profile => {
          if (!profile.avator) return null;
          if (profile.blur && Number(profile.multiGender) % 2 === 1) return null;
          let badgeColor = 'bg-green-700';
          if (Number(profile.multiGender) % 2 === 1) {
            badgeColor = 'bg-blue-700';
          }
          if (Number(profile.multiGender) % 2 === 0) {
            badgeColor = 'bg-red-700';
          }
          return (
            <div
              key={profile.idProfile}
              className="basis-1/3 shadow-lg rounded-lg pt-3 px-3 cursor-pointer"
              onClick={() => {
                navigate(
                  `/${projectCode.toLowerCase()}/s?c=${profile.simpleCode}`
                );
              }}
            >
              <div className="relative inline-flex w-fit">
                <div className="absolute bottom-auto left-auto right-1 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap text-center align-baseline text-xs font-bold leading-none text-white">
                  {(profile.multiGender === '1' ||
                    profile.multiGender === '2') && (
                    <span className={`${badgeColor} px-2.5 py-1 rounded-full`}>
                      S
                    </span>
                  )}
                  {(profile.multiGender === '3' ||
                    profile.multiGender === '4') && (
                    <span className={`${badgeColor} px-2.5 py-1 rounded-full`}>
                      M
                    </span>
                  )}
                  {(profile.multiGender === '5' ||
                    profile.multiGender === '6') && (
                    <span className={`${badgeColor} px-2.5 py-1 rounded-full`}>
                      SW
                    </span>
                  )}
                </div>
                <div className="mt-1">
                  <img
                    className={`rounded-sm ${
                      profile.blur ? 'blur' : ''
                    } shadow-inner w-full`}
                    src={Utils().avatorUrlBuilder(profile)}
                    alt={`${profile.idProfile}`}
                  />
                  <div className="text-xs text-gray-400 pl-1 line-clamp-1">
                    {profile.nickname}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  )
}