import { Icon, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PrivateCrudApi } from "../../apis/private-crud-api";
import { IBlock } from "../../interfaces/IBlock";
import { LuBan, LuUserX } from "react-icons/lu";
import { Utils } from "../../utils";
import { IProfile } from "../../interfaces";

interface BlockBoxProps {
  idProfile?: number;
  profile?: IProfile;
  blockCountList: boolean[];
  targetMemberNo: string;
  onBlocked?: () => void
}
export const BlockBox = ({ idProfile, profile, blockCountList, targetMemberNo, onBlocked }: BlockBoxProps) => {
  const blockApi = PrivateCrudApi('sns', 'blocks');
  const profileApi = PrivateCrudApi('core', `profiles/unqualified`)
  const toast = useToast();
  const [enableBlockBtn, setEnableBlockBtn] = useState<boolean>(false);
  const [tapCounter, setTapCounter] = useState<number>(0);

  const showToast = () => {
    toast({
      title: '回報封鎖',
      description: "我們收到您的回報嘍，若該用戶被檢舉次數過多，系統將自動停止該用戶使用權限。",
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }

  useEffect(() => {
    if (idProfile && tapCounter === 5 && profile) {
      const avatorPath = Utils().avatorUrlBuilder(profile);
      if (avatorPath) {
        Utils().getImgDataFromPath(avatorPath, 1, (img: ImageData) => {
          toast({
            title: '回報封鎖',
            description: `isGraphic: ${Utils().isGraphic(img)}`,
            status: 'warning',
            duration: 9000,
            isClosable: true,
            position: 'top'
          });
        })
      }
      // ;
    }
    if (idProfile && tapCounter === 10) {
      profileApi.update(idProfile, {}).then(showToast)
    }
  }, [tapCounter])

  return (
    <>
      <div className='p-3 inline-flex gap-2'>
        <div className={`${enableBlockBtn ? 'bg-red-600' : 'bg-gray-600'} p-1 rounded-full inline-flex items-center cursor-pointer`} onClick={() => {
          if (!enableBlockBtn) {
            setEnableBlockBtn(true);
            setTimeout(() => {
              setEnableBlockBtn(false);
            }, 3000)
          }
          if (enableBlockBtn) {
            showToast();
            blockApi.create<IBlock>({
              targetMemberNo: targetMemberNo
            } as IBlock).then(onBlocked)
          }
        }}>
          <Icon
            as={LuUserX}
            fontSize={'xl'}
            bgColor={enableBlockBtn ? 'red.400' : 'gray.400'}
            textColor={'white'}
            className="rounded-full p-0.5"
          />
          <div className='px-2 text-white text-xs'>{enableBlockBtn ? '再點一次' : '回報封鎖'}</div>
        </div>
        <div className='inline-flex items-center gap-1' onClick={() => {
          setTapCounter(tapCounter => tapCounter + 1);
        }}>
          {
            blockCountList.map((value, index) => {
              return <Icon
                key={`ban_${index}`}
                as={LuBan}
                fontSize={'xl'}
                bgColor={value ? 'red.400' : 'gray.400'}
                textColor={'white'}
                className="rounded-full p-0.5"
              />
            })
          }
        </div>
      </div>
    </>
  )
}