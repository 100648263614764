import { Center, Progress } from "@chakra-ui/react"
import { IProfile, ISubMember } from "../../interfaces"
import { SubMemberBox } from "./SubMemberBox"
import { HintBlock } from "../HintBlock"

interface SubMembersBlockProps {
  blockTitle: string,
  hint: string,
  noDataHint: string,
  actionPopup: boolean,
  parentProfile: IProfile,
  subMembers: ISubMember[],
  processing: boolean,
  onSale?: (memberNo: string) => void
  onFree?: (memberNo: string) => void
}
export const SubMembersBlock = ({
  blockTitle,
  hint,
  noDataHint,
  actionPopup,
  parentProfile,
  subMembers,
  onSale,
  onFree,
  processing,
}: SubMembersBlockProps) => {
  return (
    <div className="my-5 p-5 rounded-xl shadow">
      <div className="mb-3">{blockTitle}</div>
      {subMembers.map((subMember) => {
        return (
          <SubMemberBox
            key={subMember.profile.idProfile}
            subMember={subMember}
            actionPopup={actionPopup}
            onSale={() => {
              if (subMember.profile.simpleCode && onSale) {
                onSale(subMember.profile.simpleCode);
              }
            }}
            onFree={() => {
              if (subMember.profile.simpleCode && onFree) {
                onFree(subMember.profile.simpleCode);
              }
            }}
          />
        );
      })}
      {/* {
        subMembers?.map(subMember => {
          return <Avatar
            className='m-1 cursor-pointer'
            key={subMember.profile.idProfile}
            src={Utils().normalizeImagePath(subMember.profile.avator, subMember.profile.projectCode)}
            name={subMember.profile.nickname}
            onClick={() => {
              window.location.href = `/${subMember.profile.projectCode?.toLowerCase()}/s?c=${subMember.profile.simpleCode}`
            }}
          />
        })
      } */}
      {subMembers.length === 0 && (
        <Center>{parentProfile.nickname}{noDataHint}</Center>
      )}
      <HintBlock firstLine={hint} />
      <div className="h-1 overflow-hidden">
        {processing && (
          <Progress size="xs" colorScheme="yellow" isIndeterminate />
        )}
      </div>
    </div>
  );
};