import { useEffect, useState } from "react";
import { PublicCrudApi } from "../apis/public-crud-api"
import { useProjectCode } from "../hooks/useProjectCode"
import { IProfile } from "../interfaces";
import { StickyNote } from "../components/StickyNote";
import { useNavigate } from "react-router-dom";
import { LookForFilter } from "../components/LookForFilter";

export const AllProfilesPage = () => {
  const projectCode = useProjectCode();
  const navigate = useNavigate();
  const allProfilesApi = PublicCrudApi('core', `profiles/${projectCode}/intro?pageSize=500`);
  const [profiles, setProfiles] = useState<IProfile[]>([]);
  const [lookFor, setLookFor] = useState<number|string>();

  const goProfilePage = (profile: IProfile) => {
    navigate(`/${projectCode.toLowerCase()}/s?c=${profile.simpleCode}`)
  }

  useEffect(() => {
    allProfilesApi.getAll()
      .then((data) => {
        setProfiles(data as IProfile[]);
      })
  }, [])

  return (
    <>
      <LookForFilter setLookFor={(lookFor) => {setLookFor(lookFor);}} />
      <div className='gap-3 columns-2 md:columns-4 lg:columns-6'>
        {
          profiles.sort((a, b) => 0.5 - Math.random()).filter(p => !p.blocked).map(profile => {
            if (typeof lookFor === "number" && profile.multiGender !== `${lookFor}`) return null;
            if (typeof lookFor === "string" && lookFor === "SKYPE" && !profile.skypeId) return null;
            if (typeof lookFor === "string" && lookFor === "TWITTER" && !profile.twitterId) return null;
            return (
              <StickyNote
                key={profile.idProfile}
                profile={profile} content={profile.introduction}
                onClickNickname={() => {
                  // console.log(profile.idProfile)
                  goProfilePage(profile)
                }}
              />
            )
          })
        }
      </div>
    </>
  )
}