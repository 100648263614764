import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormLabel, Input, Spacer, useDisclosure } from "@chakra-ui/react";
import { BottomNavigation } from "../../components/BottomNavigation";
import { useProjectCode } from "../../hooks/useProjectCode";
import { AddIcon } from "@chakra-ui/icons";
import { Translation, useTranslation } from "react-i18next";
import { PrivateCrudApi } from "../../apis/private-crud-api";
import { useEffect, useState } from "react";
import { IAlbum } from "../../interfaces/IAlbum";
import { AlbumFolders } from "./AlbumFolders";

export const AlbumListPage = () => {
  const projectCode = useProjectCode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [albums, setAlbums] = useState<IAlbum[]>([]);
  const [newAlbum, setNewAlbum] = useState<IAlbum>({
    title: '',
    secretKey: ''
  });
  const albumApi = PrivateCrudApi('sns', 'albums');
  const memberAlbumApi = PrivateCrudApi('sns', 'member-albums');

  useEffect(() => {
    memberAlbumApi.getAll().then((data) => {
      setAlbums(data as IAlbum[]);
    });
  }, [])

  return (
    <>
      <Flex>
        <div className="text-left pt-3 aspect-square m-3"></div>
        <Spacer />
        <Box p="4">
          <Button
            rightIcon={<AddIcon />}
            colorScheme="teal"
            variant="outline"
            onClick={onOpen}
          >
            <Translation>{(t) => t('createAlbum')}</Translation>
          </Button>
        </Box>
      </Flex>
      <div>
        <AlbumFolders albums={albums} onAlbumDeleted={(album) => {
          setAlbums(albums.filter(a => a.idAlbum !== album.idAlbum))
        }} />
      </div>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{t('createYourAlbum')}</DrawerHeader>

          <DrawerBody>
            <div className="grid gap-3">
              <div>
                <FormLabel htmlFor="albumTitle">{t('albumTitle')}</FormLabel>
                <Input
                  id="albumTitle"
                  placeholder="Type here..."
                  value={newAlbum.title}
                  onChange={(e) => {
                    setNewAlbum({ ...newAlbum, title: e.target.value });
                  }}
                />
              </div>
              <div>
                <FormLabel htmlFor="secretKey">{t('secretKey')}</FormLabel>
                <Input
                  id="secretKey"
                  placeholder="Type here..."
                  value={newAlbum.secretKey}
                  onChange={(e) => {
                    setNewAlbum({ ...newAlbum, secretKey: e.target.value });
                  }}
                />
              </div>
            </div>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button
              colorScheme="teal"
              onClick={() => {
                albumApi.create(newAlbum).then((data) => {
                  setAlbums([...albums, data as IAlbum])
                  onClose();
                });
              }}
            >
              {t('create')}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <BottomNavigation projectCode={projectCode}></BottomNavigation>
    </>
  );
}