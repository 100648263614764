import { Textarea, VStack } from '@chakra-ui/react';
import { QRCodeCanvas } from 'qrcode.react';
import { useState } from 'react';

export const QrGenPage = () => {
  const [qrContent, setQrContent] = useState('')
  return (
    <>
      <VStack>
        <div className='p-3 shadow-lg rounded-lg m-5'>
          <QRCodeCanvas value={qrContent} />
        </div>
        <Textarea
          onChange={(e) => {
            setQrContent(e.target.value)
          }}
          value={qrContent}
          rows={10}
        />
      </VStack>
    </>
  )
}