import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Image,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { Utils } from '../utils';
import { useRef } from 'react';
import { Translation } from 'react-i18next';
import { useApi } from '../api';
import { ICargo } from '../interfaces';
import messageIcon from '../assets/imgs/message.png';
import { useRecoilValue } from 'recoil';
import { profileAtom } from '../stores/profile';

export const LeaveMessageButton = () => {
  const { projectCode, simpleCode } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const profile = useRecoilValue(profileAtom);
  const api = useApi(projectCode);
  const toast = useToast();

  return (
    <>
      <div className="flex flex-col px-8 justify-center items-center">
        <Image
          boxSize="36px"
          src={messageIcon}
          onClick={onOpen}
          className="cursor-pointer"
        />
        <div className="text-xs cursor-pointer" onClick={onOpen}>
          <Translation>{(t) => t('leaveMessage')}</Translation>
        </div>
      </div>
      <AlertDialog
        size={'xs'}
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <Translation>{(t) => t('leaveMessage')}</Translation>
            </AlertDialogHeader>

            <AlertDialogBody>
              {Utils().isLogin() ? (
                <Textarea
                  rows={10}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                ></Textarea>
              ) : (
                <Translation>{(t) => t('requiredToLoginMessage')}</Translation>
              )}
              <div className="pl-3 mt-3 text-xs text-slate-700">
                <div className="text-xs text-slate-500">
                  這不是公開留言喔! 只有對方本人可以看到。
                </div>
              </div>
            </AlertDialogBody>

            <AlertDialogFooter>
              {isSubmitted ? (
                <></>
              ) : (
                <Button ref={cancelRef} variant="outline" onClick={onClose}>
                  <Translation>{(t) => t('close')}</Translation>
                </Button>
              )}
              {Utils().isLogin() ? (
                isSubmitted ? (
                  <Button
                    colorScheme="teal"
                    variant="outline"
                    onClick={() => {
                      setMessage('');
                      setIsSubmitted(false);
                      onClose();
                    }}
                    ml={3}
                  >
                    <Translation>{(t) => t('done')}</Translation>
                  </Button>
                ) : (
                  <Button
                    isLoading={isLoading}
                    colorScheme="teal"
                    variant={'outline'}
                    onClick={() => {
                      if (profile?.kycStatus !== 1) {
                        toast({
                          title: '尚未通過認證',
                          description: "留言前需通過聖火認證",
                          status: 'warning',
                          duration: 9000,
                          isClosable: true,
                        });
                        return;
                      }
                      setIsLoading(true);
                      let url = new URL(window.location.href);
                      let shareCode =
                        url.searchParams.get('c') ||
                        url.searchParams.get('sharingCode') ||
                        simpleCode;
                      if (shareCode) {
                        api.leaveMessage(
                          shareCode,
                          message,
                          (cargo: ICargo) => {
                            setIsLoading(false);
                            if (cargo.returnCode === '000000') {
                              setIsSubmitted(true);
                            }
                          }
                        );
                      }
                    }}
                    ml={3}
                  >
                    <Translation>{(t) => t('submit')}</Translation>
                  </Button>
                )
              ) : (
                <Button
                  colorScheme="red"
                  onClick={() => {
                    navigate(`/${projectCode}/login`, { replace: true });
                    onClose();
                  }}
                  ml={3}
                >
                  <Translation>{(t) => t('login')}</Translation>
                </Button>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
