import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import './i18n';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import theme from './theme';
import 'focus-visible/dist/focus-visible';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// import * as Sentry from '@sentry/react';

const firebaseConfig = {
  apiKey: 'AIzaSyCmNq86bzM64yTXJSBWolkDSe7BprnZDEo',
  authDomain: 'bdsmtw-club.firebaseapp.com',
  projectId: 'bdsmtw-club',
  storageBucket: 'bdsmtw-club.appspot.com',
  messagingSenderId: '586499950984',
  appId: '1:586499950984:web:073f78e1b34d2d2a01123d',
  measurementId: 'G-L5RY8STD8R',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Sentry.init({
//   dsn: 'https://5df4013df66a23919d0bf39f0afb3fff@o4506098912919552.ingest.us.sentry.io/4506884658626560',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 0.5, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/bdsmtw\.club\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// if (window.localStorage.getItem('memberNo')) {
//   Sentry.setUser({ id: window.localStorage.getItem('memberNo') || '' });
// }

const container = document.getElementById("root");
if (!container) {
  throw new Error("The element #root wasn't found");
}
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <ChakraProvider>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </ChakraProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

