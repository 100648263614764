import { useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Image } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useProjectCode } from "../../hooks/useProjectCode";
import { PublicCrudApi } from "../../apis/public-crud-api";
import { PrivateCrudApi } from "../../apis/private-crud-api";
import { ArrowRightIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";

import { IBanner } from "../../interfaces";
import { Utils } from "../../utils";
import { RatingBox } from "./RatingBox";
import { IBannerRating } from "../../interfaces/IBannerRating";
import { useTranslation } from "react-i18next";

interface BannerReviewBlockProps {
  onComplete?: () => void
}

export const BannerReviewBlock = ({ onComplete }: BannerReviewBlockProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const projectCode = useProjectCode();
  const { t } = useTranslation();
  const [swiper, setSwiper] = useState<any>(null);
  const reviewApi = PublicCrudApi('core', `banners/review/${projectCode}`);
  const ratingApi = PrivateCrudApi('sns', 'banner-ratings');
  const [banners, setBanners] = useState<IBanner[]>([]);
  const [currentBanner, setCurrentBanner] = useState<IBannerRating>();
  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    reviewApi.getAll()
      .then(data => {
        setBanners(data as IBanner[]);
      })
  }, [])

  useEffect(() => {
    if (counter > 9) {
      onClose();
      if (onComplete) {
        onComplete()
      }
    }
  }, [counter])

  return (
    <>
      <div className='my-10 flex justify-end'>
        <div className="px-3 inline-flex items-center bg-yellow-400 border-yellow-400 border-4 rounded-full overflow-hidden whitespace-nowrap cursor-pointer" onClick={onOpen}>
          <div className="ml-1 select-none">{t('reviewPhotos')}</div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('reviewPhotos')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Swiper
              slidesPerView={1}
              spaceBetween={2}
              className='rounded-t-xl'
              onSwiper={(s) => setSwiper(s)}
              allowTouchMove={false}
            >
              {
                banners.map(banner => {
                  return (
                    <SwiperSlide key={banner.idBanner}>
                      <div>
                        <img
                          src={Utils().normalizeImagePath(banner.image, projectCode)}
                          className="rounded-lg object-scale-down"
                          alt={`${banner.idBanner}`}
                        />
                        <RatingBox onRating={(rating) => {
                          setCurrentBanner({
                            idBanner: banner.idBanner,
                            rating: rating
                          });
                        }} />
                      </div>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='teal' variant={'outline'} rightIcon={<ArrowRightIcon />} onClick={() => {
              if (currentBanner) {
                ratingApi.create(currentBanner)
              }
              swiper.slideNext();
              setCounter(counter => counter + 1)
            }}>
              {t('confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}