import { useEffect, useState } from "react"
import { ICargo, IProfile } from "../interfaces"
import { Utils } from "../utils"
import { useProjectCode } from "../hooks/useProjectCode"
import { useApi } from "../api"
import { useNavigate } from "react-router-dom"

export const PhotoGridPage = () => {
  const [hotProfiles, setHotProfiles] = useState<IProfile[]>([])
  const projectCode = useProjectCode();
  const api = useApi(projectCode);
  const navigate = useNavigate();

  useEffect(() => {
    if (!projectCode) return;
    api.getHotProfile(projectCode, 500, (cargo: ICargo) => {
      const profiles: IProfile[] = cargo.info;

      setHotProfiles(profiles.filter(p => p.avator && !p.blocked && !p.blur));
    })
  }, [projectCode])

  return (
    <div className='grid grid-cols-4 md:grid-cols-6'>
      {
        hotProfiles.sort((a, b) => 0.5 - Math.random()).map(profile => {
          return <img
            key={profile.idProfile}
            src={Utils().normalizeImagePath(profile.avator, projectCode)}
            className=' hover:scale-110 hover:rounded-md hover:shadow cursor-pointer'
            onClick={() => {
              navigate(`/${profile.projectCode?.toLowerCase()}/s?c=${profile.simpleCode}`)
            }}
          />
        })
      }
    </div>
  )
}