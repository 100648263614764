import { BottomNavigation } from "../../components/BottomNavigation";
import { useProjectCode } from "../../hooks/useProjectCode";
import { useEffect, useState } from "react";
import { IPhoto } from "../../interfaces/IPhoto";
import { useParams } from "react-router-dom";
import { IAlbum } from "../../interfaces/IAlbum";
import { ImageBox } from "./ImageBox";
import { useTranslation } from "react-i18next";
import { Utils } from "../../utils";
import { PublicCrudApi } from "../../apis/public-crud-api";
import { GiUnlocking } from 'react-icons/gi';

export const AlbumPage = () => {
  const isHalfLayout = false;
  const projectCode = useProjectCode();
  const { t } = useTranslation();
  const { idAlbum } = useParams();
  const [secretKey, setSecretKey] = useState<string>('');
  const albumApi = PublicCrudApi('sns', 'albums');
  const [album, setAlbum] = useState<IAlbum>();
  const [photos, setPhotos] = useState<IPhoto[]>([]);
  const [secretKeyField, setSecretKeyField] = useState<string>('');

  useEffect(() => {
    if (!idAlbum) return;
    albumApi.getById(idAlbum).then(data => {
      setAlbum(data as IAlbum);
    })
  }, [idAlbum])

  useEffect(() => {
    if(!album) return;
    if(!album.photos) return;

    setPhotos(album.photos)
  }, [album])

  return (
    <>
      <div className="mb-3">
        <label
          htmlFor="profile-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <GiUnlocking size={30}/>
          </div>
          <input
            type="search"
            id="profile-search"
            className="block w-full p-4 ps-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder={t('secretKey')}
            required
            value={secretKeyField}
            onChange={(e) => {
              setSecretKeyField(e.target.value);
            }}
          />
          <button
            type="submit"
            className="text-white absolute end-2.5 bottom-2.5 bg-yellow-500 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 transition-all"
            onClick={() => {
              setSecretKey(secretKeyField);
            }}
          >
            {t('unlock')}
          </button>
        </div>
      </div>
      <div>
        <div
          className={`gap-3 ${
            isHalfLayout
              ? 'columns-2 lg:columns-3'
              : 'columns-2 md:columns-4 lg:columns-6'
          }`}
        >
          {photos.map((photo) => {
            return (
              <ImageBox
                key={photo.idPhoto}
                photo={photo}
                secretKey={
                  secretKey !== '' ? Utils().md5(secretKey) : secretKey
                }
              />
            );
          })}
        </div>
      </div>
      <BottomNavigation projectCode={projectCode}></BottomNavigation>
    </>
  );
}