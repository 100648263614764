import { Outlet } from "react-router-dom"
import { useProjectCode } from "../hooks/useProjectCode"
import { useEffect } from "react";
import { useApi } from "../api";
import { ICargo, IProfile } from "../interfaces";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { profileAtom } from "../stores/profile";
import { Utils } from "../utils";
import { PublicCrudApi } from "../apis/public-crud-api";
import { IConnection } from "../interfaces/IConnection";
import { connectionStatusAtom, onlineUsersMemberNoAtom, receivedNewNotifyAtom } from "../stores/mqtt";
import { usePrivateApi } from "../hooks/usePrivateApi";

export const PrivateLayout = () => {
  const projectCode = useProjectCode();
  const api = useApi(projectCode);
  const privateApi = usePrivateApi();
  const setProfile = useSetRecoilState(profileAtom);
  const connectionApi = PublicCrudApi('sns', 'connections');
  const connectionStatus = useRecoilValue(connectionStatusAtom);
  const setOnlineUsersMemberNo = useSetRecoilState(onlineUsersMemberNoAtom);
  const setReceivedNewNotify = useSetRecoilState(receivedNewNotifyAtom);

  const fetchOnlineUsers = () => {
    connectionApi.getAll().then(data => {
      const connectionSet = new Set<string>();
      const connections: IConnection[] = data as IConnection[];
      connections.forEach(conn => {
        connectionSet.add(conn.memberNo)
      })
      setOnlineUsersMemberNo(connectionSet);
    });
  }

  useEffect(() => {
    if (Utils().isLogin()) {
      fetchOnlineUsers();
      api.getProfile((cargo: ICargo) => {
        if (cargo.success) {
          const profile:IProfile = cargo.info;
          setProfile(profile);

          if (profile.simpleCode) {
            const connection: IConnection = {
              memberNo: profile.simpleCode
            }
            connectionApi.create(connection);
          }
        }
      })
      privateApi.getUnreadChatroomMessageCount((cargo: ICargo) => {
        setReceivedNewNotify(cargo.info > 0);
      })
    }
    window.addEventListener('beforeunload', () => {
      if (window.localStorage.getItem('memberNo')) {
        connectionApi.delete(Number(window.localStorage.getItem('memberNo')));
      }
    })
    if (window.location.pathname.split('/').length === 2) {
      window.location.href = `${window.location.href}/profiles`
    }
  }, [])

  useEffect(() => {
    if (connectionStatus) {
      fetchOnlineUsers();
    }
  }, [connectionStatus])
  return (<Outlet />)
}