import { useState } from "react";
import { useProjectCode } from "../hooks/useProjectCode";
import { Utils } from "../utils";

interface AvatorMarkerProps {
  size: number;
  onClick?: () => void;
  path: string;
  alt?: string;
}
export const AvatorMarker = ({ size, onClick, path, alt }: AvatorMarkerProps) => {
  const projectCode = useProjectCode();
  const [isImageAvailable, setImageAvailability] = useState(true);

  const handleImageError = () => {
    setImageAvailability(false);
  };
  return (
    isImageAvailable ? (<div
      className={`cursor-pointer rounded-full`}
      style={{ 'width': size < 48 ? size : 48 }}
      onClick={onClick}
      onError={handleImageError}
    >
      <img
        src={Utils().normalizeImagePath(path, projectCode)}
        alt={alt}
        className="rounded-full w-full"
      />
    </div>) : (<div></div>)
  )
}