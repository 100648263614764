import { CheckCircleIcon } from "@chakra-ui/icons"
import { Image, Flex, Center, Spacer, List, Box } from "@chakra-ui/react"
import { Logo } from "../../components/Logo"
import { Utils } from "../../utils"
import { useNavigate, useParams } from "react-router-dom"
import { useCallback, useEffect } from "react"
import { useRecoilState } from "recoil"
import { useApi } from "../../api"
import { ICargo } from "../../interfaces"
import { flameAtom } from "../../stores/profile"
import flameIcon from '../../assets/imgs/Olympic-Torch-PNG-Free-Download.png'
import { useTranslation } from "react-i18next"

export const FlameBlock = () => {
  const { projectCode } = useParams();
  const { t } = useTranslation();
  const api = useApi(projectCode);
  const navigate = useNavigate();
  const [flame, setFlame] = useRecoilState(flameAtom);

  const fetchLastFlame = useCallback(() => {
    api.getLastFlame((cargo: ICargo) => {
      setFlame(cargo.info)
    })
  }, [api]);

  useEffect(() => {
    fetchLastFlame()
  }, [])

  return (
    <>
      <div className='cursor-pointer border rounded-lg shadow-xl p-3'>
        <div className='bold select-none'>{t('flameOwner')}</div>
        <Flex onClick={() => {
          navigate(`/${projectCode}/s?c=${flame?.profile.simpleCode}`)
        }}>
          <Box>
            <h1 className="text-3xl font-bold mt-5">
              {
                flame && flame.profile && flame.profile.avator === null ? <Logo></Logo> :
                  <Center>
                    <Box width={'104px'}>
                      <Image
                        borderRadius={'xl'}
                        src={Utils().avatorUrlBuilder(flame?.profile)}
                        shadow={'dark-lg'}
                      />
                      {flame?.profile?.kycStatus === 1 && <CheckCircleIcon color={'green.400'} className=' absolute top-14 left-4 bg-white rounded-full' />}
                    </Box>
                  </Center>
              }
            </h1>
          </Box>
          <Spacer />
          <Box p='4' className='text-sm inline-block'>
            聖火，權力傳遞的像徵。<br />
            取得聖火的人，意味著擁有核可下位 <CheckCircleIcon color={'green.400'} /> 認證使用者的權力。<br />
            快向聖火持有者打聲招呼，請求持有者進入你的個人頁面，將聖火傳遞給你吧。
          </Box>
          <Image src={flameIcon} className=' absolute w-12 right-4 top-0' />
        </Flex>
      </div>
    </>
  )
}