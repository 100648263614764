import { Input, InputGroup, InputRightAddon } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import sendIcon from "../../assets/imgs/send.png";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { nicknameSelectedAtom } from "../../stores/message";

interface MessageInputProps {
  onSend?: (message: string) => void
}
export const MessageInput = ({ onSend }: MessageInputProps) => {
  const [message, setMessage] = useState<string>('');
  const [nickname, setNickname] = useRecoilState<string>(nicknameSelectedAtom)
  const { t } = useTranslation();

  const rollToBottom = () => {
    if (window.localStorage.getItem('disableRollToBottom') === 'true') return;
    document.documentElement.scrollTo({
      left: 0,
      top: document.documentElement.scrollHeight - document.documentElement.clientHeight,
      behavior: 'smooth'
    });
  }

  const sendMessage = () => {
    if (onSend && message.trim().length > 0) {
      onSend(message);
      setMessage('');
    }
  }

  useEffect(() => {
    if (nickname) {
      setMessage(`@${nickname}, ${message}`)
      setNickname('')
    }
  }, [nickname])

  return (
    <>
      <InputGroup size={'lg'}>
        <Input
          placeholder={`${t('inputMessage')}`}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value)
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              sendMessage();
            }
          }}
          onClick={() => {
            setTimeout(rollToBottom, 300);
          }}
        />
        <InputRightAddon
          children={<img src={sendIcon} alt="send" width={30} />}
          className="cursor-pointer"
          onClick={sendMessage}
        />
      </InputGroup>
    </>
  )
}