import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useSubscribeChatroomId } from "../../hooks/useSubscribeChatroomId";
import { ICargo, IChatMessage, IChatMessagePayload, IMessage, IProfile } from "../../interfaces";
import { chatroomIdAtom, connectionStatusAtom, onlineUsersMemberNoAtom, prevChatroomIdAtom, publishChatMessageAtom, receivedChatMessageAtom, receivedReloadChatroomAtom, reloadChatroomAtom } from '../../stores/mqtt'
import { avatorAtom, memberNoAtom, nicknameAtom, profileAtom } from "../../stores/profile";
import { Utils } from "../../utils";
import { useProjectCode } from "../../hooks/useProjectCode";
import { SendMessageBox } from "../../components/SendMessageBox";
import { useNavigate, useParams } from "react-router-dom";
import { usePrivateApi } from "../../hooks/usePrivateApi";
import { usePublicApi } from "../../hooks/usePublicApi";
import { NaviBar } from "../../components/NaviBar";
import { Avatar, AvatarBadge, Spinner, useToast } from "@chakra-ui/react";
import { MessageBlock } from "./MessageBlock";
import { useApi } from "../../api";
import { useTranslation } from "react-i18next";
import { publicChatMessagesAtom } from "../../stores/message";
import { PublicCrudApi } from "../../apis/public-crud-api";
import { IConnection } from "../../interfaces/IConnection";

export const PublicChatPage = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const projectCode = useProjectCode();
  const api = useApi(projectCode);
  const publicApi = usePublicApi();
  const privateApi = usePrivateApi();
  const navigate = useNavigate();
  const connectionApi = PublicCrudApi('sns', 'connections');
  const [showAvatorBar, setAvatorBar] = useState<boolean>(true);

  const [lastAccessProfiles, setLastAccessProfiles] = useState<IProfile[]>([])

  const [showKycOnly, setKycOnly] = useState<boolean>(window.localStorage.getItem('showKycOnly') === 'true' || false);
  const [showPrivateMsg, setShowPrivateMsg] = useState<boolean>(window.localStorage.getItem('showPrivateMsg') === 'true' || false);

  const [chatMessages, setChatMessages] = useRecoilState(publicChatMessagesAtom)
  const [sendMessageTo, setMessageTo] = useState<IProfile>()

  const setPrevChatroomId = useSetRecoilState(prevChatroomIdAtom);
  const setCurrentChatroomId = useSetRecoilState(chatroomIdAtom);

  const { fromMemberNo, toMemberNo, connectionId } = useParams();
  const [chatroomId, setChatroomId] = useState<string | undefined>(connectionId);
  const previousChatroomId = useSubscribeChatroomId(chatroomId || '');

  const setPublishChatMessage = useSetRecoilState(publishChatMessageAtom);
  const receivedChatMessage = useRecoilValue(receivedChatMessageAtom);
  const connectionStatus = useRecoilValue(connectionStatusAtom);
  const setReloadChatroom = useSetRecoilState(reloadChatroomAtom);
  const [receivedReloadChatroom, setReceivedReloadChatroom] = useRecoilState(receivedReloadChatroomAtom);
  const onlineUsersMemberNo = useRecoilValue(onlineUsersMemberNoAtom);

  const profile = useRecoilValue(profileAtom);
  const nickname = useRecoilValue(nicknameAtom);
  const memberNo = useRecoilValue(memberNoAtom);
  const avator = useRecoilValue(avatorAtom);

  const rollToBottom = (behavior: string) => {
    if (window.localStorage.getItem('disableRollToBottom') === 'true') return;
    if (behavior === 'instant') {
      document.documentElement.scrollTo({
        left: 0,
        top: document.documentElement.scrollHeight - document.documentElement.clientHeight + 130
      });
    } else {
      document.documentElement.scrollTo({
        left: 0,
        top: document.documentElement.scrollHeight - document.documentElement.clientHeight + 130,
        behavior: 'smooth'
      });
    }
  }

  const getMyMemberNo = useCallback(() => {
    return memberNo || window.localStorage.getItem('memberNo')
  }, [])
  const isSelfMessage = useCallback((chatMessage: IChatMessage) => {
    return chatMessage?.profile?.simpleCode === window.localStorage.getItem('memberNo') || chatMessage?.memberNo === window.localStorage.getItem('memberNo')
  }, [])

  const fetchLastAccessProfiles = useCallback(() => {
    if (!projectCode) return;
    api.getLastAccessProfiles(projectCode, 30, (cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        const profiles: IProfile[] = cargo.info;
        setLastAccessProfiles(profiles.filter(p => p.avator && !p.blocked && !p.blur && p.simpleCode !== window.localStorage.getItem('memberNo')));
      }
    })
  }, [api])

  useEffect(() => {
    if (receivedReloadChatroom) {
      fetchChatroomMessages(receivedReloadChatroom, false)
      setReceivedReloadChatroom(null)
      fetchLastAccessProfiles()
    }
  }, [receivedReloadChatroom])

  useEffect(() => {
    if (connectionStatus && chatroomId) {
      setPrevChatroomId(null);
      setCurrentChatroomId(chatroomId);
    }
  }, [chatroomId, connectionStatus, setCurrentChatroomId, setPrevChatroomId])

  useEffect(() => {
    setPrevChatroomId(previousChatroomId);
  }, [previousChatroomId, setPrevChatroomId])

  useEffect(() => {
    // console.log(onlineUsersMemberNo)
  }, [onlineUsersMemberNo])

  useEffect(() => {
    setTimeout(rollToBottom, 100);
    setTimeout(rollToBottom, 500);
    setTimeout(rollToBottom, 1000);
    if (receivedChatMessage?.memberNo === memberNo) return;
    if (!receivedChatMessage?.message) return;
    if (receivedChatMessage.chatroomId !== chatroomId) return;
    setChatMessages([...chatMessages, receivedChatMessage])
    window.localStorage.setItem(`last_received_chat_message_${chatroomId}`, `${Utils().getTimestamp()}`)
    fetchLastAccessProfiles();
    if (window.localStorage.getItem('enableNotification') === 'true') {
      if (receivedChatMessage.toProfile?.simpleCode === memberNo && receivedChatMessage.profile?.avator && receivedChatMessage.profile.nickname) {
        const img = Utils().normalizeImagePath(receivedChatMessage.profile.avator, projectCode);
        const text = receivedChatMessage.message;
        const notification = new Notification(receivedChatMessage.profile.nickname, { body: text, icon: img });
      }
    }
  }, [receivedChatMessage])

  const fetchChatroomMessages = (chatroomId: string, rollingToBottom: boolean = true) => {

    publicApi.getChatroomMessages(chatroomId, 200, window.localStorage.getItem('memberNo'), (cargo: ICargo) => {
      const chatMsgs: IChatMessage[] = cargo.info.reverse();
      chatMsgs.forEach((msg) => {
        msg.dateAdd = new Date(msg.dateAdd + 'Z');
      })
      setChatMessages(chatMsgs);
      setTimeout(() => {
        rollToBottom('instant')
      }, 500);
    })
  }

  useEffect(() => {
    if (!chatroomId) {
      if (fromMemberNo && toMemberNo) {
        privateApi.getChatroomConnection(fromMemberNo, toMemberNo, (cargo: ICargo) => {
          setChatroomId(cargo.info)
          if (cargo.success) {
            fetchChatroomMessages(cargo.info)
          }
        })
      }
    } else {
      fetchChatroomMessages(chatroomId)
    };
    fetchLastAccessProfiles();
    if (profile && profile.simpleCode) {
      const connection: IConnection = {
        memberNo: profile.simpleCode
      }
      connectionApi.create(connection);
    }
    const msgStr = window.localStorage.getItem('messageTo');
    if (msgStr) {
      if (msgStr === 'undefined') return;
      setMessageTo(JSON.parse(window.localStorage.getItem('messageTo') || ''));
    }
  }, [])

  const sendMessage = (message: string) => {
    const idMessage: string = Utils().uuid();
    if (!chatroomId) return;
    if (profile?.blocked) {
      toast({
        title: '回報封鎖',
        description: "您被檢舉次數過多，系統將自動停止使用權限。",
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      return;
    }
    if (!nickname && !avator && !window.localStorage.getItem('avator')) {
      toast({
        title: '溫馨小提醒',
        description: "資料完善才發言喔! 請確認匿稱及大頭貼是否都有正確呦",
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      // return;
    }
    const emojiObj: IChatMessagePayload = message.includes('emoji') ? JSON.parse(message) : null;
    if (emojiObj) {
      emojiObj.type = 'emoji';
    }
    const imgObj: IChatMessagePayload = message.includes('img') ? JSON.parse(message) : null;
    const isPrivateConfig = window.localStorage.getItem('setIsPrivate') === 'true'
    const chatMessage: IChatMessage = {
      idMessage: idMessage,
      requestId: Utils().getUuid(),
      chatroomId: chatroomId,
      message: message,
      nickname: nickname,
      memberNo: memberNo,
      avator: avator,
      payload: emojiObj ? emojiObj : imgObj ? imgObj : {
        type: 'text',
        content: message
      },
      isPrivate: sendMessageTo ? isPrivateConfig : false,
      toProfile: sendMessageTo,
      toMemberNo: sendMessageTo?.simpleCode,
      profile: {
        nickname: nickname,
        simpleCode: memberNo,
        avator: avator
      }
    }
    setPublishChatMessage(chatMessage)
    setChatMessages([...chatMessages, chatMessage])
    setTimeout(rollToBottom, 100);
    setTimeout(rollToBottom, 500);
    setTimeout(rollToBottom, 1000);
    if (toMemberNo) {
      privateApi.sendChatroomPrivateMessage(chatroomId, toMemberNo, message, JSON.stringify(chatMessage.payload), sendMessageTo ? isPrivateConfig : false, chatMessage.requestId || Utils().getUuid())
    } else if (sendMessageTo && sendMessageTo.simpleCode) {
      privateApi.sendChatroomPrivateMessage(chatroomId, sendMessageTo.simpleCode, message, JSON.stringify(chatMessage.payload), sendMessageTo ? isPrivateConfig : false, chatMessage.requestId || Utils().getUuid())
    } else {
      privateApi.sendChatroomMessage(chatroomId, message, JSON.stringify(chatMessage.payload), Utils().getUuid())
    }
  }

  const triggerMessageTo = (profile: IProfile) => {
    if (sendMessageTo?.idProfile === profile.idProfile) {
      setMessageTo(undefined);
    } else {
      setMessageTo(profile);
    }
  }  

  return (
    <>
      <NaviBar
        title={t('chatroom')}
        onClickMore={() => {
          setAvatorBar((showAvatorBar) => !showAvatorBar);
        }}
      />
      <div className="flex flex-row">
        <div className="w-full md:mr-10 md:ml-3 p-3">
          {chatMessages.length === 0 ? (
            <div className="text-center h-full content-center">
              <div className="h-1/2"></div>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="yellow.500"
                size="xl"
              />
              <div className="text-stone-700">安全連線建立中…</div>
            </div>
          ) : (
            chatMessages.map((chatMessage, index) => {
              if (
                showKycOnly &&
                chatMessage.profile &&
                chatMessage.profile.kycStatus !== 1
              )
                return null;
              if (
                chatMessage?.isPrivate &&
                chatMessage?.toMemberNo &&
                chatMessage.toMemberNo !== getMyMemberNo() &&
                !isSelfMessage(chatMessage)
              ) {
                if (window.localStorage.getItem('showPrivateMsg') === 'false') {
                  return null;
                }
              }
              if (chatMessage.deleted || chatMessage.blocked) return null;
              return (
                <MessageBlock
                  key={`${index}${chatMessage.idMessage}`}
                  chatMessage={chatMessage}
                  onClickAvator={triggerMessageTo}
                  onClickNickname={triggerMessageTo}
                  onDeleteChatMessage={(deletedChatMessage) => {
                    if (
                      deletedChatMessage.idChatroomMessage ||
                      deletedChatMessage.requestId
                    ) {
                      privateApi.delChatroomMessage(
                        deletedChatMessage.idChatroomMessage ||
                          deletedChatMessage.requestId,
                        false,
                        () => {
                          setReloadChatroom(deletedChatMessage.chatroomId);
                        }
                      );
                      setChatMessages((chatMessages) =>
                        chatMessages.filter(
                          (chatMessage) =>
                            chatMessage.idChatroomMessage !==
                            deletedChatMessage.idChatroomMessage
                        )
                      );
                    }
                  }}
                />
              );
            })
          )}
          <div className="h-[130px]"></div>
        </div>
        <div
          className={`${!showAvatorBar ? 'w-0' : 'w-28'} md:w-16 pt-3 bg-white`}
        >
          <div
            className={`${
              !showAvatorBar ? 'w-0 invisible' : 'w-16'
            } md:w-16 fixed top-16 pl-3 pr-16 right-1 md:visible border-l h-full overflow-y-scroll bg-white rounded-lg overflow-x-hidden no-scrollbar`}
          >
            {lastAccessProfiles.map((profile) => {
              return (
                <Avatar
                  key={profile.idProfile}
                  src={Utils().avatorUrlBuilder(profile)}
                  className="cursor-pointer my-1.5"
                  onClick={() => setMessageTo(profile)}
                  shadow={'md'}
                  border={'1px'}
                  size={'md'}
                >
                  {profile.simpleCode &&
                    onlineUsersMemberNo.has(profile.simpleCode) && (
                      <AvatarBadge
                        borderColor="papayawhip"
                        bg="tomato"
                        boxSize="0.7em"
                      />
                    )}
                </Avatar>
              );
            })}
          </div>
        </div>
      </div>
      <div className="fixed left-0 bottom-0 w-full bg-white p-3">
        <SendMessageBox
          showPrivateIcon={true}
          onSend={(message) => {
            sendMessage(message);
            fetchLastAccessProfiles();
          }}
          onKycFilter={setKycOnly}
          onPrivateMsgFilter={setShowPrivateMsg}
          messageTo={sendMessageTo}
          onCancelMessageTo={() => {
            setMessageTo(undefined);
          }}
          onClickHome={(profile) => {
            navigate(`/${projectCode.toLowerCase()}/s?c=${profile.simpleCode}`);
          }}
          onClickPrivateChatroom={(profile) => {
            navigate(
              `/${projectCode.toLowerCase()}/chatroom/${memberNo}/${
                profile.simpleCode
              }`
            );
          }}
          imageNeedKyc={true}
        />
      </div>
    </>
  );
}