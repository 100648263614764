import { ICargo } from "../interfaces";
import { generateFetchHeader, generatePrivateHeader } from "../utils/api/headerUtils";

interface ApiOptions {
  body?: BodyInit;
}

async function makeRequest<T>(url: string, method: string, options: ApiOptions = {}): Promise<T> {
  const response = await fetch(url, {
    method,
    headers: url.includes("/private/") ? generatePrivateHeader() : generateFetchHeader(),
    body: options.body ? JSON.stringify(options.body) : undefined,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(JSON.stringify(errorData));
  }

  const responseData: ICargo = await response.json();

  if (responseData.returnCode !== '000000') {
    console.error(JSON.stringify(responseData))
    // throw new Error(`[${url}] ${JSON.stringify(responseData)}`);
  }

  return responseData.info;
}

export async function get<T>(url: string): Promise<T> {
  try {
    return makeRequest<T>(url, 'GET');
  } catch (error) {
    throw error;
  }
}

export async function post<T>(url: string, data: any): Promise<T> {
  try {
    return makeRequest<T>(url, 'POST', { body: data });
  } catch (error) {
    throw error;
  }
}

export async function put<T>(url: string, data: any): Promise<T> {
  try {
    return makeRequest<T>(url, 'PUT', { body: data });
  } catch (error) {
    throw error;
  }
}

export async function patch<T>(url: string, data: any): Promise<T> {
  try {
    return makeRequest<T>(url, 'PATCH', { body: data });
  } catch (error) {
    throw error;
  }
}

export async function remove<T>(url: string): Promise<T> {
  try {
    return makeRequest<T>(url, 'DELETE');
  } catch (error) {
    throw error;
  }
}
