import React from 'react';
import './App.css';
import { RouteTable } from './RouteTable'
import { MqttProvider } from './providers/MqttProvider';
import { RestoreDataProvider } from './providers/RestoreDataProvider';

function App() {
  return (
    <div>
      <RouteTable />
      <MqttProvider />
      <RestoreDataProvider />
    </div>
  );
}

export default App;
