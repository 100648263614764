import { Avatar } from "@chakra-ui/react"
import { IProfile } from "../interfaces"
import { Utils } from "../utils"
import { useState } from "react"

interface ConfirmAvatorProps {
  profile: IProfile,
  onClick?: () => void,
  onConfirm?: (profile: IProfile) => void
}
export const ConfirmAvator = ({ profile, onClick, onConfirm }: ConfirmAvatorProps) => {
  const [confirmed, setConfirmed] = useState<boolean>(false);
  return (
    <Avatar
      className={`m-1 ${confirmed ? 'grayscale-0' : 'grayscale'}`}
      src={Utils().normalizeImagePath(profile.avator, profile.projectCode)}
      onClick={() => {
        if (onClick) {
          onClick()
        }
        setConfirmed(true)
        if (confirmed && onConfirm) {
          onConfirm(profile)
        }
      }}
    />
  )
}