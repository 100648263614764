import { ReactChild } from "react";
import { HintBlock } from "../HintBlock";

interface ContentBlockWithHintProps {
  title: string,
  children: ReactChild,
  firstLineHint: string,
  secondLineHint?: string,
  thirdLineHint?: string
}
export const ContentBlockWithHint = ({ title, children, firstLineHint, secondLineHint, thirdLineHint}: ContentBlockWithHintProps) => {
  return (
    <div className='my-5 p-5 rounded-xl shadow'>
      <div className='mb-3'>{title}</div>
      {children}
      <HintBlock firstLine={firstLineHint} secondLine={secondLineHint} thirdLine={thirdLineHint} />
    </div>
  )
}