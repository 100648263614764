export const generatePrivateHeader = () => {
  const accessToken =
    window.localStorage.getItem("token") ||
    window.localStorage.getItem("accessToken") ||
    "DummyToken";
  if (
    !window.localStorage.getItem("token") &&
    !window.localStorage.getItem("accessToken")
  ) {
    window.location.href = `/${window.location.pathname.split("/")[1]}/login`;
    return;
  }
  const headers: HeadersInit = new Headers();
  headers.set("Content-Type", "application/json");
  if (accessToken.length === 32) {
    headers.set("X-Access-Token", accessToken);
  } else if (accessToken.split(".").length === 3) {
    headers.set("Authorization", `Bearer ${accessToken}`);
  }
  if (window.localStorage.getItem("memberId")) {
    headers.set("X-Member-Id", window.localStorage.getItem("memberId") || "");
  }
  return headers;
};
export const generateFetchHeader = () => {
  const headers: HeadersInit = new Headers();
  headers.set("Content-Type", "application/json");
  return headers;
};
