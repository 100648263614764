import { useRecoilValue } from 'recoil';
import { useProjectCode } from '../../hooks/useProjectCode';
import { profileAtom } from '../../stores/profile';
import { Avatar, Icon } from '@chakra-ui/react';
import { LuChevronLeft, LuMoreHorizontal } from 'react-icons/lu';
import { Utils } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { usePublicApi } from '../../hooks/usePublicApi';
import { useEffect, useState } from 'react';
import { ICargo, IProfile } from '../../interfaces';

interface NaviBarProps {
  title?: string;
  memberNo?: string;
  onClickMore?: () => void;
}
export const NaviBar = ({ title, memberNo, onClickMore }: NaviBarProps) => {
  const projectCode = useProjectCode();
  const publicApi = usePublicApi();
  const profile = useRecoilValue(profileAtom);
  const navigate = useNavigate();
  const [assignedProfile, setAssignedProfile] = useState<IProfile>();

  useEffect(() => {
    if (!memberNo) return;
    publicApi.getPublicProfile(memberNo, (cargo: ICargo) => {
      if (cargo.success) {
        setAssignedProfile(cargo.info);
      }
    });
  }, []);

  const goProfilePage = (memberNo?: string) => {
    navigate(`/${projectCode.toLowerCase()}/share/${memberNo}`);
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-50">
        <nav
          className="
            relative
            w-full
            flex flex-wrap
            items-center
            justify-between
            py-2
            bg-gray-100
            text-gray-500
            hover:text-gray-700
            focus:text-gray-700
            shadow-lg
            navbar navbar-expand-lg navbar-light
          "
        >
          <div className="container-fluid w-full flex flex-wrap items-center justify-between pl-5 pr-6">
            <Icon
              as={LuChevronLeft}
              boxSize={8}
              className="cursor-pointer"
              onClick={() => {
                window.history.back();
              }}
            />
            <div>{title}</div>
            <div className="pr-5 w-[32px]">
              {onClickMore ? (
                <Icon
                  as={LuMoreHorizontal}
                  boxSize={8}
                  className="cursor-pointer md:invisible"
                  onClick={onClickMore}
                />
              ) : memberNo ? (
                <Avatar
                  size={'sm'}
                  src={Utils().avatorUrlBuilder(assignedProfile)}
                  onClick={() => goProfilePage(memberNo)}
                />
              ) : profile ? (
                <Avatar
                  size={'sm'}
                  src={Utils().avatorUrlBuilder(profile)}
                  onClick={() => goProfilePage(profile.simpleCode)}
                />
              ) : (
                <div>&nbsp;</div>
              )}
            </div>
          </div>
        </nav>
      </div>
      <div className="py-4"></div>
    </>
  );
};
