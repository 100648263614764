import { useState, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Translation } from 'react-i18next';
import { 
  Input,
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton
} from '@chakra-ui/react';
import { ICargo } from '../interfaces';
import { useApi } from '../api';
import i18n from '../i18n'
import { Logo } from '../components/Logo'

export const RegistrationPage = () => {
  const navigate = useNavigate()
  const { projectCode } = useParams()
  const api = useApi(projectCode)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [cfmPassword, setCfmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const signup = () => {
    if (password !== cfmPassword) {
      setErrorMessage(i18n.t('passwordDoesNotMatch'))
      onOpen()
      return
    }
    setIsLoading(true)
    api.registration(username, password, (cargo: ICargo) => {
      setIsLoading(false)
      if (cargo.returnCode === '000000') {
        navigate(`/${projectCode}/home`)
      } else {
        setErrorMessage(i18n.t('userHasAlreadyExisted'))
        onOpen()
      }
    })
  }
  return (
    <>
      <h1 className='p-3'>&nbsp;</h1>
      <div className='grid grid-cols-3'>
        <div>
          <Logo></Logo>
        </div>
      </div>
      <div className='p-3'>
        在這個國度裡<br/>
        我們希望能遇見內心真正的你<br />
        我們建議註冊時<br />
        避免使用平時常用的帳號<br />
        讓我們在另一個王國裡 相遇 相見
      </div>
      <div className='pt-3'>
        <Input 
          value={username}
          onChange={(e) => {
            setUsername(e.target.value)
          }}
          placeholder='Your Email'
        />
      </div>
      <div className='pt-3'>
        <Input
          value={password}
          onChange={(e) => {
            setPassword(e.target.value)
          }}
          type="password"
          placeholder='Your Password'
        />
      </div>
      <div className='pt-3'>
      <Input
          value={cfmPassword}
          onChange={(e) => {
            setCfmPassword(e.target.value)
          }}
          type="password"
          placeholder='Confirm Your Password'
        />
      </div>
      <div className='p-3 text-center'>
        <Translation>
          {t => (t('signupNotic'))}
        </Translation>
      </div>
      <div className='pt-3'>
        <Button
          isLoading={isLoading}
          colorScheme={'white'}
          shadow="lg"
          color={'green.900'} 
          width="100%"
          onClick={signup}
        >
          <Translation>
            {t => (t('signUp'))}
          </Translation>
        </Button>
      </div>
      <div className="p-5 text-right" onClick={() => { navigate(`/${projectCode}/login`) }}>
        <Translation>
          {t => (t('login'))}
        </Translation>
      </div>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={"xs"}
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            <Translation>
              {t => (t('error'))}
            </Translation>
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {errorMessage}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose} colorScheme='red' ml={3}>
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};