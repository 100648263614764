import { useParams } from "react-router-dom";

export const useProjectCode = () => {
  const defaultProjectCode = 'SMP';
  const { projectCode } = useParams();

  if (projectCode && projectCode?.length !== 3) {
    window.location.href = `/${defaultProjectCode}`.toLocaleLowerCase();
  }
  return projectCode?.toUpperCase() || defaultProjectCode;
}