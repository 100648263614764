import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Translation } from 'react-i18next'
import { useApi } from '../api'
import { ICargo } from '../interfaces'
import { useProjectCode } from '../hooks/useProjectCode'
import { Progress } from '@chakra-ui/react'
import { Utils } from '../utils'

interface ImageUploaderProps {
    onUploaded?: (cargo: ICargo, isGraphic: boolean, width: Number, height: Number) => void;
    secretKey?: string;
}
export const ImageUploader = ({ onUploaded, secretKey }: ImageUploaderProps) => {
  const projectCode = useProjectCode();
  const api = useApi(projectCode);
  const [processing, setProcessing] = useState<boolean>(false);
  const [isGraphic, setIsGraphic] = useState<boolean>(false);

  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [imgWidth, setImgWidth] = useState<Number>(0);
  const [imgHeight, setImgHeight] = useState<Number>(0);

  const uploadFile = useCallback(
    (fileOfBlob: Blob) => {
      setProcessing(true);
      api.uploadImageFile(projectCode, fileOfBlob, (cargo: ICargo) => {
        setProcessing(false);
        if (onUploaded) {
          onUploaded(cargo, isGraphic, imgWidth, imgHeight);
        }
      });
    },
    [api]
  );

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          if (binaryStr) {
            setImgSrc(binaryStr.toString());
            const img = new Image();
            img.src = binaryStr.toString();
            img.onload = () => {
              const maxSize = Math.max(img.width, img.height);
              const targetSize = 600;
              const scaleFactor = maxSize > targetSize ? targetSize / maxSize : 1;
              const elem = document.createElement('canvas');
              elem.width = img.width * scaleFactor;
              elem.height = img.height * scaleFactor;

              const ctx = elem.getContext('2d');
              if (ctx) {
                ctx.drawImage(
                  img,
                  0,
                  0,
                  img.width,
                  img.height,
                  0,
                  0,
                  img.width * scaleFactor,
                  img.height * scaleFactor
                );
                const imageData = ctx.getImageData(
                  0,
                  0,
                  elem.width,
                  elem.height
                );
                if (secretKey) {
                  const pixelArray: number[][] = [];
                  for (let i = 0; i < imageData.data.length; i += 4) {
                    pixelArray.push([
                      imageData.data[i], // Red
                      imageData.data[i + 1], // Green
                      imageData.data[i + 2], // Blue
                      imageData.data[i + 3], // Alpha
                    ]);
                  }
                  const { shuffledArray } = shuffle(
                    pixelArray,
                    secretKey || ''
                  );
                  imageArrayToBase64AndUpload(
                    shuffledArray,
                    elem.width,
                    elem.height,
                    file
                  );
                } else {
                  ctx.canvas.toBlob(
                    (blob) => {
                      const fileOfBlob: BlobPart = new File(
                        [blob as BlobPart],
                        file['name'],
                        {
                          type: file['type'],
                          lastModified: Date.now(),
                        }
                      );
                      uploadFile(fileOfBlob);
                    },
                    file['type'],
                    1
                  );
                }
                setIsGraphic(Utils().isGraphic(imageData));
                setImgWidth(img.width);
                setImgHeight(img.height);
              }
              // img.width and img.height will contain the original dimensions
            };
          }
        };
        reader.readAsDataURL(file);
      });
    },
    [uploadFile]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },
  });

  const stringToHash = (str: string): number =>
    Array.from(str).reduce((hash, char) => {
      hash = (hash << 5) - hash + char.charCodeAt(0);
      return hash | 0; // Convert to 32bit integer
    }, 0);

  const shuffle = (
    imageArray: number[][],
    key: string
  ): { shuffledArray: number[][]; orderArray: number[] } => {
    const hash = stringToHash(key);
    const rng = seedRandom(hash);
    const orderArray = Array.from(
      { length: imageArray.length },
      (_, index) => index
    );

    orderArray.forEach((_, i) => {
      const j = Math.floor(rng() * (i + 1));
      [orderArray[i], orderArray[j]] = [orderArray[j], orderArray[i]];
    });

    const shuffledArray = orderArray.map((index) => imageArray[index]);

    return { shuffledArray, orderArray };
  };

  const seedRandom = (seed: number): (() => number) => {
    const mask = 0xffffffff;
    let m_w = (123456789 + seed) & mask;
    let m_z = (987654321 - seed) & mask;

    return () => {
      m_z = (36969 * (m_z & 65535) + (m_z >>> 16)) & mask;
      m_w = (18000 * (m_w & 65535) + (m_w >>> 16)) & mask;
      let result = ((m_z << 16) + (m_w & 65535)) >>> 0;
      result /= 4294967296;
      return result;
    };
  };

  const imageArrayToBase64AndUpload = (
    imageArray: number[][],
    width: number,
    height: number,
    file: any
  ): string => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      const imageData = ctx.createImageData(width, height);
      for (let i = 0; i < width * height; i++) {
        const index = i * 4;
        imageData.data[index] = imageArray[i][0]; // Red
        imageData.data[index + 1] = imageArray[i][1]; // Green
        imageData.data[index + 2] = imageArray[i][2]; // Blue
        imageData.data[index + 3] = 255; // Alpha (opaque)
      }
      ctx.putImageData(imageData, 0, 0);
      ctx.canvas.toBlob(
        (blob) => {
          const fileOfBlob: BlobPart = new File(
            [blob as BlobPart],
            file['name'],
            {
              type: file['type'],
              lastModified: Date.now(),
            }
          );
          uploadFile(fileOfBlob);
        },
        file['type'],
        1
      );
      return canvas.toDataURL('image/png');
    }
    return '';
  };

  return (
    <>
      {imgSrc === null ? (
        <div
          className="border border-dashed px-3 py-10 rounded-lg"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <p className="whitespace-pre-wrap text-gray-500">
            <Translation>{(t) => t('uploadPhotoHint')}</Translation>
          </p>
        </div>
      ) : (
        <div>
          <img className="rounded-lg" src={imgSrc} alt="" />
        </div>
      )}
      <div className="h-1 overflow-hidden rounded mt-1">
        {processing && (
          <Progress size="xs" colorScheme="yellow" isIndeterminate />
        )}
      </div>
    </>
  );
};