import { Avatar, AvatarBadge, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { IProfile } from "../interfaces";
import { useProjectCode } from "../hooks/useProjectCode";
import { Utils } from "../utils";
import { LoginPage } from "../pages/LoginPage";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { onlineUsersMemberNoAtom } from "../stores/mqtt";
import { useRecoilValue } from "recoil";

interface AvatorScrollRowProps {
  profiles: IProfile[]
}
export const AvatorScrollRow = ({ profiles }: AvatorScrollRowProps) => {
  const projectCode = useProjectCode();
  const [showLoginModal, setLoginModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const onlineUsersMemberNo = useRecoilValue(onlineUsersMemberNoAtom);

  return (
    <>
      <div className="flex flex-nowrap overflow-x-auto gap-1 pb-1 no-scrollbar">
        {
          profiles.map(profile => {
            if (!profile.avator) return null;
            return (
              <div
                key={profile.idProfile}
                className="p-[1px] bg-slate-100 rounded-full"
              >
                <Avatar
                  className={` ${
                    profile.blur
                      ? 'invert brightness-200 grayscale cursor-not-allowed'
                      : 'cursor-pointer'
                  }`}
                  src={Utils().avatorUrlBuilder(profile)}
                  onClick={() => {
                    if (Utils().isLogin()) {
                      if(!profile.blur) {
navigate(`/${projectCode.toLowerCase()}/share/${profile.simpleCode}`);
                      }
                    } else {
                      setLoginModal(true);
                    }
                  }}
                >
                  {profile.simpleCode &&
                    onlineUsersMemberNo.has(profile.simpleCode) && (
                      <AvatarBadge
                        borderColor="papayawhip"
                        bg="tomato"
                        boxSize="0.7em"
                      />
                    )}
                </Avatar>
              </div>
            );
          })
        }
      </div>
      <Modal isOpen={showLoginModal} onClose={() => { setLoginModal(false) }}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <LoginPage />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}