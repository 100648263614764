import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Translation } from 'react-i18next';
import {
  Input,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { useApi } from '../api';
import { ICargo, IChatMessage, IProfile } from '../interfaces';
import { Logo } from '../components/Logo';
import { Utils } from '../utils';
import { useSetRecoilState } from 'recoil';
import { publishChatMessageAtom } from '../stores/mqtt';
import { profileAtom } from '../stores/profile';
import { LanguageSelector } from '../components/LanguageSelector';

export const LoginPage = () => {
  const publicChatroomId = 'b3de29d2072b317fc5f8c87a5fbd9564';
  const navigate = useNavigate();
  const { projectCode } = useParams();
  const api = useApi(projectCode);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const setProfile = useSetRecoilState(profileAtom);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hotProfiles, setHotProfiles] = useState<IProfile[]>([]);

  const setPublishChatMessage = useSetRecoilState(publishChatMessageAtom);

  useEffect(() => {
    if (!projectCode) return;
    api.getHotProfile(projectCode, 30, (cargo: ICargo) => {
      const profiles: IProfile[] = cargo.info;

      setHotProfiles(
        profiles.filter((p) => p.avator && p.qualify && !p.blur).slice(0, 11)
      );
    });
  }, [projectCode]);

  const login = () => {
    setIsLoading(true);
    api.login(username, password, (cargo: ICargo) => {
      setIsLoading(false);
      if (cargo.returnCode === '000000') {
        setTimeout(() => {
          api.getProfile((cargo: ICargo) => {
            const profile: IProfile = cargo.info;
            if (profile.avator) {
              setPublishChatMessage({
                idMessage: Utils().getUuid(),
                chatroomId: publicChatroomId,
                message: ' 上線嘍，快跟他打聲招呼吧!',
                nickname: profile.nickname,
                memberNo: profile.simpleCode,
                avator: profile.avator,
                payload: {
                  type: 'text',
                  content: '上線嘍，快跟他打聲招呼吧!',
                },
                toProfile: profile,
                toMemberNo: profile.simpleCode,
                profile: {
                  nickname: profile.nickname,
                  simpleCode: profile.simpleCode,
                  avator: profile.avator,
                },
              } as IChatMessage);
            }
          });
        }, 100);
        api.getProfile((cargo: ICargo) => {
          if (cargo.success) {
            const profile: IProfile = cargo.info;
            setProfile(profile);
          }
        });
        navigate(`/${projectCode}/home`, { replace: true });
      } else {
        setErrorMessage(cargo.returnMessage);
        onOpen();
      }
    });
  };
  return (
    <>
      <h1 className="p-3">&nbsp;</h1>
      <div className="grid grid-cols-4 md:grid-cols-6">
        <div>
          <Logo></Logo>
        </div>
        {hotProfiles.map((profile) => {
          return (
            <img
              key={profile.idProfile}
              src={Utils().normalizeImagePath(profile.avator, projectCode)}
              className=" hover:scale-110 hover:rounded-md hover:shadow"
              alt={`${profile.idProfile}`}
            />
          );
        })}
      </div>
      <div className="pt-3">
        <Input
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          placeholder="Your Skype ID / Email"
        />
      </div>
      <div className="pt-3">
        <Input
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type="password"
          placeholder="Your Password"
        />
      </div>
      <div className="pt-3">
        <Button
          isLoading={isLoading}
          colorScheme={'white'}
          shadow="lg"
          color={'green.900'}
          width="100%"
          onClick={login}
        >
          <Translation>{(t) => t('login')}</Translation>
        </Button>
      </div>
      <div
        className="p-5 text-right cursor-pointer"
        onClick={() => {
          navigate(`/${projectCode}/registration`);
        }}
      >
        <Translation>{(t) => t('signUp')}</Translation>
      </div>
      <LanguageSelector />
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={'xs'}
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            <Translation>{(t) => t('error')}</Translation>
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{errorMessage}</AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose} colorScheme="red" ml={3}>
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
