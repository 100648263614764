import { useEffect, useState } from "react"
import { IBanner, ICargo, IChatMessage, IProfile } from "../interfaces"
import { useProjectCode } from "../hooks/useProjectCode"
import { useApi } from "../api";
import { Avatar, Button, Center, Divider, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, useDisclosure, useToast } from "@chakra-ui/react";
import { Utils } from "../utils";
import { useNavigate } from "react-router-dom";
import { BottomNavigation } from "../components/BottomNavigation";
import { Translation, useTranslation } from "react-i18next";
import chattingIcon from '../assets/imgs/chat-106-128.png';
import { EditIcon } from "@chakra-ui/icons";
import { usePrivateApi } from "../hooks/usePrivateApi";
import { usePublicApi } from "../hooks/usePublicApi";
import { StickyNote } from "../components/StickyNote";
import { useRecoilValue } from "recoil";
import { profileAtom } from "../stores/profile";
import { HintBlock } from "../components/HintBlock";
import { PhotosWallImageBox } from "../components/PhotosWall/PhotosWallImageBox";
import { isSafeModeAtom } from "../stores/view";
import { LookForFilter } from "../components/LookForFilter";

export const ProfileWallPage = () => {
  const chatroomId = 'b3de29d2072b317fc5f8c87a5fbd9564';
  const wallChatroomId = '42b90196b487c54069097a68fe98ab6f';
  const projectCode = useProjectCode();
  const api = useApi(projectCode);
  const publicApi = usePublicApi();
  const privateApi = usePrivateApi();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [profiles, setProfiles] = useState<IProfile[]>([]);
  const { t } = useTranslation();
  const [processing, setProcessing] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [lastChatroomMessage, setLastChatroomMessage] = useState<IChatMessage>();
  const [selectedMessage, setSelectedMessage] = useState<IChatMessage|null>(null);
  const [banners, setBanners] = useState<IBanner[]>([]);
  const isSafeMode = useRecoilValue(isSafeModeAtom);
  const [lookFor, setLookFor] = useState<number|string>();

  const profile = useRecoilValue(profileAtom);

  const goProfilePage = (profile: IProfile) => {
    navigate(`/${projectCode.toLowerCase()}/share/${profile.simpleCode}`)
  }

  const fetchChatroomMessages = () => {
    const uniqueSet = new Set<string>();
    const uniqueMsgs: IChatMessage[] = [];
    publicApi.getChatroomMessages(wallChatroomId, 100, undefined, (cargo: ICargo) => {
      const msgs: IChatMessage[] = cargo.info;
      msgs.forEach(msg => {
        if (
          msg.profile?.simpleCode &&
          !uniqueSet.has(msg.profile?.simpleCode)
        ) {
          uniqueSet.add(msg.profile?.simpleCode);
          uniqueMsgs.push(msg);
        }
      })
      setMessages(uniqueMsgs);
    })
  }

  const fetchLastChatroomLastMessage = () => {
    publicApi.getLastChatroomLastMessage(chatroomId, (cargo: ICargo) => {
      setLastChatroomMessage(cargo.info);
    })
  }

  const fetchBanners = () => {
    api.getBanners((cargo: ICargo) => {
      if (cargo.success) {
        const photos: IBanner[] = cargo.info;
        setBanners(photos.sort((a, b) => 0.5 - Math.random()));
      }
    });
  };

  useEffect(() => {
    api.getIntroProfiles(projectCode, 100, (cargo: ICargo) => {
      const data: IProfile[] = cargo.info;
      setProfiles(data.sort((a, b) => 0.5 - Math.random()))
    })
    fetchChatroomMessages();
    fetchLastChatroomLastMessage();
    fetchBanners();
  }, [])

  return (
    <>
      <div className="text-right p-4 pb-2">
        <Button
          rightIcon={<EditIcon />}
          colorScheme="teal"
          variant="outline"
          onClick={() => {
            if (!profile?.avator && !window.localStorage.getItem('avator')) {
              toast({
                title: '無法貼文',
                description: '因為您尚未上傳頭貼',
                status: 'error',
                duration: 9000,
                isClosable: true,
              });
            } else if (profile?.blur) {
              toast({
                title: '無法貼文',
                description: '因為您上傳頭貼不合規範',
                status: 'error',
                duration: 9000,
                isClosable: true,
              });
            } else if (profile?.blocked) {
              toast({
                title: '封鎖中',
                description: '系統將暫時停止您的貼文權限',
                status: 'error',
                duration: 9000,
                isClosable: true,
              });
            } else {
              onOpen();
            }
          }}
        >
          <Translation>{(t) => t('createPost')}</Translation>
        </Button>
      </div>
      <LookForFilter
        setLookFor={(lookFor) => {
          setLookFor(lookFor);
        }}
      />
      <div className="gap-3 columns-2 md:columns-4 lg:columns-6">
        <div className="w-full inline-block mb-3">
          <div className="shadow-lg rounded-lg px-5 py-2">
            <Center>
              <img
                src={chattingIcon}
                className="w-[70%]"
                onClick={fetchLastChatroomLastMessage}
              />
            </Center>
            {lastChatroomMessage &&
              !lastChatroomMessage.message.includes('emoji') &&
              !lastChatroomMessage.message.includes('img') && (
                <div
                  className="inline-flex items-center bg-yellow-400 border-yellow-400 border-4 rounded-full w-full overflow-hidden whitespace-nowrap select-none mt-1"
                  onClick={fetchLastChatroomLastMessage}
                >
                  <Avatar
                    src={Utils().avatorUrlBuilder(lastChatroomMessage.profile)}
                    size={'xs'}
                  />
                  <div className="ml-1">{lastChatroomMessage.message}</div>
                </div>
              )}
            <Divider className="mt-3 mb-2" />
            <Button
              colorScheme={'white'}
              color={'green.900'}
              width="100%"
              onClick={() => {
                if (!profile?.blocked) {
                  navigate(
                    `/${projectCode.toLowerCase()}/public-chat/${chatroomId}`
                  );
                } else {
                  toast({
                    title: '回報封鎖',
                    description: '您被檢舉次數過多，系統將自動停止使用權限。',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                  });
                }
              }}
            >
              <Translation>{(t) => t('chatroom')}</Translation>
            </Button>
          </div>
        </div>
        {messages
          .filter(
            (m) => !m.profile?.blur && m.profile?.avator && !m.profile?.blocked
          )
          .map((message, index) => {
            const profile = message.profile;
            if (!profile) return null;
            if (lookFor && profile.multiGender !== `${lookFor}`) return null;
            return (
              <StickyNote
                key={`m_${message.idMessage}_${index}`}
                profile={profile}
                content={message.message}
                timestamp={message.dateAdd}
                replyId={message.idChatroomMessage}
                replyCount={message.threadCount}
                onReply={fetchChatroomMessages}
              />
            );
          })}
        {profiles
          .filter(
            (profile) => !profile?.blur && profile.avator && !profile.blocked
          )
          .map((profile, index) => {
            if (
              typeof lookFor === 'number' &&
              profile.multiGender !== `${lookFor}`
            )
              return null;
            if (
              typeof lookFor === 'string' &&
              lookFor === 'SKYPE' &&
              !profile.skypeId
            )
              return null;
            if (
              typeof lookFor === 'string' &&
              lookFor === 'TWITTER' &&
              !profile.twitterId
            )
              return null;

            if (index % 3 === 0 && index < banners.length && !isSafeMode) {
              const photo = banners[index];
              return (
                <div key={photo.idBanner} className="shadow-lg rounded-lg">
                  <PhotosWallImageBox photo={photo} />
                </div>
              );
            }
            return (
              <StickyNote
                key={profile.idProfile}
                profile={profile}
                content={profile.introduction}
                onClickNickname={() => {
                  goProfilePage(profile);
                }}
              />
            );
          })}
      </div>
      <Button
        colorScheme={'white'}
        shadow="lg"
        color={'green.900'}
        width="100%"
        onClick={() => {
          navigate(`/${projectCode?.toLowerCase()}/all-profiles`);
        }}
      >
        <Translation>{(t) => t('more')}</Translation>
      </Button>
      <Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">張貼</DrawerHeader>
          <ModalCloseButton />
          <DrawerBody>
            <Center>
              <Textarea
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                value={message}
                rows={5}
              />
            </Center>
            <HintBlock
              firstLine="人性本善~請勿灌水~勿發表可能違法之言詞"
              secondLine="我們盡可能不要封鎖您的發言權"
              thirdLine="若不小心被檢舉，系統可能會無情的移除您大部份的發言"
            />
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            {message && (
              <Button
                variant="outline"
                isLoading={processing}
                onClick={() => {
                  setProcessing(true);
                  privateApi.sendChatroomMessage(
                    wallChatroomId,
                    message,
                    null,
                    Utils().getUuid(),
                    (cargo: ICargo) => {
                      setProcessing(false);
                      setMessage('');
                      onClose();
                      fetchChatroomMessages();
                    }
                  );
                }}
              >
                發佈
              </Button>
            )}
            {!message && (
              <Button
                variant="outline"
                isLoading={processing}
                onClick={() => {
                  onClose();
                }}
              >
                關閉
              </Button>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <div className="h-[100px]"></div>
      <BottomNavigation projectCode={projectCode}></BottomNavigation>
    </>
  );
}