import { useNavigate, useParams } from 'react-router-dom'
import { Box, Image, Center } from '@chakra-ui/react'
import logo from '../assets/imgs/logo.jpg';

export const Logo = () => {
  const { projectCode } = useParams()
  const navigate = useNavigate();
  
  return (
    <Center className='m-5'>
      <Box onClick={() => {
        navigate(`/${projectCode}/home`)
      }}>
        <Image
          borderRadius={'2xl'}
          src={logo}
          shadow={'dark-lg'}
        />
      </Box>
    </Center>
  )
}