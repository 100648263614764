import { atom } from "recoil";
import { IChatMessage } from "../interfaces";

export const unreadMessageCountAtom = atom<number>({
  key: "unreadMessageCount",
  default: 0,
});

export const nicknameSelectedAtom = atom<string>({
  key: "selectedNickname",
  default: ''
})

export const publicChatMessagesAtom = atom<IChatMessage[]>({
  key: "publicChatMessages",
  default: []
})