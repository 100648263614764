import { useNavigate } from "react-router-dom"
import { ICargo, IChatMessage, IChatMessageThread, IProfile } from "../interfaces"
import { useProjectCode } from "../hooks/useProjectCode"
import { Avatar, AvatarBadge, Button, Divider, FormControl, FormLabel, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Switch, Textarea, useDisclosure, useToast } from "@chakra-ui/react"
import { Utils } from "../utils"
import { useEffect, useState } from "react"
import { usePrivateApi } from "../hooks/usePrivateApi"
import { useApi } from "../api"
import { LuTrash2 } from "react-icons/lu"
import { Translation } from "react-i18next"
import { HintBlock } from "./HintBlock"
import { useRecoilValue } from "recoil"
import { onlineUsersMemberNoAtom } from "../stores/mqtt"
import { IoOpenOutline } from 'react-icons/io5';

interface StickyNoteProps {
  profile: IProfile,
  content?: string,
  timestamp?: string|Date,
  onClickNickname?: () => void,
  replyId?: number,
  replyCount?: number,
  onReply?: () => void
  deletable?: boolean,
  onDelete?: () => void
}
export const StickyNote = ({ profile, content, timestamp, onClickNickname, replyId, replyCount, onReply, deletable, onDelete } : StickyNoteProps) => {
  const navigate = useNavigate()
  const toast = useToast()
  const projectCode = useProjectCode();
  const api = useApi(projectCode);
  const privateApi = usePrivateApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [expand, setExpand] = useState<boolean>(false);
  const [showBadge, setBadge] = useState<boolean>(false);
  const [badgeColor, setBadgeColor] = useState<string>('gray')
  const [badgeText, setBadgeText] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isPrivate, setPrivate] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [threads, setThreads] = useState<IChatMessageThread[]>([]);
  const localMemberNo = window.localStorage.getItem('memberNo');
  const [toMemberNo, setToMemberNo] = useState<string>();
  const onlineUsersMemberNo = useRecoilValue(onlineUsersMemberNoAtom);

  const goProfilePage = () => {
    navigate(`/${projectCode.toLowerCase()}/s?c=${profile.simpleCode}`)
  }

  const loadThreads = (cargo: ICargo) => {
    if (cargo.success) {
      const messageDetail: IChatMessage = cargo.info;
      setThreads(messageDetail.threads ? messageDetail.threads.sort((a, b) => (a.idChatMessageThread || 0) - (b.idChatMessageThread || 0)) : [])
    }
  }

  const fetchThreads = () => {
    if (replyId) {
      if (Utils().isLogin()) {
        privateApi.getChatMessageDetail(replyId, loadThreads)
      } else {
        api.getChatMessageDetail(replyId, loadThreads)
      }
    }
  }

  useEffect(() => {
    if (!profile.lookFor) return;
    if (profile.lookFor === 0) return;
    if (profile.lookFor % 2 === 1) {
      setBadgeColor('cyan')
      
      if (profile.lookFor === 1 ) {setBadgeText('找主')}
      if (profile.lookFor === 3 ) {setBadgeText('找奴')}
      if (profile.lookFor === 5 ) {setBadgeText('找SW')}
      setBadge(true);
    }
    if (profile.lookFor % 2 === 0) {
      setBadgeColor('pink')

      if (profile.lookFor === 2 ) {setBadgeText('找主')}
      if (profile.lookFor === 4 ) {setBadgeText('找奴')}
      if (profile.lookFor === 6 ) {setBadgeText('找SW')}
      setBadge(true);
    }
  }, [])

  return (
    <div
      className="mb-3 shadow rounded-lg p-3 text-sm whitespace-break-spaces cursor-pointer"
      data-cmp-name="StickyNote"
    >
      <div className="w-full inline-block">
        <div className="flex justify-between" onClick={goProfilePage}>
          <Avatar
            src={Utils().avatorUrlBuilder(profile)}
            className={profile.blur ? 'blur-sm' : ''}
            size={profile.blur ? 'sm' : 'md'}
          >
            {profile.simpleCode &&
              onlineUsersMemberNo.has(profile.simpleCode) && (
                <AvatarBadge
                  borderColor="papayawhip"
                  bg="tomato"
                  boxSize="0.7em"
                />
              )}
          </Avatar>
          {showBadge && (
            <span
              className={`bg-${badgeColor}-100 text-${badgeColor}-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-${badgeColor}-900 dark:text-${badgeColor}-300 h-5`}
            >
              {badgeText}
            </span>
          )}
          {/* {(profile.multiGender === '1' || profile.multiGender === '3') && <span className="bg-cyan-100 text-cyan-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-cyan-900 dark:text-cyan-300 h-5">
            {profile.multiGender === '1' && '找奴'}
            {profile.multiGender === '3' && '找主'}
          </span>}
          {(profile.multiGender === '2' || profile.multiGender === '4') && <span className="bg-pink-100 text-pink-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300 h-5">
            {profile.multiGender === '2' && '找奴'}
            {profile.multiGender === '4' && '找主'}
          </span>} */}
        </div>
        <Divider className="mt-3 mb-2" />
        <div
          className={`${
            expand ? 'max-h-fix' : 'max-h-36'
          } overflow-hidden text-gray-500`}
          onClick={() => {
            setExpand((expand) => !expand);
          }}
        >
          {profile.avator && profile.nickname ? (
            profile.blur ? (
              <div>
                該用戶頭貼不適合顯示
                <br />
                暫時無法顯示內容
              </div>
            ) : (
              content
            )
          ) : (
            <div>
              該用戶資料不完整
              <br />
              暫時無法顯示內容
            </div>
          )}
        </div>
        <Divider className="mt-2 mb-1" />
        <div className="flex justify-between items-center">
          <div className="flex gap-1">
            {replyId && (
              <Icon
                as={IoOpenOutline}
                fontSize={'2xl'}
                bgColor={'gray.200'}
                textColor={'white'}
                className="rounded-full p-1 cursor-pointer"
                onClick={() => {
                  navigate(`/${projectCode}/notes/${replyId}`);
                }}
              />
            )}
            {deletable && (
              <Icon
                as={LuTrash2}
                fontSize={'2xl'}
                bgColor={'gray.400'}
                textColor={'white'}
                className="rounded-full p-1 cursor-pointer"
                onClick={() => {
                  if (replyId) {
                    privateApi.delChatroomMessage(replyId, false, onDelete);
                  }
                  toast({
                    title: '刪除訊息',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                  });
                }}
              />
            )}
          </div>
          <div>
            {profile.nickname && (
              <div
                className="text-right relative"
                onClick={
                  onClickNickname
                    ? onClickNickname
                    : () => {
                        onOpen();
                        fetchThreads();
                      }
                }
              >
                {replyCount && replyCount > 0 ? (
                  <span className="bg-red-100 text-red-800 text-xs font-medium px-2 py-0.5 dark:bg-red-900 dark:text-red-300 rounded-full absolute right-[-10px] top-[-14px]">
                    {replyCount}
                  </span>
                ) : (
                  ''
                )}
                <div className="mt-1 mr-1">By {profile.nickname}</div>
                {timestamp && (
                  <div className="text-xs text-neutral-300">
                    {timestamp ? `${Utils().datetimeFormatter(timestamp)}` : ''}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        onClose={onClose}
        size={'xl'}
        isOpen={isOpen}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Avatar
              src={Utils().normalizeImagePath(profile?.avator, projectCode)}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col">
            <div className="min-h-[300px] overflow-scroll text-neutral-700 whitespace-break-spaces rounded-lg bg-slate-100 p-3">
              <div>
                {profile.avator && profile.nickname
                  ? content
                  : '該用戶資料不完整，暫時無法顯示內容。'}
              </div>
              {threads.map((thread) => {
                return (
                  <div
                    key={thread.idChatMessageThread}
                    className="bg-white mt-3 ml-3 p-3 rounded"
                  >
                    <div className="flex justify-between">
                      <Avatar
                        size={'xs'}
                        src={Utils().normalizeImagePath(
                          thread.profile?.avator,
                          projectCode
                        )}
                        className="cursor-pointer"
                        onClick={() => {
                          navigate(
                            `/${projectCode.toLowerCase()}/s?c=${
                              thread.profile?.simpleCode
                            }`
                          );
                        }}
                      />
                    </div>
                    <Divider className="mt-3" />
                    <div className="text-slate-500">{thread.message}</div>
                    <div className="text-xs text-right text-slate-300">
                      {Utils().datetimeFormatter(`${thread.dateAdd}Z`)}
                    </div>
                  </div>
                );
              })}
            </div>
            {replyId && (
              <div className="mt-3">
                <Textarea
                  rows={3}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                ></Textarea>
                <HintBlock
                  firstLine="請必務謹慎回覆留言"
                  secondLine="版主可能對反感的留言回報封鎖"
                />
                {profile.simpleCode !== localMemberNo && (
                  <div>
                    <FormControl
                      className="mt-2 ml-1 mr-4 pr-4"
                      display="flex"
                      alignItems="center"
                    >
                      <FormLabel htmlFor="email-alerts" mb="0">
                        私訊回覆
                      </FormLabel>
                      <Spacer />
                      <Switch
                        id="is-private-switch"
                        size={'lg'}
                        colorScheme={'yellow'}
                        isChecked={isPrivate}
                        onChange={(e) => {
                          setPrivate(e.target.checked);
                          if (
                            e.target.checked &&
                            !window.localStorage.getItem('avator')
                          ) {
                            setPrivate(false);
                            privateApi.getProfile((cargo: ICargo) => {
                              const profile: IProfile = cargo.info;
                              if (profile.avator) {
                                setPrivate(true);
                                window.localStorage.setItem(
                                  'avator',
                                  profile.avator
                                );
                              }
                            });
                          }
                        }}
                      />
                    </FormControl>
                    <div className="ml-1 text-xs text-red-700">
                      若您尚未上傳頭貼、無法使用私訊回覆。
                    </div>
                  </div>
                )}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            {replyId && message && (
              <Button
                isLoading={processing}
                onClick={() => {
                  if (!message) return;
                  setProcessing(true);
                  privateApi.sendChatroomMessageThread(
                    replyId,
                    message,
                    isPrivate,
                    (cargo: ICargo) => {
                      setProcessing(false);
                      fetchThreads();
                      setMessage('');
                      if (onReply) {
                        onReply();
                      }
                    }
                  );
                }}
              >
                {isPrivate ? '只回覆給作者' : '回覆'}
              </Button>
            )}
            {!message && (
              <Button variant="outline" onClick={onClose}>
                <Translation>{(t) => t('close')}</Translation>
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}