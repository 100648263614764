import { useDisclosure, Button, CloseButton } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Wheel } from "react-custom-roulette"
import {  IUserCharacter } from "../interfaces"
import { WheelData } from "react-custom-roulette/dist/components/Wheel/types"
import { Utils } from "../utils"
import { Translation, useTranslation } from "react-i18next"
import { useProjectCode } from "../hooks/useProjectCode"
import { useNavigate } from "react-router-dom"

interface RouletteModalProps {
  userCharacters: IUserCharacter[],
  open?: boolean,
  onClick?: () => void,
}

export const RouletteModal = ({ userCharacters, open, onClick }: RouletteModalProps) => {
  const { t } = useTranslation()
  const projectCode = useProjectCode()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [mustSpin, setMustSpin] = useState<boolean>(false)
  const [prizeNumber, setPrizeNumber] = useState<number>(0)
  const [wheelData, setWheelData] = useState<WheelData[]>([])

  useEffect(() => {
    if (!userCharacters) return;
    const data: WheelData[] = [];
    let odd = true;
    userCharacters.map(userCharacter => {
      data.push({ option: userCharacter.character.title, style: {backgroundColor: odd ? 'white' : 'pink'} })
      odd = !odd;
    })
    if (!odd) {
      data.push({ option: "Go", style: { backgroundColor: odd ? 'white' : 'pink' } })
    }
    setWheelData(data)
    if (open) {
      onOpen()
    }
  }, [userCharacters])

  const handleSpinClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * wheelData.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  }

  const shareTo = () => {
    const url = new URL(window.location.href);
    url.searchParams.set('showRoulette', 'true')
    if (window.navigator.share) {
      window.navigator.share({
        title: 'Profile Share',
        url: url.toString()
      }).then(() => {
        console.log('Thanks for sharing!');
      }).catch(console.error);
    }
  }
  
  return (
    <>
      <Button
        className="w-full rounded-xl"
        colorScheme="white"
        textColor={'black'}
        shadow={'lg'}
        rounded={'xl'}
        onClick={() => {
          if (onClick) {
            onClick();
          }
          onOpen();
        }}
      >
        {t('roulette')}
      </Button>
      {isOpen && (
        <div className="fixed top-20 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden white opacity-100 flex flex-col items-center backdrop-blur-xl rounded-t-3xl border-2 border-black">
          <div className="fixed top-5 right-5">
            <CloseButton
              size={'lg'}
              colorScheme="whiteAlpha"
              onClick={onClose}
            />
          </div>
          <div onClick={handleSpinClick} className="pt-20">
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={wheelData}
              outerBorderColor={'#ccc'}
              outerBorderWidth={0}
              innerBorderColor={'#ccc'}
              radiusLineColor={'tranparent'}
              radiusLineWidth={1}
              textDistance={70}
              backgroundColors={['#3e3e3e', '#df3428']}
              onStopSpinning={() => {
                setMustSpin(false);
              }}
            />
          </div>
          <div className="mt-5">
            <Button
              onClick={() => {
                navigate(`/${projectCode.toLowerCase()}/profiles`);
              }}
            >
              認識更多同好
            </Button>
          </div>
          <div className="mt-5">
            {window.navigator.share !== undefined && (
              <Button onClick={shareTo}>邀請朋友一起來玩吧</Button>
            )}
          </div>
          {!Utils().isLogin() && (
            <div className="mt-5 p-5">
              <div>我們誠摯的邀請您與您的伴侶一同加入轉盤的行列</div>
              <div className="mt-5">
                <Button
                  className="w-full rounded-xl"
                  colorScheme="white"
                  textColor={'black'}
                  shadow={'lg'}
                  rounded={'xl'}
                  onClick={() => {
                    navigate(`/${projectCode.toLowerCase()}/registration`);
                  }}
                >
                  <Translation>{(t) => t('signUp')}</Translation>
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}