import { InfoOutlineIcon } from "@chakra-ui/icons"
import { Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, useDisclosure } from "@chakra-ui/react"
import { ReactChild } from "react"

interface HintIconProps {
  title: string,
  children: ReactChild,
}
export const HintIcon = ({ title, children }: HintIconProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  return (
    <div>
      {!isOpen && <InfoOutlineIcon className="cursor-pointer" onClick={onOpen} />}
      {isOpen && <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <PopoverTrigger>
          <InfoOutlineIcon className="cursor-pointer" />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>{title}</PopoverHeader>
          <PopoverBody className="text-sm">{children}</PopoverBody>
        </PopoverContent>
      </Popover>}
    </div>
  )
}