import { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Spacer,
  FormControl,
  FormLabel,
  Switch,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  Divider,
  Avatar,
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Center,
  Textarea,
  Icon,
} from '@chakra-ui/react';
import { useApi } from '../api'
import { ICargo, IGuestbook, IProfile } from "../interfaces";
import { BottomNavigation } from '../components/BottomNavigation'
import { t } from 'i18next'
import { usePrivateApi } from '../hooks/usePrivateApi';
import { Utils } from '../utils';
import { LuTrash2, LuHistory, LuMessagesSquare } from 'react-icons/lu';
import { useRecoilState } from 'recoil';
import { profileAtom } from '../stores/profile';

export const GuestbookPage = () => {
  const navigate = useNavigate()
  const { projectCode } = useParams()
  const api = useApi(projectCode)
  const privateApi = usePrivateApi();
  const [guestbookItems, setGuestbookItems] = useState<[]>()
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [historyMessages, setHistoryMessages] = useState<IGuestbook[]>([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const [replyProfile, setReplyProfile] = useState<IProfile|null>(null)
  const [replyMessage, setReplyMessage] = useState<string>('')
  const [processing, setProcessing] = useState<boolean>(false)

  const rollToBottom = () => {
    const history = document.getElementById('history')
    if (!history) return;
    if (window.localStorage.getItem('disableRollToBottom') === 'true') return;
    history.scrollTo({
      left: 0,
      top: history.scrollHeight,
      behavior: 'smooth'
    });
  }

  const loadHistories = (memberNo?: string, cb?: Function) => {
    if (!memberNo) return;
    privateApi.getGuestbooksHistories(memberNo, (cargo: ICargo) => {
      if (cb) {
        cb();
      }
      setHistoryMessages(cargo.info)
      setTimeout(rollToBottom, 100)
    })
    onOpen()
  }

  const getGuestBooks = useCallback(() => {
    api.getGuestbooks((cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        let myGuestbook = cargo.info
        const items = myGuestbook.map((guestbook: IGuestbook) => 
          <div
            key={`gbm_${guestbook['idGuestbook']}`}
            style={{whiteSpace: 'pre-wrap'}}
            className='p-3 '
          >
            <div className='flex justify-between'>
              <Avatar
                src={Utils().avatorUrlBuilder(guestbook.profile)}
                size={'sm'}
                onClick={() => {
                  navigate(`/${projectCode}/s?c=${guestbook['profile']['simpleCode']}`)
                }}
                className={`${(guestbook.profile.blur || guestbook.profile.blocked) ? 'blur-sm' : ''} cursor-pointer`} 
              />
              <div
                className='text-right text-sm '
              >
                {Utils().datetimeFormatter(guestbook.timelog)}
              </div>
            </div>
            <div className='text-gray-600 text-ellipsis overflow-y-scroll mt-1'>{guestbook['message']}</div>
            <div className='flex justify-end gap-3'>
              <div className='text-right text-sm italic mt-1 mb-3'>
                By {guestbook['profile']['nickname'] ? guestbook['profile']['nickname'] : '...'}
              </div>
            </div>
            <div className='flex justify-end gap-3'>
              <Icon
                as={LuMessagesSquare}
                fontSize={'2xl'}
                bgColor={'gray.400'}
                textColor={'white'}
                className="rounded-full p-1 cursor-pointer"
                onClick={() => {
                  setReplyProfile(guestbook.profile)
                }}
              />
              <Icon
                as={LuHistory}
                fontSize={'2xl'}
                bgColor={'gray.400'}
                textColor={'white'}
                className="rounded-full p-1 cursor-pointer"
                onClick={() => {
                  loadHistories(guestbook.profile?.simpleCode)
                }}
              />
              <Icon
                as={LuTrash2}
                fontSize={'2xl'}
                bgColor={'gray.400'}
                textColor={'white'}
                className="rounded-full p-1 cursor-pointer"
                onClick={() => {
                  if (!guestbook.idGuestbook) return;
                  toast({
                    title: '刪除訊息',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                  })
                  privateApi.delGuestbook(guestbook.idGuestbook, () => {
                    getGuestBooks()
                  })
                }}
              />
            </div>
            <div className='p-3' onDoubleClick={() => {
              window.navigator.clipboard.writeText(guestbook.message)
              toast({
                title: 'Copied',
                description: guestbook.message,
                status: 'success',
                duration: 1000,
                isClosable: true,
              })
            }} >
              <Divider />
            </div>
          </div>
        )
        setGuestbookItems(items)
      }
    })
  }, [api, navigate, projectCode])

  useEffect(() => {
    api.getProfile((cargo: ICargo) => {
      setProfile(cargo.info)
    })
    getGuestBooks();
  }, [])

  return (
    <>
      {/* <LastChatroomMessageBlock /> */}
      <div className="p-3">
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="email-alerts" mb="0" className='p-1'>
            {t('enableEmailAlert')}
          </FormLabel>
          <Spacer />
          {profile && <Switch
            id="email-alerts"
            size={'lg'}
            colorScheme={'yellow'}
            isDisabled={!profile}
            isChecked={profile && !profile.muted}
            onChange={(e) => {
              api.updateMuted(!e.target.checked);
              setProfile({ ...profile, muted: !e.target.checked });
              if (e.target.checked) {
                if (window.Notification) {
                  Notification.requestPermission();
                }
                if (!('serviceWorker' in navigator)) {
                  // Service Worker isn't supported on this browser, disable or hide UI.
                  return;
                }

                if (!('PushManager' in window)) {
                  // Push isn't supported on this browser, disable or hide UI.
                  return;
                }
              }
            }}
          />}
        </FormControl>
      </div>
      <div className='border rounded-lg shadow'>{guestbookItems}</div>
      <Drawer isOpen={isOpen} placement="bottom" size="full" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader className="mb-3"></DrawerHeader>

          <DrawerBody id="history">
            <div>
              {historyMessages.map((guestbook) => {
                return (
                  <div
                    className="p-3 rounded-lg mb-2 text-sm text-gray-600 border whitespace-pre-wrap"
                    key={guestbook.idGuestbook}
                  >
                    <span className="bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400">
                      {guestbook.profile.nickname}
                    </span>
                    <div className="mt-3 bg-slate-100 p-3 rounded-lg">
                      {guestbook.message}
                    </div>
                    <div className="text-right text-xs mt-1">
                      {Utils().datetimeFormatter(guestbook.timelog)}
                    </div>
                  </div>
                );
              })}
            </div>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              {t('close')}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Modal
        isOpen={replyProfile !== null}
        onClose={() => setReplyProfile(null)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Avatar
              src={Utils().normalizeImagePath(
                replyProfile?.avator,
                projectCode
              )}
              className={
                replyProfile?.blur || replyProfile?.blocked ? 'blur-sm' : ''
              }
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Textarea
                onChange={(e) => {
                  setReplyMessage(e.target.value);
                }}
                value={replyMessage}
                rows={10}
              />
            </Center>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="teal"
              variant="outline"
              onClick={() => {
                if (!replyProfile?.simpleCode) return;
                setProcessing(true);
                api.leaveMessage(
                  replyProfile.simpleCode,
                  replyMessage,
                  (cargo: ICargo) => {
                    setProcessing(false);
                    if (cargo.success) {
                      setReplyMessage('');
                      loadHistories(replyProfile.simpleCode, () => {
                        setReplyProfile(null);
                      });
                    }
                  }
                );
              }}
              isLoading={processing}
            >
              送出
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <div className="h-[130px]"></div>
      <BottomNavigation projectCode={projectCode}></BottomNavigation>
    </>
  );
};