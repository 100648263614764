import { LockIcon } from "@chakra-ui/icons"
import { Avatar, AvatarGroup, Box, Flex, Spacer, VStack } from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import { Utils } from "../utils"

export const CharacterFlavor = (props: any) => {
  const { projectCode } = useParams()
  const character = props.character
  let lockers = []
  for (let i = 0; i < props.character.level; ++i) {
    lockers.push(<LockIcon key={`lv${i}`} color={'gray.300'} />)
  }

  return (
    <>
      <Flex mb='6' className='cursor-pointer'>
        <VStack
          align='stretch'
        >
          <Box pl='4' pt='2'>
            {
              character['checked'] ?
                <div className='text-md font-bold'>{character['title']}</div>
                :
                <div className='text-md text-gray-400'>{character['title']}</div>
            }
          </Box>
          <Box pl='4'>
            {
              character['userCharacters'].length > 0 && <AvatarGroup size='xs' max={10}>
                {character['userCharacters'].map((userCharacter: any, index: number) => {
                  return <Avatar
                    key={`userCharacter_${index}`}
                    className={`${character['checked'] ? 'opacity-80' : 'opacity-30'} ml-1 ${!Utils().isLogin() && 'blur-sm'}`}
                    name={`${userCharacter['nickname']}`}
                    src={`${Utils().normalizeImagePath(userCharacter['avator'], projectCode)}`}
                  />
                })}
              </AvatarGroup>
            }
            { character['userCharacters'].length === 0 && lockers }
          </Box>
        </VStack>
        <Spacer />
        <Box p='4'>
          {character['checked'] ?
            <svg className='h-10 w-10' width="160px" height="160px" viewBox="0 -2.5 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M77.641 109.484C72.9009 109.484 65.9845 107.282 59.4934 103.692C55.9045 101.703 52.8079 99.087 50.9573 95.2983C50.3885 94.3292 50.0498 93.2424 49.9673 92.1217C49.9971 91.0383 50.0351 90.0267 51.0894 89.5737C51.6234 89.3877 52.1991 89.3564 52.7502 89.4832C53.3013 89.6101 53.8053 89.89 54.2042 90.2908C55.4807 91.5232 56.1747 93.4206 57.5453 94.4817C66.8574 101.695 77.1602 104.852 88.8085 101.248C94.2358 99.5683 98.0015 95.7554 100.849 90.9551C101.535 89.7978 102.028 88.3284 103.041 87.614C104.176 86.8143 106.105 86.0328 107.127 86.492C108.149 86.9511 108.975 88.9466 108.99 90.2786C109.005 91.7256 108.188 93.3061 107.389 94.619C101.248 104.714 92.1908 109.602 77.641 109.484Z" fill="black" />
              <path d="M48.7689 46.7527C56.5141 46.5808 61.2223 49.154 65.1558 53.3597C65.8554 54.0913 66.4025 54.9547 66.7654 55.8997C67.6822 58.4559 65.8553 60.6607 63.2585 59.8447C61.3835 59.1397 59.6673 58.0693 58.2096 56.6954C54.6627 53.6259 50.8044 52.8098 46.2844 54.0463C39.6382 55.8644 34.3666 59.2137 31.658 65.8659C31.0412 67.38 30.0464 68.7838 28.0956 68.1371C26.0134 67.4471 25.7736 65.4705 26.4596 63.9388C27.8179 60.9072 29.2135 57.709 31.3655 55.253C36.3662 49.5493 43.2061 47.3906 48.7689 46.7527Z" fill="black" />
              <path d="M128.999 55.286C128.647 55.7519 128.071 57.2802 127.103 57.5931C126.476 57.7121 125.831 57.6926 125.212 57.5359C124.594 57.3791 124.017 57.089 123.523 56.6858C122.394 55.6958 121.753 54.1234 120.986 52.7542C120.513 51.7836 119.747 50.9866 118.796 50.4761C117.844 49.9656 116.756 49.7676 115.686 49.9102C109.777 50.4194 104.475 52.115 100.66 57.1178C100.081 57.8775 98.0438 57.8937 97.6625 57.5449C96.8499 56.8001 96.0956 55.8521 96.382 54.9129C96.9428 53.2144 97.9063 51.677 99.1901 50.4316C104.299 45.4302 110.651 43.4299 117.672 43.6236C122.98 43.7671 128.8 49.51 128.999 55.286Z" fill="black" />
              <path d="M44.2051 75.5854C45.3123 76.1089 46.525 77.6676 46.5751 78.8113C46.5179 79.5021 46.3007 80.1701 45.9409 80.7626C45.581 81.355 45.0884 81.8556 44.5017 82.2248C40.3135 84.37 35.7929 85.2273 31.1368 83.9441C29.4047 83.4701 27.7274 82.5953 27.5967 80.3804C27.4701 78.2352 28.8027 76.9533 30.5375 76.3114C33.0281 75.3904 42.4391 74.7505 44.2051 75.5854Z" fill="black" />
              <path d="M120.918 78.5489C117.829 77.3348 117.058 74.0643 119.461 71.8148C120.841 70.5528 122.524 69.6701 124.347 69.253C126.265 68.8379 128.375 69.0864 130.374 69.3099C132.321 69.5279 134.129 70.2457 134.66 72.4776C134.962 73.4622 134.91 74.5213 134.513 75.4717C134.116 76.422 133.4 77.2037 132.488 77.6815C130.481 78.7595 122.457 79.1543 120.918 78.5489Z" fill="black" />
              <path d="M3.27272 55.9748C-3.73711 83.5259 2.40121 107.831 21.5299 128.223C22.0283 128.75 22.5355 129.277 23.0434 129.785C33.3184 140.06 46.1171 146.404 56.2588 150.819C60.6115 152.719 82.9451 155.028 88.988 154.128C124.899 148.765 148.531 128.511 156.762 94.625C158.333 87.9873 159.116 81.1873 159.094 74.3661C159.132 58.2974 152.366 39.6163 138.414 25.6251C136.977 24.4373 135.546 23.2069 134.162 22.0145C131.16 19.4339 128.057 16.762 124.81 14.4834C100.013 -2.97008 74.2479 -3.06774 48.2339 6.1875C24.6807 14.5618 9.55529 31.3158 3.27272 55.9748ZM48.3341 14.4373C71.1594 4.95246 94.0612 3.27855 116.398 17.4844C120.482 20.0847 131.371 28.8849 133.085 30.2067L133.195 30.2879L133.286 30.3889C142 39.6299 151.978 56.5268 151.758 73.8963C151.686 81.3232 150.68 88.7115 148.766 95.8878C143.092 116.511 129.527 131.541 107.295 141.821C84.6956 152.642 52.2846 141.702 43.6611 136.01C34.1295 129.493 25.7895 121.386 19.0056 112.043C8.36622 96.9802 5.97929 75.3276 11.6958 55.5339C14.3715 46.3948 19.0474 37.9656 25.3844 30.8574C31.7214 23.7493 39.5608 18.1403 48.3341 14.4373Z" fill="black" />
            </svg>
            :
            <svg className='h-10 w-10 opacity-30' width="160px" height="160px" viewBox="0 -2.5 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M87.538 92.1827C97.9431 92.1645 106.519 94.2223 114.589 98.2723C116.331 99.0502 117.883 100.197 119.138 101.632C119.933 102.664 120.369 104.903 119.77 105.838C119.061 106.944 117.043 107.783 115.639 107.724C113.96 107.656 112.218 106.694 110.692 105.799C98.8416 98.8465 86.2305 98.0448 73.1575 101.083C66.1964 102.7 60.7311 106.722 56.2288 112.153C55.7263 112.759 55.2808 113.415 54.7797 114.021C53.7294 115.286 52.7144 117.294 50.7784 115.975C48.8824 114.685 49.353 112.551 50.4074 110.829C54.8386 103.583 60.9255 98.3014 68.9991 95.4147C74.9745 93.3853 81.2285 92.2951 87.538 92.1827Z" fill="black" />
              <path d="M54.0803 78.0532C49.5096 77.8419 45.3919 76.3223 42.2702 72.6393C40.9518 71.0819 39.9117 69.2014 41.677 67.5533C43.3916 65.9518 44.9043 67.2879 46.2816 68.6632C52.0293 74.3986 59.4542 72.6177 62.1736 64.9002C62.5481 63.8384 62.8954 62.7651 63.3248 61.7271C64.0019 60.0945 65.3176 58.879 66.9746 59.6333C67.5691 59.9691 68.0816 60.4329 68.475 60.9909C68.8685 61.5489 69.1331 62.1873 69.2498 62.86C69.4694 65.0038 69.1479 67.1683 68.3148 69.1557C67.4816 71.1431 66.1634 72.8899 64.4806 74.2361C61.5181 76.7328 58.0301 77.8277 54.0803 78.0532Z" fill="black" />
              <path d="M105.686 73.2745C103.031 73.029 100.473 72.1462 98.2312 70.701C95.9893 69.2558 94.1294 67.2912 92.8092 64.9735C91.9507 63.3999 91.5519 61.6166 91.658 59.8271C91.7061 58.825 92.9818 57.254 93.8994 57.1037C94.7566 56.9601 96.4048 58.1464 96.7671 59.1019C97.7225 61.6283 98.9665 63.733 101.334 65.1428C105.489 67.6171 108.169 66.9033 110.372 62.6372C112.149 59.1973 113.868 58.0422 115.853 58.9536C117.709 59.8061 118.149 62.2297 117.064 65.6323C115.548 70.3954 111.681 73.2393 105.686 73.2745Z" fill="black" />
              <path d="M41.2736 52.0625C39.2472 51.067 37.2845 49.9469 35.3967 48.7087C34.8874 48.3396 34.9017 46.8018 35.1895 45.9797C35.3249 45.6005 36.8146 45.3133 37.4769 45.5612C44.0702 48.0382 48.9443 44.7885 53.5347 40.7852C54.8714 39.6192 55.8025 37.9974 56.9746 36.6316C58.1258 35.2915 59.5126 34.0584 61.3084 35.2773C63.0656 36.4725 62.5476 38.3143 61.7621 39.8406C58.5835 46.0162 53.0858 49.0581 46.6312 50.6832C45.002 51.0936 43.3132 51.2573 41.6494 51.535C41.5235 51.7106 41.3982 51.8864 41.2736 52.0625Z" fill="black" />
              <path d="M111.841 48.0808C105.864 47.6597 100.966 45.7027 97.2146 41.373C96.3542 40.4872 95.7235 39.4044 95.3775 38.2189C95.1933 37.3169 95.5935 35.7925 96.2619 35.3612C96.9302 34.9298 98.7267 35.1486 99.2 35.7424C103.127 40.6707 108.366 41.8768 114.283 41.6337C116.029 41.562 117.843 42.0753 117.963 44.2638C118.074 46.2817 116.616 47.2432 114.824 47.6427C113.733 47.8865 112.609 47.9725 111.841 48.0808Z" fill="black" />
              <path d="M3.29432 55.9748C-3.71551 83.5259 2.42283 107.831 21.5516 128.223C22.0499 128.75 22.5571 129.277 23.065 129.785C33.34 140.06 46.1388 146.404 56.2804 150.819C60.6324 152.719 82.9668 155.028 89.0089 154.128C124.92 148.765 148.553 128.511 156.783 94.625C158.355 87.9873 159.138 81.1873 159.115 74.3661C159.154 58.2974 152.388 39.6163 138.435 25.6251C136.999 24.4373 135.568 23.2069 134.183 22.0145C131.181 19.4339 128.078 16.762 124.832 14.4834C100.034 -2.97008 74.2695 -3.06774 48.2549 6.1875C24.701 14.5618 9.57486 31.3158 3.29432 55.9748ZM48.3557 14.4373C71.181 4.95246 94.0828 3.27855 116.419 17.4844C120.504 20.0847 131.392 28.8849 133.106 30.2067L133.216 30.2879L133.307 30.3889C142.021 39.6299 152 56.5268 151.78 73.8963C151.707 81.3232 150.702 88.7115 148.787 95.8878C143.113 116.511 129.548 131.541 107.317 141.821C84.7172 152.642 52.3062 141.702 43.6828 136.01C34.1511 129.493 25.8111 121.386 19.0272 112.043C8.38782 96.9802 6.0002 75.3276 11.714 55.5339C14.3899 46.3946 19.0661 37.9653 25.4035 30.8571C31.7409 23.7489 39.5807 18.1401 48.3544 14.4373H48.3557Z" fill="black" />
            </svg>
          }
        </Box>
      </Flex>
    </>
  )
}