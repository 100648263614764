import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom'
import { useApi } from '../api'
import { ICargo } from '../interfaces'
import placeholder from '../assets/imgs/dog-placeholder.png';
import { Progress } from '@chakra-ui/react'
import { Utils } from '../utils'

interface ProfileAvatarUploaderProps {
  onUploaded: (cargo: ICargo, isGraphic: boolean) => void;
  error: boolean;
  image: string | null;
}

export const ProfileAvatarUploader = (props: ProfileAvatarUploaderProps) => {
  const { projectCode } = useParams();
  const api = useApi(projectCode);
  const [processing, setProcessing] = useState<boolean>(false);
  const [isGraphic, setIsGraphic] = useState<boolean>(false);

  const [imgSrc, setImgSrc] = useState<string | null>(null);

  const uploadFile = useCallback(
    (fileOfBlob: Blob) => {
      setProcessing(true);
      api.uploadPersonalFile(fileOfBlob, (cargo: ICargo) => {
        setProcessing(false);
        props.onUploaded(cargo, isGraphic);
      });
    },
    [api, props]
  );

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          if (binaryStr) {
            setImgSrc(binaryStr.toString());
            const img = new Image();
            img.src = binaryStr.toString();
            img.onload = () => {
              const width = 300;
              const elem = document.createElement('canvas');
              elem.width = width;
              elem.height = 300;

              const ctx = elem.getContext('2d');
              if (ctx) {
                ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, 300, 300);
                ctx.canvas.toBlob(
                  (blob) => {
                    const fileOfBlob: BlobPart = new File(
                      [blob as BlobPart],
                      file['name'],
                      {
                        type: file['type'],
                        lastModified: Date.now(),
                      }
                    );
                    uploadFile(fileOfBlob);
                    setImgSrc(elem.toDataURL());
                  },
                  file['type'],
                  1
                );
                const imageData = ctx.getImageData(
                  0,
                  0,
                  elem.width,
                  elem.height
                );
                setIsGraphic(Utils().isGraphic(imageData));
              }
              // img.width and img.height will contain the original dimensions
            };
          }
        };
        reader.readAsDataURL(file);
      });
    },
    [uploadFile]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },
  });

  return (
    <>
      {imgSrc === null ? (
        <div
          className={`border border-dashed rounded-lg ${
            props.error ? 'border-red-500 border-2' : ''
          }`}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <p className="whitespace-pre-wrap text-gray-500">
            <img
              className="rounded-lg"
              width={'110px'}
              height={'110px'}
              src={props.image ? props.image : placeholder}
              alt=""
            />
          </p>
        </div>
      ) : (
        <div>
          <img
            className="rounded-lg"
            width={'110px'}
            height={'110px'}
            src={imgSrc}
            alt=""
          />
        </div>
      )}
      <div className="h-1 overflow-hidden rounded">
        {processing && (
          <Progress size="xs" colorScheme="yellow" isIndeterminate />
        )}
      </div>
    </>
  );
};