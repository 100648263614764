import { useToast } from '@chakra-ui/react';
import mqtt from 'mqtt';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { IChatMessage, INotification } from '../interfaces';
import { unreadMessageCountAtom } from '../stores/message';
import { chatroomIdAtom, connectionStatusAtom, onlineUsersMemberNoAtom, prevChatroomIdAtom, publishChatMessageAtom, publishNewNotifyAtom, publishNotifyAtom, receivedChatMessageAtom, receivedNewNotifyAtom, receivedNotifyAtom, receivedReloadChatroomAtom, reloadChatroomAtom } from '../stores/mqtt';
import { referralCodeAtom } from '../stores/profile';

export const MqttProvider = () => {
  let isMounted = false;
  let messageChannelConnected = false;

  const toast = useToast();

  const [mqttClient, setMqttClient] = useState<mqtt.MqttClient>();
  const [connected, setConnectionStatus] = useRecoilState(connectionStatusAtom);
  const referralCode = useRecoilValue(referralCodeAtom);
  const publishNotify = useRecoilValue(publishNotifyAtom);
  const setReceivedNotify = useSetRecoilState(receivedNotifyAtom);
  const prevChatroomId = useRecoilValue(prevChatroomIdAtom);
  const chatroomId = useRecoilValue(chatroomIdAtom);
  const publishChatMessage = useRecoilValue(publishChatMessageAtom);
  const setReceivedMessage = useSetRecoilState(receivedChatMessageAtom);
  const [reloadChatroom, setReloadChatroom] = useRecoilState(reloadChatroomAtom);
  const setReceivedReloadChatroom = useSetRecoilState(receivedReloadChatroomAtom);
  const [unreadMessageCount, setUnreadMessageCount] = useRecoilState(unreadMessageCountAtom);
  const [onlineUsersMemberNo, setOnlineUsersMemberNo] = useRecoilState(onlineUsersMemberNoAtom);

  const [subscribeQueue, setSubscribeQueue] = useState<string []>([]);

  const setReceivedNewNotify = useSetRecoilState(receivedNewNotifyAtom);
  const [publishNewNotify, setPublishNewNotify] = useRecoilState(publishNewNotifyAtom);

  useEffect(() => {
    if (isMounted) return;
    isMounted = true;
    const client = mqtt.connect('wss://talk.supercoin.network:9883')
    client.on('connect', () => {
      setConnectionStatus(true);
      setMqttClient(client);
      if (window.localStorage.getItem('sessionId')) {
        client.subscribe(`/notification/${window.localStorage.getItem('sessionId')}`)
      }
      if (window.localStorage.getItem('referralCode')) {
        messageChannelConnected = true;
        client.subscribe(`/message/${window.localStorage.getItem('referralCode')}`)
      }
      if (window.localStorage.getItem('memberNo')) {
        client.publish(`/connections/${window.localStorage.getItem('memberNo')}/state`, '1')
        client.subscribe(`/notification/${window.localStorage.getItem('memberNo')}`)
      }
      client.subscribe('/connections/+/state')
    })
    window.addEventListener('beforeunload', () => {
      if (window.localStorage.getItem('memberNo')) {
        client.publish(`/connections/${window.localStorage.getItem('memberNo')}/state`, '0')
      }
    })
    client.on('close', () => {
      setConnectionStatus(false);
    })
    client.on('message', (topic, payload) => {
      if(topic.toString() === `/notification/${window.localStorage.getItem('sessionId')}`) {
        const notification: INotification = JSON.parse(payload.toString())
        toast({
          title: '訊息通知',
          description: notification.msg.includes('emoji') ? (
            <span>{JSON.parse(notification.msg)['emoji']}</span>
          ) : (
            notification.msg
          ),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        });
      }
      if (topic.toString() === `/notification/${window.localStorage.getItem('memberNo')}`) {
        setReceivedNewNotify(true);
      }
      if(topic.toString().includes('/message/')) {
        const notification: INotification = JSON.parse(payload.toString())
        setReceivedNotify(notification);
        setUnreadMessageCount(unreadMessageCount => unreadMessageCount + 1)
        toast({
          title: notification.title || '訊息通知',
          description: notification.msg,
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top'
        })
      }
      if(topic.toString().includes('/chat/msg/')) {
        const chatMessage: IChatMessage = JSON.parse(payload.toString())
        setReceivedMessage(chatMessage);
      }
      if (topic.toString().includes('/chatroom/reload/')) {
        setReceivedReloadChatroom(topic.toString().replace('/chatroom/reload/', ''));
      }
      if(topic.toString().includes('/connections/')) {
        // console.log(topic.toString(), payload.toString())
        const memberNo = topic.toString().replace('/connections/', '').replace('/state', '')
        const state = payload.toString()
        const userSet = new Set<string>();
        onlineUsersMemberNo.forEach((onlineMemberNo) => {
          userSet.add(onlineMemberNo)
        })
        if (state === "1") {
          userSet.add(memberNo);
        } else {
          userSet.delete(memberNo);
        }
        setOnlineUsersMemberNo(userSet)
      }
    })
  }, [])

  useEffect(() => {
    if (referralCode.trim().length > 0 && messageChannelConnected === false) {
      messageChannelConnected = true;
      mqttClient?.subscribe(`/message/${referralCode}`)
    }
  }, [messageChannelConnected, mqttClient, referralCode])

  useEffect(() => {
    if (!publishNotify?.toReferralCode || !publishNotify) return;
    mqttClient?.publish(`/message/${publishNotify?.toReferralCode}`, JSON.stringify(publishNotify))
  }, [mqttClient, publishNotify])

  useEffect(() => {
    if (prevChatroomId === chatroomId) return;
    if (prevChatroomId) {
      // console.log(`unsubscribe: ${prevChatroomId}`);
      mqttClient?.unsubscribe(`/chat/msg/${prevChatroomId}`)
    }
    if (chatroomId) {
      const topic = `/chat/msg/${chatroomId}`;
      // console.log(`subscribe: ${chatroomId}`);
      mqttClient?.subscribe(topic)
      mqttClient?.subscribe(`/chatroom/reload/${chatroomId}`)
    }
  }, [prevChatroomId, chatroomId, mqttClient, connected])

  useEffect(() => {
    if (reloadChatroom) {
      mqttClient?.publish(`/chatroom/reload/${reloadChatroom}`, "")
      setReloadChatroom(null);
    }
  }, [reloadChatroom])

  useEffect(() => {
    if (!publishChatMessage) return;
    mqttClient?.publish(`/chat/msg/${chatroomId || publishChatMessage.chatroomId}`, JSON.stringify(publishChatMessage))
  }, [chatroomId, mqttClient, publishChatMessage])

  useEffect(() => {
    if (!publishNewNotify) return;
    mqttClient?.publish(`/notification/${publishNewNotify}`, "")
    setPublishNewNotify(undefined);
  }, [publishNewNotify])

  return (<></>)
}