import { Avatar, Button, Divider, FormControl, FormLabel, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Switch, Textarea, useDisclosure } from "@chakra-ui/react"
import { LuMoreHorizontal, LuMessageCircle } from 'react-icons/lu';
import { IBanner, ICargo, IChatMessage, IChatMessagePayload, IChatMessageThread, IProfile } from "../../interfaces"
import { Utils } from "../../utils"
import { useProjectCode } from "../../hooks/useProjectCode"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { usePrivateApi } from "../../hooks/usePrivateApi"
import { usePublicApi } from "../../hooks/usePublicApi"
import { Translation } from "react-i18next"
import { useApi } from "../../api"
import { RatingBox } from "../BannerReviewBlock/RatingBox"

interface PhotosWallImageBoxProps {
  photo: IBanner,
  onReply?: () => void
}
export const PhotosWallImageBox = ({ photo, onReply }: PhotosWallImageBoxProps) => {
  const projectCode = useProjectCode();
  const navigate = useNavigate();
  const api = useApi(projectCode);
  const publicApi = usePublicApi();
  const privateApi = usePrivateApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [message, setMessage] = useState<string>('');
  const [isPrivate, setPrivate] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [replyId, setReplyId] = useState<string>(photo.image.substring(0, 32));
  const [chatMessages, setChatMessages] = useState<IChatMessage[]>([]);
  const [threads, setThreads] = useState<IChatMessageThread[]>([]);
  const [idChatroomMessage, setIdChatroomMessage] = useState<number>();
  const [replyNumber, setReplyNumber] = useState<number>(0);
  
  const fetchChatroomMessages = () => {
    publicApi.getChatroomMessages(replyId, 100, undefined, (cargo: ICargo) => {
      const msgs: IChatMessage[] = cargo.info;
      if (msgs.length > 0) {
        const msg: IChatMessage = msgs[0];
        setIdChatroomMessage(msg.idChatroomMessage)
      }
      setChatMessages(msgs.filter(msg => !msg.message.includes('img')));
    })
  }

  const loadThreads = (cargo: ICargo) => {
    if (cargo.success) {
      const messageDetail: IChatMessage = cargo.info;
      setThreads(messageDetail.threads ? messageDetail.threads.reverse() : [])
    }
  }

  const fetchThreads = () => {
    if (idChatroomMessage) {
      if (Utils().isLogin()) {
        privateApi.getChatMessageDetail(idChatroomMessage, loadThreads)
      } else {
        api.getChatMessageDetail(idChatroomMessage, loadThreads)
      }
    }
  }

  useEffect(() => {
    if (idChatroomMessage) {
      fetchThreads()
    }
  }, [idChatroomMessage])

  useEffect(() => {
    if (!photo.threadCount) return;
    setReplyNumber(photo.threadCount);
  }, [])

  return (
    <div className="mb-3 relative">
      <img
        className={`w-full rounded-lg cursor-pointer ${
          photo.blur ? 'blur' : ''
        }`}
        src={Utils().normalizeImagePath(photo.image, projectCode)}
        alt={`${photo.idBanner}`}
        onClick={() => {
          if (photo.url) {
            window.open(photo.url);
          } else if (photo.memberNo) {
            navigate(`/${projectCode?.toLowerCase()}/s?c=${photo.memberNo}`);
          }
        }}
        width={photo.width}
        height={photo.height}
      />
      {photo.memberNo && (
        <span
          className="absolute bottom-0 right-0 p-3 cursor-pointer"
          onClick={() => {
            fetchChatroomMessages();
            onOpen();
          }}
        >
          <div className="flex opacity-30 hover:opacity-80">
            {replyNumber > 0 ? (
              <span className="text-white rounded-full bg-black px-1 pb-1 items-center justify-center">
                <Icon
                  as={LuMessageCircle}
                  className="rounded-full p-0.5"
                  fontSize={'xl'}
                  bgColor={'black'}
                  textColor={'white'}
                />
                <span className="ml-3 m-1 text-xs">{replyNumber}</span>
              </span>
            ) : (
              <Icon
                as={LuMoreHorizontal}
                className="rounded-full p-0.5"
                fontSize={'xl'}
                bgColor={'black'}
                textColor={'white'}
              />
            )}
          </div>
        </span>
      )}
      <Modal
        onClose={onClose}
        size={'xl'}
        isOpen={isOpen}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <RatingBox initValue={photo.rating} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col">
            <div className="min-h-[300px] overflow-scroll text-neutral-700 whitespace-break-spaces rounded-lg bg-slate-100 p-3">
              {chatMessages.length === 0 && threads.length === 0 && (
                <div>暫無留言</div>
              )}
              {chatMessages.map((thread) => {
                return (
                  <div
                    key={thread.idChatroomMessage}
                    className="bg-white mt-3 ml-3 p-3 rounded"
                  >
                    <div className="flex justify-between">
                      <Avatar
                        size={'xs'}
                        src={Utils().normalizeImagePath(
                          thread.profile?.avator,
                          projectCode
                        )}
                      />
                    </div>
                    <Divider className="mt-3" />
                    <div className="text-slate-500">{thread.message}</div>
                    <div className="text-xs text-right text-slate-300">
                      {Utils().datetimeFormatter(thread.dateAdd)}
                    </div>
                  </div>
                );
              })}
              {threads.map((thread) => {
                return (
                  <div
                    key={thread.idChatMessageThread}
                    className="bg-white mt-3 ml-3 p-3 rounded"
                  >
                    <div className="flex justify-between">
                      <Avatar
                        size={'xs'}
                        src={Utils().normalizeImagePath(
                          thread.profile?.avator,
                          projectCode
                        )}
                      />
                    </div>
                    <Divider className="mt-3" />
                    <div className="text-slate-500">{thread.message}</div>
                    <div className="text-xs text-right text-slate-300">
                      {Utils().datetimeFormatter(thread.dateAdd)}
                    </div>
                  </div>
                );
              })}
            </div>
            {replyId && (
              <div className="mt-3">
                <Textarea
                  rows={3}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                ></Textarea>
                <FormControl
                  className="mt-2 mx-4 pr-4"
                  display="flex"
                  alignItems="center"
                >
                  <FormLabel htmlFor="email-alerts" mb="0">
                    私訊回覆
                  </FormLabel>
                  <Spacer />
                  <Switch
                    id="is-private-switch"
                    size={'lg'}
                    colorScheme={'yellow'}
                    isChecked={isPrivate}
                    onChange={(e) => {
                      setPrivate(e.target.checked);
                      if (
                        e.target.checked &&
                        !window.localStorage.getItem('avator')
                      ) {
                        setPrivate(false);
                        privateApi.getProfile((cargo: ICargo) => {
                          const profile: IProfile = cargo.info;
                          if (profile.avator) {
                            setPrivate(true);
                            window.localStorage.setItem(
                              'avator',
                              profile.avator
                            );
                          }
                        });
                      }
                    }}
                  />
                </FormControl>
                <div className="mx-4 text-xs text-red-700">
                  若您尚未上傳頭貼、無法使用私訊回覆。
                </div>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            {replyId && message && (
              <Button
                isLoading={processing}
                onClick={() => {
                  if (!message) return;
                  setProcessing(true);
                  const payload: IChatMessagePayload = {
                    type: 'text',
                    content: message,
                  };
                  if (idChatroomMessage) {
                    privateApi.sendChatroomMessageThread(
                      idChatroomMessage,
                      message,
                      isPrivate,
                      (cargo: ICargo) => {
                        setProcessing(false);
                        fetchThreads();
                        setMessage('');
                        if (onReply) {
                          onReply();
                        }
                      }
                    );
                    // privateApi.sendChatroomPrivateMessage(replyId, photo.memberNo, message, JSON.stringify(payload), isPrivate, (cargo: ICargo) => {
                    //   setProcessing(false)
                    //   fetchChatroomMessages()
                    //   setMessage('')
                    //   if (onReply) {
                    //     onReply()
                    //   }
                    // })
                  }
                }}
              >
                {isPrivate ? '只回覆給作者' : '回覆'}
              </Button>
            )}
            {!message && (
              <Button variant="outline" onClick={onClose}>
                <Translation>{(t) => t('close')}</Translation>
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}