import { ICargo, IChatMessage, ICreatorProfile, IProfile } from "../interfaces";

export const usePublicApi = () => {
  return {
    getGeneralHeader() {
      if (!window.localStorage.getItem("token")) {
        window.location.href = `/${window.location.pathname.split('/')[1]}/login`;
        return;
      }
      return {
        'Content-Type': 'application/json',
        'X-Access-Token': window.localStorage.getItem('token') || 'DummyToken',
        'X-Member-ID': window.localStorage.getItem('memberId') || '',
      };
    },
    getMemberId() {
      return window.localStorage.getItem("memberId");
    },
    publicGet(endPoint: string, cb?: Function, apiVersion: number = 6) {
      fetch(`/api/v${apiVersion}/public${endPoint}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          return res.status === 200 ? res.json() : { returnCode: res.status };
        })
        .then((cargo: ICargo) => {
          if (cargo.returnCode === '000000') {
            cargo.success = true;
          }
          if (cb !== undefined) {
            cb(cargo);
          }
        })
        .catch((error) => {
          // throw new Error(error);
          console.error(error);
        });
    },
    publicPost(
      endPoint: string,
      body: any,
      cb?: Function,
      apiVersion: number = 6
    ) {
      fetch(`/api/v${apiVersion}/public${endPoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          return res.status === 200 ? res.json() : { returnCode: res.status };
        })
        .then((cargo) => {
          if (cb !== undefined) {
            cb(cargo);
          }
        })
        .catch((error) => {
          // throw new Error(error);
          console.error(error);
        });
    },
    privateGet(endPoint: string, cb?: Function, apiVersion: number = 6) {
      fetch(`/api/v${apiVersion}/private${endPoint}`, {
        method: 'GET',
        headers: this.getGeneralHeader(),
      })
        .then((res) => {
          return res.status === 200 ? res.json() : { returnCode: res.status };
        })
        .then((cargo) => {
          if (cb !== undefined) {
            cb(cargo);
          }
        })
        .catch((error) => {
          // throw new Error(error);
          console.error(error);
        });
    },
    privateMethod(
      endPoint: string,
      method: string,
      body: any,
      cb?: Function,
      apiVersion: number = 6
    ) {
      fetch(`/api/v${apiVersion}/private${endPoint}`, {
        method: method.toUpperCase(),
        headers: this.getGeneralHeader(),
        body: JSON.stringify(body),
      })
        .then((res) => {
          return res.status === 200 ? res.json() : { returnCode: res.status };
        })
        .then((cargo) => {
          if (cb !== undefined) {
            cb(cargo);
          }
        })
        .catch((error) => {
          // throw new Error(error);
          console.error(error);
        });
    },
    privatePost(
      endPoint: string,
      body: any,
      cb?: Function,
      apiVersion: number = 6
    ) {
      this.privateMethod(endPoint, "POST", body, cb, apiVersion);
    },
    privatePatch(
      endPoint: string,
      body: any,
      cb?: Function,
      apiVersion: number = 6
    ) {
      this.privateMethod(endPoint, "PATCH", body, cb, apiVersion);
    },
    privatePut(
      endPoint: string,
      body: any,
      cb?: Function,
      apiVersion: number = 6
    ) {
      this.privateMethod(endPoint, "PUT", body, cb, apiVersion);
    },
    privateDelete(
      endPoint: string,
      body: any,
      cb?: Function,
      apiVersion: number = 6
    ) {
      this.privateMethod(endPoint, "DELETE", body, cb, apiVersion);
    },
    acceptFriend(memberNo: string, cb?: Function) {
      this.privatePut(`/sns/friends/${this.getMemberId()}/accept/${memberNo}`, {}, cb)
    },
    addUserBanner(image: string, cb?: Function) {
      this.privatePost(`/core/banners/${this.getMemberId()}`, { image }, cb)
    },
    addUserCharacter(idCharacter: Number, cb?: Function) {
      this.privatePost(`/sns/characters/${this.getMemberId()}/${idCharacter}`, {}, cb)
    },
    cardBinding(refNo: string, username: string, password: string, referralCode: string, cb?: Function) {
      if (referralCode?.length > 0) {
        this.publicPost(`/card/bindings/${refNo}`, { username, password, referralCode }, cb)
      } else {
        this.publicPost(`/card/bindings/${refNo}`, { username, password }, cb)
      }
    },
    delUserBanner(idBanner: Number, cb?: Function) {
      this.privateDelete(`/core/banners/${idBanner}/${this.getMemberId()}`, {}, cb);
    },
    delUserCharacter(idCharacter: Number, cb?: Function) {
      this.privateDelete(`/sns/characters/${this.getMemberId()}/${idCharacter}`, {}, cb)
    },
    lineBinding(code: string, redirectUrl: string, cb: Function) {
      this.privatePost(`/partner/line/openid/bind/${this.getMemberId()}`, { code, redirectUrl }, cb)
    },
    lineUnbinding(cb: Function) {
      this.privateDelete(`/partner/line/openid/unbind/${this.getMemberId()}`, {}, cb)
    },
    lineLogin(projectCode: string, code: string, redirectUrl: string, cb: Function) {
      this.publicPost(`/partner/line/openid/login/${projectCode}`, { code, redirectUrl}, (cargo: ICargo) => {
        if (cargo.returnCode === '000000') {
          for (let key in cargo.info)
            if (cargo.info[key])
              localStorage.setItem(key, cargo.info[key])
        }
        if (cb !== undefined) {
          cb(cargo)
        }
      })
    },
    login(username: string, password: string, projectCode: string, cb?: Function) {
      this.publicPost(`/core/sessions/${projectCode}`, { username, password }, (cargo: ICargo) => {
        if (cargo.returnCode === '000000') {
          for (let key in cargo.info)
            if (cargo.info[key])
              localStorage.setItem(key, cargo.info[key])
        }
        if (cb !== undefined) {
          cb(cargo)
        }
      })
    },
    forgetPassword(projectCode: string, email: string, cb?: Function) {
      this.publicPost(`/core/members/${projectCode}/forget/password`, { email }, cb)
    },
    getAssets(projectCode: string, cb?: Function) {
      this.publicGet(`/game/assets/${projectCode}`, cb)
    },
    getBindingInfo(refNo: string, cb?: Function) {
      this.publicGet(`/card/binding/${refNo}`, cb, 7)
    },
    getBindingRecord(cb?: Function) {
      this.privateGet(`/card/binding/${this.getMemberId()}`, cb, 7)
    },
    getCardContacts(refNo: string, cb?: Function) {
      this.publicGet(`/card/contacts/${refNo}`, cb)
    },
    getCharacters(projectCode: string, cb?: Function) {
      this.publicGet(`/sns/characters/${projectCode}`, cb)
    },
    getChatMessages(referralCode: string, cb?: Function) {
      this.privateGet(`/sns/messages/${this.getMemberId()}/${referralCode}`, cb)
    },
    getChatroomList(projectCode: string, cb?: Function) {
      this.publicGet(`/sns/chatrooms/${projectCode}`, cb)
    },
    getChatroomMessages(chatroomId: string, pageSize?: number, memberNo?: string | null, cb?: Function) {
      const params = new URLSearchParams();
      params.append('pageSize', `${pageSize || 500}`);
      if (memberNo) {
        params.append('memberNo', `${memberNo}`);
      }
      if (window.localStorage.getItem('memberId')) {
        this.privateGet(`/sns/chatroom/${chatroomId}/messages?${params}`, (cargo: ICargo) => {
          const chatMessages: IChatMessage[] = cargo.info;
          for (let i = 0; i < chatMessages.length; ++i) {
            chatMessages[i].payload = JSON.parse(`${chatMessages[i].payload}`)
          }
          if (cb) {
            cargo.info = chatMessages;
            cb(cargo)
          }
        })
      } else {
        this.publicGet(`/sns/chatroom/${chatroomId}/messages?${params}`, (cargo: ICargo) => {
          const chatMessages: IChatMessage[] = cargo.info;
          for (let i = 0; i < chatMessages.length; ++i) {
            chatMessages[i].payload = JSON.parse(`${chatMessages[i].payload}`)
          }
          if (cb) {
            cargo.info = chatMessages;
            cb(cargo)
          }
        })
      }
    },
    getChatroomsMessages(chatroomId: string, cb?: Function) {
      this.publicGet(`/sns/chatrooms/${chatroomId}/messages`, (cargo: ICargo) => {
        const chatMessages: IChatMessage[] = cargo.info;
        for (let i = 0 ; i < chatMessages.length ; ++i) {
          chatMessages[i].payload = JSON.parse(`${chatMessages[i].payload}`)
        }
        if (cb) {
          cargo.info = chatMessages;
          cb(cargo)
        }
      })
    },
    getChildrenProfile(cb?: Function) {
      this.privateGet(`/core/referrals/childrens/${this.getMemberId()}`, cb)
    },
    getCreatorProfile(memberNo: string, cb?: Function) {
      this.publicGet(`/creator/profile/${memberNo}`, cb, 7)
    },
    getDna(memberNo: string, cb?: Function) {
      this.publicGet(`/family/dna/${memberNo}`, cb);
    },
    getDnaTopLearning(cb?: Function) {
      this.publicGet(`/family/dna/top/learning`, cb);
    },
    getDnaTopLearningCount(cb?: Function) {
      this.publicGet(`/family/dna/top/learning/count`, cb);
    },
    getDnaTopMoneySpending(cb?: Function) {
      this.publicGet(`/family/dna/top/money-spending`, cb);
    },
    getDnaTopMoneySpendingCount(cb?: Function) {
      this.publicGet(`/family/dna/top/money-spending/count`, cb);
    },
    getDnaTopMoneySaving(cb?: Function) {
      this.publicGet(`/family/dna/top/money-saving`, cb);
    },
    getDnaTopMoneySavingCount(cb?: Function) {
      this.publicGet(`/family/dna/top/money-saving/count`, cb);
    },
    getDnaTopVip(cb?: Function) {
      this.publicGet(`/family/dna/top/vip`, cb);
    },
    getDnaTopVipCount(cb?: Function) {
      this.publicGet(`/family/dna/top/vip/count`, cb);
    },
    getDnaTopVvip(cb?: Function) {
      this.publicGet(`/family/dna/top/vvip`, cb);
    },
    getDnaTopVvipCount(cb?: Function) {
      this.publicGet(`/family/dna/top/vvip/count`, cb);
    },
    getDnaTopBd(cb?: Function) {
      this.publicGet(`/family/dna/top/bd`, cb);
    },
    getDnaTopBdCount(cb?: Function) {
      this.publicGet(`/family/dna/top/bd/count`, cb);
    },
    getDnaTopHandGiving(cb?: Function) {
      this.publicGet(`/family/dna/top/hand-giving`, cb);
    },
    getDnaTopHandGivingCount(cb?: Function) {
      this.publicGet(`/family/dna/top/hand-giving/count`, cb);
    },
    getDnaTopCeoCount(cb?: Function) {
      this.publicGet(`/family/dna/top/ceo/count`, cb);
    },
    getDnaTopSpiritCount(cb?: Function) {
      this.publicGet(`/family/dna/top/spirit/count`, cb);
    },
    getDnaBadLiverGallbladderCount(cb?: Function) {
      this.publicGet(`/family/dna/bad/liver-gallbladder/count`, cb);
    },
    getDnaBadCardiovascularCount(cb?: Function) {
      this.publicGet(`/family/dna/bad/cardiovascular/count`, cb);
    },
    getDnaBadStomachCount(cb?: Function) {
      this.publicGet(`/family/dna/bad/stomach/count`, cb);
    },
    getDnaBadLungThroatCount(cb?: Function) {
      this.publicGet(`/family/dna/bad/lung-throat/count`, cb);
    },
    getDnaBadKidneyCount(cb?: Function) {
      this.publicGet(`/family/dna/bad/kidney/count`, cb);
    },
    getDnaSexy7Count(cb?: Function) {
      this.publicGet(`/family/dna/sexy/7/count`, cb);
    },
    getDnaSexy8Count(cb?: Function) {
      this.publicGet(`/family/dna/sexy/8/count`, cb);
    },
    getDnaSexy9Count(cb?: Function) {
      this.publicGet(`/family/dna/sexy/9/count`, cb);
    },
    getDnaSexy10Count(cb?: Function) {
      this.publicGet(`/family/dna/sexy/10/count`, cb);
    },
    getFavoriteCount(memberNo: string, cb?: Function) {
      this.publicGet(`/core/favorite/count/${memberNo}`, cb)
    },
    getFavoriteList(memberNo: string, cb?: Function) {
      this.publicGet(`/core/favorite/members/${memberNo}`, cb)
    },
    getFavoriteMe(cb?: Function) {
      this.privateGet(`/core/favorite/members/me/${this.getMemberId()}`, cb)
    },
    getFriendCount(memberNo: string, cb?: Function) {
      this.publicGet(`/sns/friends/count/${memberNo}`, cb)
    },
    getFriendList(memberNo: string, cb?: Function) {
      this.publicGet(`/sns/friends/${memberNo}`, cb)
    },
    getFollows(memberNo: string, cb?: Function) {
      this.publicGet(`/sns/follows/${memberNo}`, cb)
    },
    getFollowNumber(memberNo: string, cb?: Function) {
      this.publicGet(`/sns/follow-number/${memberNo}`, cb)
    },
    getGifts(cb?: Function) {
      this.publicGet(`/nft/assets/supercard/gift`, cb)
    },
    getGuestbooks(cb?: Function) {
      this.privateGet(`/sns/guestbooks/${this.getMemberId()}`, cb)
    },
    getHotProfile(projectCode: string, cb?: Function) {
      this.publicGet(`/core/profiles/${projectCode}/hot`, cb)
    },
    getLastChatroomLastMessage(chatroomId: string, cb?: Function) {
      this.publicGet(`/sns/chatrooms/${chatroomId}/messages/last`, cb)
    },
    getLineLoginBindingStatus(cb?: Function) {
      this.privateGet(`/partner/line/openid/bind/${this.getMemberId()}`, cb)
    },
    getLocation(memberNo: string, cb?: Function){
      this.publicGet(`/sns/geolocation/${memberNo}`, cb)
    },
    getMemberCount(projectCode: string, cb?: Function) {
      this.publicGet(`/core/members/count/${projectCode}`, cb)
    },
    getMessages(cb?: Function) {
      this.privateGet(`/sns/messages/${this.getMemberId()}`, cb)
    },
    getMyReferral(cb?: Function) {
      this.privateGet(`/core/members/referral/${this.getMemberId()}`, cb)
    },
    getNearby(geohash: string, idCharacter?: Number | null, cb?: Function) {
      this.publicGet(`/sns/geolocation/nearby/${geohash}${idCharacter? `?idCharacter=${idCharacter}` : ''}`, cb)
    },
    getNewProfile(projectCode: string, cb?: Function) {
      this.publicGet(`/core/profiles/${projectCode}/new`, cb)
    },
    getPaveView(memberNo: string, cb?: Function) {
      this.publicGet(`/sns/characters/pageview/${memberNo}`, cb);
    },
    getPersonalProfile(cb?: Function) {
      this.privateGet(`/core/profiles/${this.getMemberId()}`, cb)
    },
    getProfile(memberNo: string, cb?: Function) {
      this.publicGet(`/creator/profile/${memberNo}`, cb, 7)
    },
    getProfileType(memberNo: string, cb?: Function) {
      this.publicGet(`/core/profiles/type/${memberNo}`, cb)
    },
    getPublicProfile(memberNo: string, cb?: Function) {
      this.publicGet(`/core/profiles/${memberNo}`, cb)
    },
    getPublicProfileMore(refNo: string, cb?: Function) {
      this.publicGet(`/core/profiles/more/${refNo}`, cb)
    },
    getReferral(code: string, cb?: Function) {
      this.publicGet(`/core/referral/${code}`, cb)
    },
    getReferralActivities(activityId: number, cb?: Function) {
      this.publicGet(`/sns/activities/referral/${activityId}`, cb)
    },
    getRefNo(memberNo: string, cb?: Function) {
      this.publicGet(`/card/ref-no/${memberNo}`, cb, 7)
    },
    getUserBanners(memberNo: string, cb?: Function) {
      this.publicGet(`/core/banners/users/${memberNo}`, cb)
    },
    getUserCharacters(cb?: Function) {
      this.privateGet(`/sns/characters/${this.getMemberId()}`, cb)
    },
    getUserPublicCharacters(sharingCode: string | null, cb?: Function) {
      if (sharingCode == null) {
          return
      }
      this.publicGet(`/sns/characters/user/${sharingCode}`, cb)
    },
    getVisitors(memberNo?: string, cb?: Function) {
      this.publicGet(`/sns/visitors/${memberNo}`, cb)
    },
    getVisitorCount(refNo: string, cb?: Function) {
      this.publicGet(`/sns/visitors/count/${refNo}`, cb);
    },
    getWalletAddress(memberNo: string, token: string, cb?: Function) {
      this.publicGet(`/core/wallets/address/${token}/${memberNo}`, cb)
    },
    isFavoriteMember(memberNo: string, cb?: Function) {
      this.privateGet(`/core/favorite/members/${this.getMemberId()}/${memberNo}`, cb)
    },
    leaveMessage(sharingCode: string | null, message: string, cb?: Function) {
      if (sharingCode !== null) {
          this.privatePost(`/sns/guestbooks/${sharingCode}/${this.getMemberId()}`, {message}, cb);
      }
    },
    referralSignup(username: string, password: string, referralCode: string, cb?: Function) {
      this.publicPost(`/core/members/referral-signup`, { username, password, referralCode}, cb)
    },
    referralSignupByLine(referralCode: string, code: string, redirectUrl: string, cb?: Function) {
      this.publicPost(`/partner/line/signup/referral`, { referralCode, code, redirectUrl }, (cargo: ICargo) => {
        if (cargo.returnCode === '000000') {
          for (let key in cargo.info)
            if (cargo.info[key])
              localStorage.setItem(key, cargo.info[key])
        }
        if (cb !== undefined) {
          cb(cargo)
        }
      })
    },
    rejectFriend(memberNo: string, cb?: Function) {
      this.privatePut(`/sns/friends/${this.getMemberId()}/reject/${memberNo}`, {}, cb)
    },
    requestToBeTheFriend(memberNo: string, cb?: Function) {
      this.privatePost(`/sns/friends/${this.getMemberId()}/${memberNo}`, {}, cb)
    },
    searchProfile(keyword: string, projectCode: string, cb?: Function) {
      this.publicGet(`/core/profiles/${projectCode}/search?keyword=${keyword}`, cb)
    },
    sendMessage(referralCode: string, message: string, cb?: Function) {
      this.privatePost(`/sns/messages/${this.getMemberId()}/${referralCode}`, { message }, cb)
    },
    updateAvator(avator: string, cb?:Function) {
      this.privatePatch(`/core/profiles/${this.getMemberId()}`, { avator }, cb)
    },
    updateBirthday(birthday: Date, cb?: Function) {
      this.privatePatch(`/core/profiles/${this.getMemberId()}`, { birthday }, cb)
    },
    updateCreatorProfile(profile: ICreatorProfile, cb?: Function) {
      this.privatePut(`/creator/profile/${this.getMemberId()}`, {
        introduction: profile.introduction,
        websiteUrl: profile.websiteUrl,
        company: profile.company,
        title: profile.title,
        location: profile.location,
        additionalLinkUrl1: profile.additionalLinkUrl1,
        additionalLinkUrl2: profile.additionalLinkUrl2,
        additionalLinkUrl3: profile.additionalLinkUrl3,
        additionalLinkUrl4: profile.additionalLinkUrl4,
        additionalLinkUrl5: profile.additionalLinkUrl5,
        additionalLinkLogo1: profile.additionalLinkLogo1,
        additionalLinkLogo2: profile.additionalLinkLogo2,
        additionalLinkLogo3: profile.additionalLinkLogo3,
        additionalLinkLogo4: profile.additionalLinkLogo4,
        additionalLinkLogo5: profile.additionalLinkLogo5,
        additionalLinkTitle1: profile.additionalLinkTitle1,
        additionalLinkTitle2: profile.additionalLinkTitle2,
        additionalLinkTitle3: profile.additionalLinkTitle3,
        additionalLinkTitle4: profile.additionalLinkTitle4,
        additionalLinkTitle5: profile.additionalLinkTitle5,
        additionalLinkUrl6: profile.additionalLinkUrl6,
        additionalLinkUrl7: profile.additionalLinkUrl7,
        additionalLinkUrl8: profile.additionalLinkUrl8,
        additionalLinkUrl9: profile.additionalLinkUrl9,
        additionalLinkUrl10: profile.additionalLinkUrl10,
        additionalLinkLogo6: profile.additionalLinkLogo6,
        additionalLinkLogo7: profile.additionalLinkLogo7,
        additionalLinkLogo8: profile.additionalLinkLogo8,
        additionalLinkLogo9: profile.additionalLinkLogo9,
        additionalLinkLogo10: profile.additionalLinkLogo10,
        additionalLinkTitle6: profile.additionalLinkTitle6,
        additionalLinkTitle7: profile.additionalLinkTitle7,
        additionalLinkTitle8: profile.additionalLinkTitle8,
        additionalLinkTitle9: profile.additionalLinkTitle9,
        additionalLinkTitle10: profile.additionalLinkTitle10
      }, cb, 7)
    },
    updateForgottenPassword(newPassword: string, cb?: Function) {
      this.privatePut(`/core/members/forget/password/${this.getMemberId()}`, { newPassword}, cb)
    },
    updateMemberFavorite(memberNo: string, cb?: Function) {
      this.privatePut(`/core/favorite/members/${this.getMemberId()}`, { memberNo }, cb)
    },
    updateGeolocation(latitude: number, longitude: number, geohash: string, cb?: Function) {
      this.privatePut(`/geolocation/${this.getMemberId()}`, { latitude, longitude, geohash }, cb, 7)
    },
    updatePassword(oldPassword: string, newPassword: string, cb?: Function) {
      this.privatePut(`/core/members/password/${this.getMemberId()}`, { oldPassword, newPassword}, cb)
    },
    updateProfile(profile: IProfile, cb?: Function) {
      this.privatePatch(`/core/profiles/${this.getMemberId()}`, {
        nickname: profile.nickname,
        gender: profile.gender,
        phone: profile.phone,
        mobile: profile.mobile
      }, cb)
    },
    uploadImageFile(projectCode: string, fileOfBlob: Blob, cb?: Function) {
      const formData = new FormData();
      formData.append('file', fileOfBlob);

      fetch(`/api/v6/private/core/files/${projectCode.toLocaleLowerCase()}/${this.getMemberId()}`, {
          method: 'POST',
          headers: {
              'X-Access-Token': window.localStorage.getItem('token') || 'Dummy'
          },
          body: formData
      }).then(res => res.json())   // you can do something with response
      .catch(error => console.error('Error:', error))
      .then((cargo: ICargo) => {
          if (cb !== undefined) {
              cb(cargo)
          }
      });
    },
    uploadPersonalFile(fileOfBlob: Blob, cb?: Function) {
      const formData = new FormData();
      formData.append("file", fileOfBlob);

      fetch(`/api/v6/private/core/files/${this.getMemberId()}`, {
        method: "POST",
        headers: {
          "X-Access-Token": window.localStorage.getItem("token") || "Dummy",
        },
        body: formData,
      })
        .then((res) => res.json()) // you can do something with response
        .catch((error) => console.error("Error:", error))
        .then((cargo: ICargo) => {
          if (cb !== undefined) {
            cb(cargo);
          }
        });
    },
    visit(sharingCode: string | null, cb?: Function) {
      if (sharingCode !== null && window.localStorage.getItem('token')) {
          this.privatePost(`/sns/visitors/${this.getMemberId()}/${sharingCode}`, {}, cb)
      }
    }
  };
};
