import { Outlet } from "react-router-dom"
import { BottomNavigation } from "../components/BottomNavigation"
import { useProjectCode } from "../hooks/useProjectCode"

export const BottomNavigationLayout = () => {
  const projectCode = useProjectCode();
  return (
    <>
      <Outlet />
      <div className="h-[100px]"></div>
      <BottomNavigation projectCode={projectCode}></BottomNavigation>
    </>
  )
}