import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { ReactChild } from "react";

interface ConfirmButtonProps {
  children: ReactChild;
  title: string;
  description: string;
  isLoading?: boolean;
  onClick?: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
}
export const ConfirmButton = ({ onClick, children, title, description, isLoading, onCancel, onConfirm }: ConfirmButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button
        isLoading={isLoading}
        colorScheme={'white'}
        shadow="lg"
        color={'green.900'}
        width="100%"
        onClick={() => {
          if (onClick) {
            onClick();
          }
          onOpen()
        }}
      >
        {children}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {description}
          </ModalBody>

          <ModalFooter>
            <Button  mr={3} onClick={() => {
              if (onCancel) {
                onCancel()
              }
              onClose()
            }}>
              No
            </Button>
            <Button colorScheme='red' onClick={() => {
              if (onConfirm) {
                onConfirm()
              }
              onClose()
            }}>Yes</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}