import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { chatroomIdAtom } from "../stores/mqtt";

export const useSubscribeChatroomId = (chatroomId: string) => {
  const [prevData, setPrevData] = useRecoilState(chatroomIdAtom);
  
  useEffect(() => {
    if (chatroomId === null) return;
    setPrevData(chatroomId);
  }, [chatroomId, setPrevData])

  return prevData;
}