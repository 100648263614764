import { useState } from 'react';
import { useParams } from "react-router-dom";
import { Translation } from 'react-i18next';
import { t } from "i18next"
import {
  Textarea,
  Button, 
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Slide,
  Input,
} from '@chakra-ui/react'
import { useApi } from '../api'
import { ICargo } from '../interfaces';
import { EditIcon } from '@chakra-ui/icons';

export const TaskDialog = () => {
  const { projectCode } = useParams()
  const api = useApi(projectCode)

  const OverlayConfig = () => (
    <ModalOverlay
      bg='none'
      backdropFilter='auto'
      backdropInvert='80%'
      backdropBlur='2px'
    />
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [overlay, setOverlay] = useState(<OverlayConfig />)
  const [subject, setSubject] = useState('')
  const [description, setDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)

  const submit = () => {
    setIsLoading(true)
    api.createTask(subject, description, (cargo: ICargo) => {
      setIsLoading(false)
      if (cargo.returnCode === '000000') {
        onClose()
        setShowSuccessAlert(true)
        setTimeout(() => {
          setShowSuccessAlert(false)
        }, 5000)
        setSubject('')
        setDescription('')
      }
    })
  }

  return (
    <>
      <div>
        <Button 
          rightIcon={<EditIcon />} 
          colorScheme='teal' 
          variant='outline'
          onClick={() => {
            setOverlay(<OverlayConfig />)
            onOpen()
          }}
        >
          <Translation>
            {t => (t('createTask'))}
          </Translation>
        </Button>
      </div>
      <Modal size={"xs"} isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent>
          <ModalHeader>
            <Translation>
              {t => (t('task'))}
            </Translation>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={6}>
            <div>{t('subject')}</div>
            <Input value={subject} onChange={(e) => {
              setSubject(e.target.value)
            }} />
            <div className='mt-5'>{t('description')}</div>
            <Textarea
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              value={description}
              rows={10}
            />
          </ModalBody>
          <ModalFooter>
            <Button isLoading={isLoading} onClick={submit}>
            <Translation>
              {t => (t('submit'))}
            </Translation>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Slide direction='bottom' in={showSuccessAlert} style={{ zIndex: 10 }}>
        <Alert
          colorScheme='whiteAlpha'
          status='success'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          height='200px'
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Task submitted!
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            Thanks for submitting your task.
            <div className='mb-20'></div>
          </AlertDescription>
        </Alert>
      </Slide>
    </>
  )
}