import { useProjectCode } from "../../hooks/useProjectCode";
import { IChatMessage, IProfile } from "../../interfaces";
import { Utils } from "../../utils";

interface ImageBubbleProps {
  chatMessage: IChatMessage;
  profile: IProfile;
  onClickAvator?: (profile: IProfile) => void;
}
export const ImageBubble = ({
  profile,
  chatMessage,
  onClickAvator,
}: ImageBubbleProps) => {
  const projectCode = useProjectCode();

  return (
    <div className="flex items-start gap-2.5 mb-3">
      <img
        className="w-8 h-8 rounded-full cursor-pointer"
        src={Utils().avatorUrlBuilder(profile)}
        alt={`${profile.nickname} image`}
        onClick={() => {
          if (
            onClickAvator &&
            chatMessage.profile &&
            !chatMessage.profile.blur &&
            !chatMessage.profile.blocked
          ) {
            onClickAvator(chatMessage.profile);
          }
        }}
      />
      <div className="flex flex-col gap-1">
        <div className="flex flex-col w-full max-w-[326px] leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700">
          <div className="flex items-center space-x-2 rtl:space-x-reverse mb-2">
            <span className="text-sm font-semibold text-gray-900 dark:text-white">
              {profile.nickname}
            </span>
            <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
              {Utils().datetimeFormatter(chatMessage.dateAdd) ||
                Utils().datetimeFormatter(new Date())}
            </span>
          </div>
          <p className="text-sm font-normal text-gray-900 dark:text-white">
            {chatMessage.payload?.content}
          </p>
          <div className="group relative my-2.5">
            <div className="absolute w-full h-full bg-gray-900/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
              <button
                data-tooltip-target="download-image"
                className="inline-flex items-center justify-center rounded-full h-10 w-10 bg-white/30 hover:bg-white/50 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50"
                onClick={() => {
                  const url = Utils().normalizeImagePath(
                    chatMessage.payload?.src,
                    projectCode
                  );
                  if (!url) return;
                  fetch(url, {
                    mode: 'no-cors',
                  })
                    .then((resp) => resp.blob())
                    .then((blob) => {
                      let blobUrl = window.URL.createObjectURL(blob);
                      let a = document.createElement('a');
                      a.download = url.replace(/^.*[\\\/]/, '');
                      a.href = blobUrl;
                      document.body.appendChild(a);
                      a.click();
                      a.remove();
                    })
                    .catch(() => alert('An error sorry'));
                }}
              >
                <svg
                  className="w-5 h-5 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 16 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                  />
                </svg>
              </button>
              <div
                id="download-image"
                role="tooltip"
                className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
              >
                Download image
                <div className="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>
            <img
              src={Utils().normalizeImagePath(
                chatMessage.payload?.src,
                projectCode
              )}
              className="rounded-lg"
            />
          </div>
          <span className="text-xs font-normal text-gray-500 dark:text-gray-400">
            Delivered
          </span>
        </div>
      </div>
      <div className="w-10"></div>
    </div>
  );
};
