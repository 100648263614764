import { IAlbum } from "../../interfaces/IAlbum";
import photoIcon from "../../assets/imgs/icons8-photo-96.png";
import { useNavigate } from "react-router-dom";
import { useProjectCode } from "../../hooks/useProjectCode";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Input } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Utils } from "../../utils";
import { TiDelete } from 'react-icons/ti';
import { PrivateCrudApi } from "../../apis/private-crud-api";

interface AlbumFoldersProps {
  albums: IAlbum[];
  onAlbumDeleted?: (album: IAlbum) => void;
}
export const AlbumFolders = ({ albums, onAlbumDeleted }: AlbumFoldersProps) => {
  const projectCode = useProjectCode();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [secretKey, setSecretKey] = useState<string>('');
  const [selectedAlbum, setSelectedAlbum] = useState<IAlbum>();
  const albumApi = PrivateCrudApi('sns', 'albums');

  return (
    <div className="my-5">
      <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2">
        {albums.map((album) => {
          return (
            <div
              key={album.idAlbum}
              className="basis-1/3 shadow-lg rounded-lg pt-3 px-3 cursor-pointer text-center"
            >
              <div className="relative">
                <div className="absolute bottom-auto left-auto right-1 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap text-center align-baseline text-lg font-bold leading-none">
                  <TiDelete
                    onClick={() => {
                      if (!album.idAlbum) return;
                      albumApi.delete(album.idAlbum);
                      if (onAlbumDeleted) {
                        onAlbumDeleted(album);
                      }
                    }}
                  />
                </div>
                <div
                  className="mt-1 w-full"
                  onClick={() => setSelectedAlbum(album)}
                >
                  <div className="flex justify-center w-full text-center">
                    <img src={photoIcon} className="opacity-40" />
                  </div>
                  <div className="text-xs text-gray-400 pl-1 line-clamp-1 my-2">
                    {album.title}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        isOpen={!!selectedAlbum}
        onClose={() => setSelectedAlbum(undefined)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('secretKey')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={secretKey}
              onChange={(e) => setSecretKey(e.target.value)}
              placeholder={t('youCanSkipSecretKeyIfNoNeeded')}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="teal"
              onClick={() => {
                navigate(
                  `/${projectCode.toLocaleLowerCase()}/albums/${
                    selectedAlbum?.idAlbum
                  }/edit?${
                    secretKey ? `secretKey=${Utils().md5(secretKey)}` : ''
                  }`
                );
              }}
            >
              Enter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};