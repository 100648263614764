import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { avatorAtom, memberNoAtom, nicknameAtom, referralCodeAtom } from "../stores/profile";

export const RestoreDataProvider = () => {

  const setReferralCode = useSetRecoilState(referralCodeAtom);
  const setMemberNo = useSetRecoilState(memberNoAtom);
  const setAvator = useSetRecoilState(avatorAtom);
  const setNickname = useSetRecoilState(nicknameAtom);

  useEffect(() => {
    const referralCode = window.localStorage.getItem('referralCode');
    if (referralCode) {
      setReferralCode(referralCode)
    }
  
    const memberNo = window.localStorage.getItem('memberNo');
    if (memberNo) {
      setMemberNo(memberNo)
    }
    
    const avator = window.localStorage.getItem('avator');
    if (avator) {
      setAvator(avator)
    }
  
    const nickname = window.localStorage.getItem('nickname');
    if (nickname) {
      setNickname(nickname)
    }
  }, [setAvator, setMemberNo, setNickname, setReferralCode])

  return (
    <></>
  )
}