import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PublicCrudApi } from "../../apis/public-crud-api";
import { Avatar, Button, Divider, FormControl, FormLabel, Spacer, Switch, Textarea } from "@chakra-ui/react";
import { Utils } from "../../utils";
import { HintBlock } from "../../components/HintBlock";
import { ICargo, IChatMessage, IChatMessageThread, IProfile } from "../../interfaces";
import { useProjectCode } from "../../hooks/useProjectCode";
import { usePrivateApi } from "../../hooks/usePrivateApi";
import { useApi } from "../../api";

export const NotePage = () => {
  const { idChatroomMessage } = useParams();
  const projectCode = useProjectCode();
  const navigate = useNavigate();
  const chatMsgApi = PublicCrudApi('sns', 'chatroom/messages');
  const [chatMsg, setChatMsg] = useState<IChatMessage>();
  const [profile, setProfile] = useState<IProfile>();
  const [content, setContent] = useState<string>('');
  const [threads, setThreads] = useState<IChatMessageThread[]>([]);
  const [message, setMessage] = useState<string>('');
  const [replyId, setReplyId] = useState<string>();
  const localMemberNo = window.localStorage.getItem('memberNo');
  const [isPrivate, setPrivate] = useState<boolean>(false);
  const privateApi = usePrivateApi();
  const api = useApi(projectCode);
  const [processing, setProcessing] = useState<boolean>(false);

  useEffect(() => {
    if (!idChatroomMessage) return;
    chatMsgApi.getById(idChatroomMessage).then(data => {
      setChatMsg(data as IChatMessage);
    })
    setReplyId(idChatroomMessage)
  }, [idChatroomMessage]);

  useEffect(() => {
    if (!chatMsg) return;
    setProfile(chatMsg.profile);
    setContent(chatMsg.message);
    if (chatMsg.threads) {
      setThreads(chatMsg.threads)
    };
    
  }, [chatMsg]);

  const fetchThreads = () => {
    if (replyId) {
      if (Utils().isLogin()) {
        privateApi.getChatMessageDetail(replyId, loadThreads);
      } else {
        api.getChatMessageDetail(replyId, loadThreads);
      }
    }
  };

  const loadThreads = (cargo: ICargo) => {
    if (cargo.success) {
      const messageDetail: IChatMessage = cargo.info;
      setThreads(
        messageDetail.threads
          ? messageDetail.threads.sort(
              (a, b) =>
                (a.idChatMessageThread || 0) - (b.idChatMessageThread || 0)
            )
          : []
      );
    }
  };

  if (!profile) return null;

  return (
    <>
      {profile && (
        <div className="mb-3">
          <Avatar src={Utils().avatorUrlBuilder(profile)} />
          <div>{profile.nickname}</div>
        </div>
      )}
      <div className="min-h-[300px] overflow-scroll text-neutral-700 whitespace-break-spaces rounded-lg bg-slate-100 p-3">
        {profile && (
          <div>
            {profile.avator && profile.nickname
              ? content
              : '該用戶資料不完整，暫時無法顯示內容。'}
          </div>
        )}
        {threads.map((thread) => {
          return (
            <div
              key={thread.idChatMessageThread}
              className="bg-white mt-3 ml-3 p-3 rounded"
            >
              <div className="flex justify-between">
                <Avatar
                  size={'xs'}
                  src={Utils().normalizeImagePath(
                    thread.profile?.avator,
                    projectCode
                  )}
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(
                      `/${projectCode.toLowerCase()}/s?c=${
                        thread.profile?.simpleCode
                      }`
                    );
                  }}
                />
              </div>
              <Divider className="mt-3" />
              <div className="text-slate-500">{thread.message}</div>
              <div className="text-xs text-right text-slate-300">
                {Utils().datetimeFormatter(`${thread.dateAdd}Z`)}
              </div>
            </div>
          );
        })}
      </div>
      {replyId && (
        <div className="mt-3">
          <Textarea
            rows={3}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          ></Textarea>
          <HintBlock
            firstLine="請必務謹慎回覆留言"
            secondLine="版主可能對反感的留言回報封鎖"
          />
          {profile.simpleCode !== localMemberNo && (
            <div>
              <FormControl
                className="mt-2 ml-1 mr-4 pr-4"
                display="flex"
                alignItems="center"
              >
                <FormLabel htmlFor="email-alerts" mb="0">
                  私訊回覆
                </FormLabel>
                <Spacer />
                <Switch
                  id="is-private-switch"
                  size={'lg'}
                  colorScheme={'yellow'}
                  isChecked={isPrivate}
                  onChange={(e) => {
                    setPrivate(e.target.checked);
                    if (
                      e.target.checked &&
                      !window.localStorage.getItem('avator')
                    ) {
                      setPrivate(false);
                      privateApi.getProfile((cargo: ICargo) => {
                        const profile: IProfile = cargo.info;
                        if (profile.avator) {
                          setPrivate(true);
                          window.localStorage.setItem('avator', profile.avator);
                        }
                      });
                    }
                  }}
                />
              </FormControl>
              <div className="ml-1 text-xs text-red-700">
                若您尚未上傳頭貼、無法使用私訊回覆。
              </div>
            </div>
          )}
          {replyId && message && (
            <Button
              className="mt-3 w-full"
              isLoading={processing}
              onClick={() => {
                if (!message) return;
                setProcessing(true);
                privateApi.sendChatroomMessageThread(
                  replyId,
                  message,
                  isPrivate,
                  (cargo: ICargo) => {
                    setProcessing(false);
                    fetchThreads();
                    setMessage('');
                  }
                );
              }}
            >
              {isPrivate ? '只回覆給作者' : '回覆'}
            </Button>
          )}
        </div>
      )}
    </>
  );
}