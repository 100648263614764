import { useToast } from "@chakra-ui/react"
import { useApi } from "../api"
import { ImageUploader } from "./ImageUploader"
import { useProjectCode } from "../hooks/useProjectCode"
import { ICargo, IChatMessage, IChatMessagePayload } from "../interfaces"
import { useTranslation } from "react-i18next"
import { HintBlock } from "./HintBlock"
import { Utils } from "../utils"
import { usePrivateApi } from "../hooks/usePrivateApi"

interface BannerManagementProps {
  memberNo?: string,
  onSuccess?: (file: string) => void
}
export const BannerManagement = ({ memberNo, onSuccess }: BannerManagementProps) => {
  const projectCode = useProjectCode();
  const api = useApi(projectCode);
  const privateApi = usePrivateApi();
  const toast = useToast();
  const { t } = useTranslation();

  const sendChatroomMessage = (file: string) => {
    const replyId: string = file.substring(0, 32)
    const payload: IChatMessagePayload = { type: 'img', src: file };
    const message = JSON.stringify(payload);
    if (memberNo) {
      privateApi.sendChatroomPrivateMessage(replyId, memberNo, message, JSON.stringify(payload), false, Utils().getUuid() ,(cargo: ICargo) => {

      })
    }
  }

  return (
    <>
      <div className="p-3">
        <ImageUploader onUploaded={function (cargo: ICargo, isGraphic: boolean, width: Number, height: Number): void {
          if (cargo.returnCode === "000000") {
            const file = cargo.info['file'];
            api.addUserBanner(file, isGraphic !== true, width, height, (cargo: ICargo) => {
              toast({
                title: 'Complete',
                description: t('uploadSuccessfully'),
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top'
              })
            })
            sendChatroomMessage(file)
            if (onSuccess) {
              onSuccess(file)
            }
          }
        }} />
        <HintBlock firstLine="圖片上傳完畢前，請勿關閉畫面" />
      </div>
    </>
  )
}