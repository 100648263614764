import { Avatar, Badge, Icon, useToast } from '@chakra-ui/react';
import { IChatMessage, IChatMessagePayload, IProfile } from '../../interfaces';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Utils } from '../../utils';
import { useProjectCode } from '../../hooks/useProjectCode';
import { LuChevronUp, LuChevronDown, LuLock } from 'react-icons/lu';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { profileAtom } from '../../stores/profile';
import { ImageBubble } from './ImageBubble';

interface MessageBlockProps {
  chatMessage: IChatMessage;
  onClickNickname?: (profile: IProfile) => void;
  onClickAvator?: (profile: IProfile) => void;
  onDeleteChatMessage?: (chatMessage: IChatMessage) => void;
}
export const MessageBlock = ({
  chatMessage,
  onClickNickname,
  onClickAvator,
  onDeleteChatMessage,
}: MessageBlockProps) => {
  const navigate = useNavigate();
  const profile = useRecoilValue(profileAtom);
  const toast = useToast();

  if (!chatMessage.payload) return null;
  const { t } = useTranslation();
  const chatroomId = 'b3de29d2072b317fc5f8c87a5fbd9564';
  const getMyMemberNo = () => {
    return profile?.simpleCode || window.localStorage.getItem('memberNo');
  };
  const isSelfMessage = () => {
    return (
      chatMessage?.profile?.simpleCode === getMyMemberNo() ||
      chatMessage?.memberNo === getMyMemberNo()
    );
  };
  const projectCode = useProjectCode();
  const [expand, setExpand] = useState<boolean>(
    chatMessage?.payload?.type === 'img'
  );
  const [maskedMsg, setMaskedMsg] = useState<IChatMessagePayload>();

  const evalChatMessage = (chatMessage?: string) => {
    if (chatMessage) {
      let flag = chatMessage.indexOf(`/smp/s?c=`);
      if (flag && flag > -1) {
        const href = `${window.location.protocol}//${
          window.location.host
        }${chatMessage.substring(flag, flag + 19)}`;
        return href;
      }
      flag = chatMessage.indexOf(`/smp/share/`);
      if (flag && flag > -1) {
        const href = `${window.location.protocol}//${
          window.location.host
        }${chatMessage.substr(flag, flag + 19)}`;
        return href;
      }
    }
    return null;
  };

  useEffect(() => {
    if (chatMessage?.isPrivate) {
      setExpand(false);
    }
    if (chatMessage?.toMemberNo === getMyMemberNo()) {
      setExpand(true);
    }
    if (
      chatMessage?.isPrivate &&
      chatMessage?.toMemberNo &&
      chatMessage.toMemberNo !== getMyMemberNo() &&
      !isSelfMessage()
    ) {
      setMaskedMsg({
        type: 'text',
        content: t('privateMessage'),
      });
    }
  }, [chatMessage]);

  if (
    chatMessage.payload.type === 'img' &&
    !isSelfMessage() &&
    chatMessage.profile
  ) {
    return (
      <ImageBubble
        chatMessage={chatMessage}
        profile={chatMessage.profile}
        onClickAvator={onClickAvator}
      />
    );
  }

  if (
    chatMessage.profile && chatMessage.message.startsWith('https') &&
    (chatMessage.message.includes('er.club') ||
      chatMessage.message.includes('tw.club'))
  ) {
    if (
      chatMessage.message.includes('.jpg') ||
      chatMessage.message.includes('.jpeg') ||
      chatMessage.message.includes('.png')
    ) {
      
      const msg: IChatMessage = JSON.parse(JSON.stringify(chatMessage));
      msg.payload = {
        type: 'img',
        content: '',
        src: chatMessage.message,
      };
      return (
        <ImageBubble
          chatMessage={msg}
          profile={chatMessage.profile}
          onClickAvator={onClickAvator}
        />
      );
    }
  }

  return (
    <>
      <div
        className={`mb-5 ${
          !isSelfMessage() ? 'shadow' : expand ? 'shadow' : ''
        } ${
          !expand ? 'rounded-full items-center' : 'rounded-2xl items-start'
        } ${chatMessage.toMemberNo === getMyMemberNo() ? 'bg-[#e6e6cd]' : ''}`}
      >
        <div
          className={`my-1 p-1 gap-2 flex justify-between ${
            !expand
              ? 'rounded-full items-center'
              : 'rounded-t-2xl items-start bg-[#c6c6aa]'
          } ${isSelfMessage() ? 'flex-row-reverse' : ''}`}
        >
          <div
            className={`flex justify-start gap-2 w-full items-center ${
              isSelfMessage() ? 'flex-row-reverse' : ''
            }`}
          >
            <Avatar
              src={Utils().avatorUrlBuilder(chatMessage.profile)}
              size={'sm'}
              className={`${
                chatMessage.profile?.blur
                  ? 'brightness-50 cursor-not-allowed'
                  : 'cursor-pointer'
              }`}
              onClick={() => {
                if (
                  onClickAvator &&
                  chatMessage.profile &&
                  !chatMessage.profile.blur &&
                  !chatMessage.profile.blocked
                ) {
                  onClickAvator(chatMessage.profile);
                }
              }}
            />
            {chatMessage.chatroomId === chatroomId &&
              chatMessage.toProfile?.avator && (
                <Avatar
                  size={'xs'}
                  className={`${
                    !isSelfMessage() ? 'ml-[-22px]' : 'mr-[-22px]'
                  } mt-2 border-2 cursor-pointer`}
                  src={Utils().avatorUrlBuilder(chatMessage.toProfile)}
                  onClick={() => {
                    if (onClickNickname && chatMessage.toProfile) {
                      onClickNickname(chatMessage.toProfile);
                    }
                  }}
                />
              )}
            {chatMessage.isPrivate && (
              <Icon
                as={LuLock}
                fontSize={'xl'}
                bgColor={'red.400'}
                textColor={'white'}
                className={`rounded-full p-1`}
              />
            )}
            {!isSelfMessage() && (
              <Badge
                className="cursor-pointer select-none overflow-hidden max-w-32"
                colorScheme="yellow"
                onClick={() => {
                  if (onClickNickname && chatMessage.profile) {
                    onClickNickname(chatMessage.profile);
                  }
                }}
              >
                @{chatMessage.profile?.nickname || chatMessage.nickname}
              </Badge>
            )}
            {!expand && chatMessage.payload.type === 'text' && (
              <div
                className={`text-sm cursor-pointer ${
                  !expand ? 'line-clamp-1' : ''
                } ${
                  isSelfMessage() ? 'bg-green-100 p-1 rounded-full' : 'grow'
                }`}
                onClick={() => {
                  setExpand((expand) => !expand);
                }}
              >
                <div className={`${isSelfMessage() ? 'px-2' : ''}`}>
                  {maskedMsg?.content || chatMessage.payload?.content}
                </div>
              </div>
            )}
            {!expand &&
              chatMessage.payload.type === 'emoji' &&
              chatMessage.payload.unified && (
                <div
                  className={`${!expand ? 'line-clamp-1' : ''} ${
                    isSelfMessage() ? 'bg-green-100 p-1 px-5 rounded-full' : ''
                  }`}
                >
                  <span>{chatMessage.payload.emoji}</span>
                </div>
              )}
          </div>
          {expand && (
            <div>
              <Icon
                as={expand ? LuChevronUp : LuChevronDown}
                fontSize={'xl'}
                bgColor={'gray.500'}
                textColor={'white'}
                // className={`rounded-full p-1 cursor-pointer ${isSelfMessage() ? expand ? 'opacity-100' : 'opacity-10 hover:opacity-80' : 'opacity-10'}`}
                className="rounded-full p-1 cursor-pointer m-1"
                onClick={() => {
                  setExpand((expand) => !expand);
                }}
              />
            </div>
          )}
        </div>
        {expand && (
          <div>
            <div className="px-3 ml-9">
              {/* {chatMessage.toProfile?.nickname && <div className="text-sm pb-1 text-blue-500">{`@${chatMessage.toProfile?.nickname}`}</div>} */}
              {chatMessage.payload.type === 'text' && (
                <div className="text-gray-700">
                  {maskedMsg?.content || chatMessage.payload?.content}
                  <div
                    className="text-xs cursor-pointer underline"
                    onClick={() => {
                      window.location.href = `${evalChatMessage(
                        chatMessage.payload?.content
                      )}`;
                    }}
                  >
                    {evalChatMessage(chatMessage.payload?.content)}
                  </div>
                </div>
              )}
              {chatMessage.payload.type === 'emoji' &&
                chatMessage.payload.emoji && (
                  <span>chatMessage.payload.emoji</span>
                )}
              {chatMessage.payload.type === 'img' && isSelfMessage() && (
                <div>
                  <img
                    className="flex flex-row-revers rounded-xl w-1/2 md:w-1/4 lg:w-1/6"
                    src={Utils().normalizeImagePath(
                      chatMessage.payload.src,
                      projectCode
                    )}
                  />
                  {isSelfMessage() && (
                    <div
                      className="text-xs px-3 text-gray-500 underline underline-offset-2 cursor-pointer"
                      onClick={() => {
                        if (onDeleteChatMessage) {
                          onDeleteChatMessage(chatMessage);
                        }
                      }}
                    >
                      {t('deleteTheImage')}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="text-xs text-right mt-1 px-3 py-1 text-[#a5a58d] bg-[#6b705c] rounded-b-2xl">
              {Utils().datetimeFormatter(chatMessage.dateAdd) ||
                Utils().datetimeFormatter(new Date())}
            </div>
            {/* <div className="bg-stone-50 rounded-b p-1.5 flex justify-between items-center">
                <div>
                  <Icon
                    as={LuUserSquare}
                    fontSize={'3xl'}
                    bgColor={'green.200'}
                    textColor={'white'}
                    className="rounded-full p-1 cursor-pointer"
                    onClick={() => {
                      navigate(`/${projectCode.toLowerCase()}/s?c${chatMessage.profile?.simpleCode}`)
                    }}
                  />
                </div>
                <div>
                  <div className="text-xs text-gray-500 text-right mt-1">By {chatMessage.profile?.nickname || chatMessage.nickname}</div>
                  <div className="text-xs text-gray-500 text-right mt-1">{Utils().datetimeFormatter(chatMessage.dateAdd) || Utils().datetimeFormatter(new Date())}</div>
                </div>
              </div> */}
          </div>
        )}
      </div>
    </>
  );
};
