import moment from 'moment';
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { IChatMessage, IChatMessagePayload, IMessage, IProfile } from '../interfaces';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { nicknameSelectedAtom } from '../stores/message';
import { useCallback, useEffect, useState } from 'react';
import { Utils } from '../utils';
import { useProjectCode } from '../hooks/useProjectCode';
import { LuChevronUp, LuChevronDown, LuLock } from 'react-icons/lu';
import { useTranslation } from 'react-i18next';
import sendIcon from '../assets/imgs/send.png';
import { usePrivateApi } from '../hooks/usePrivateApi';

interface MessageBlockProps {
  chatMessage?: IChatMessage;
  msg: IMessage;
  fromAvator?: string;
  toAvator?: string;
  link?: string;
  onClickNickname?: (profile: IProfile) => void;
  onClickAvator?: (profile: IProfile) => void;
  onDeleteChatMessage?: (chatMessage: IChatMessage) => void;
  secretKey?: string;
}
export const MessageBlock = ({
  chatMessage,
  msg,
  fromAvator,
  toAvator,
  link,
  onClickNickname,
  onClickAvator,
  onDeleteChatMessage,
  secretKey,
}: MessageBlockProps) => {
  const { t } = useTranslation();
  const privateApi = usePrivateApi();
  const [shadowChatMessage, setShadowChatMessage] = useState<IChatMessage | undefined>(chatMessage);

  const chatroomId = 'b3de29d2072b317fc5f8c87a5fbd9564';
  const getMyMemberNo = useCallback(() => {
    return window.localStorage.getItem('memberNo');
  }, []);
  const isSelfMessage = useCallback(() => {
    return (
      chatMessage?.profile?.simpleCode ===
        window.localStorage.getItem('memberNo') ||
      chatMessage?.memberNo === window.localStorage.getItem('memberNo')
    );
  }, []);
  const projectCode = useProjectCode();
  const navigate = useNavigate();
  const setNickname = useSetRecoilState(nicknameSelectedAtom);
  const [expand, setExpand] = useState<boolean>(
    chatMessage?.payload?.type === 'img' ||
      chatMessage?.payload?.type === 'encodedImage'
  );
  const [decodedImage, setDecodedImage] = useState<string>();
  const [selectedImage, setSelectedImage] = useState<string>();
  const [edit, setEdit] = useState<boolean>(false);

  const nicknameWrapper = useCallback(() => {
    return `@${window.localStorage.getItem('nickname')},`;
  }, []);

  if (
    chatMessage?.isPrivate &&
    chatMessage?.toMemberNo &&
    chatMessage.toMemberNo !== getMyMemberNo() &&
    !isSelfMessage()
  ) {
    chatMessage.payload = {
      type: 'text',
      content: t('privateMessage'),
    };
  }

  const evalChatMessage = (chatMessage?: string) => {
    if (chatMessage) {
      let flag = chatMessage.indexOf(`/smp/s?c=`);
      if (flag && flag > -1) {
        const href = `${window.location.protocol}//${
          window.location.host
        }${chatMessage.substring(flag, flag + 19)}`;
        return href;
      }
      flag = chatMessage.indexOf(`/smp/share/`);
      if (flag && flag > -1) {
        const href = `${window.location.protocol}//${
          window.location.host
        }${chatMessage.substr(flag, flag + 19)}`;
        return href;
      }
    }
    return null;
  };

  const decodeImage = (url: string) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
        const pixelArray: number[][] = [];
        for (let i = 0; i < imageData.length; i += 4) {
          pixelArray.push([
            imageData[i], // Red
            imageData[i + 1], // Green
            imageData[i + 2], // Blue
            imageData[i + 3], // Alpha
          ]);
        }
        const targetImageArray = recovery(
          pixelArray,
          keyToOrderArr(pixelArray.length, `${secretKey}`)
        );
        setDecodedImage(imageArrayToBase64(targetImageArray, img.width, img.height))
      }
    };
    const path = Utils().normalizeImagePath(url, projectCode);
    if (path) {
      img.src = path;
    }
  };

  const imageArrayToBase64 = (
    imageArray: number[][],
    width: number,
    height: number
  ): string => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      const imageData = ctx.createImageData(width, height);
      for (let i = 0; i < width * height; i++) {
        const index = i * 4;
        imageData.data[index] = imageArray[i][0]; // Red
        imageData.data[index + 1] = imageArray[i][1]; // Green
        imageData.data[index + 2] = imageArray[i][2]; // Blue
        imageData.data[index + 3] = 255; // Alpha (opaque)
      }
      ctx.putImageData(imageData, 0, 0);
      return canvas.toDataURL('image/png');
    }
    return '';
  };

  const stringToHash = (str: string): number =>
    Array.from(str).reduce((hash, char) => {
      hash = (hash << 5) - hash + char.charCodeAt(0);
      return hash | 0; // Convert to 32bit integer
    }, 0);

  const recovery = (
    shuffledArray: number[][],
    orderArray: number[]
  ): number[][] => {
    const originalArray: number[][] = new Array(shuffledArray.length);
    orderArray.forEach((index, i) => {
      originalArray[index] = shuffledArray[i];
    });
    return originalArray;
  };

  const seedRandom = (seed: number): (() => number) => {
    const mask = 0xffffffff;
    let m_w = (123456789 + seed) & mask;
    let m_z = (987654321 - seed) & mask;

    return () => {
      m_z = (36969 * (m_z & 65535) + (m_z >>> 16)) & mask;
      m_w = (18000 * (m_w & 65535) + (m_w >>> 16)) & mask;
      let result = ((m_z << 16) + (m_w & 65535)) >>> 0;
      result /= 4294967296;
      return result;
    };
  };

  const keyToOrderArr = (length: number, key: string): number[] => {
    const hash = stringToHash(key);
    const rng = seedRandom(hash);
    const orderArr = Array.from({ length }, (_, index) => index);

    orderArr.forEach((_, i) => {
      const j = Math.floor(rng() * (i + 1));
      [orderArr[i], orderArr[j]] = [orderArr[j], orderArr[i]];
    });

    return orderArr;
  };

  useEffect(() => {
    if (chatMessage?.isPrivate) {
      setExpand(false);
    }
    if (chatMessage?.toMemberNo === getMyMemberNo()) {
      setExpand(true);
    }
    if (
      chatMessage?.payload?.type === 'encodedImage' &&
      chatMessage.payload.src
    ) {
      decodeImage(chatMessage.payload.src);
    }
  }, [chatMessage]);

  if (chatMessage?.payload) {
    return (
      <>
        <div
          className={`mb-5 ${
            !isSelfMessage() ? 'shadow' : expand ? 'shadow' : ''
          } ${
            !expand ? 'rounded-full items-center' : 'rounded-2xl items-start'
          } ${
            chatMessage.toMemberNo === getMyMemberNo() ? 'bg-[#e6e6cd]' : ''
          }`}
        >
          <div
            className={`my-1 p-1 gap-2 flex justify-between ${
              !expand
                ? 'rounded-full items-center'
                : 'rounded-t-2xl items-start bg-[#c6c6aa]'
            } ${isSelfMessage() ? 'flex-row-reverse' : ''}`}
          >
            <div
              className={`flex justify-start gap-2 w-full items-center ${
                isSelfMessage() ? 'flex-row-reverse' : ''
              }`}
            >
              <Avatar
                src={Utils().avatorUrlBuilder(chatMessage.profile)}
                size={'sm'}
                className={`${
                  chatMessage.profile?.blur
                    ? 'brightness-50 cursor-not-allowed'
                    : 'cursor-pointer'
                }`}
                onClick={() => {
                  if (
                    onClickAvator &&
                    chatMessage.profile &&
                    !chatMessage.profile.blur &&
                    !chatMessage.profile.blocked
                  ) {
                    onClickAvator(chatMessage.profile);
                  }
                }}
              />
              {chatMessage.chatroomId === chatroomId &&
                chatMessage.toProfile?.avator && (
                  <Avatar
                    size={'xs'}
                    className={`${
                      !isSelfMessage() ? 'ml-[-22px]' : 'mr-[-22px]'
                    } mt-2 border-2 cursor-pointer`}
                    src={Utils().avatorUrlBuilder(chatMessage.toProfile)}
                    onClick={() => {
                      if (onClickNickname && chatMessage.toProfile) {
                        onClickNickname(chatMessage.toProfile);
                      }
                    }}
                  />
                )}
              {chatMessage.isPrivate && (
                <Icon
                  as={LuLock}
                  fontSize={'xl'}
                  bgColor={'red.400'}
                  textColor={'white'}
                  className={`rounded-full p-1`}
                />
              )}
              {!isSelfMessage() && (
                <Badge
                  className="cursor-pointer overflow-hidden max-w-32"
                  colorScheme="yellow"
                  onClick={() => {
                    if (onClickNickname && chatMessage.profile) {
                      onClickNickname(chatMessage.profile);
                    }
                  }}
                >
                  @{chatMessage.profile?.nickname || chatMessage.nickname}
                </Badge>
              )}
              {!expand && chatMessage.payload.type === 'text' && (
                <div
                  className={`text-sm cursor-pointer ${
                    !expand ? 'line-clamp-1' : ''
                  } ${
                    isSelfMessage() ? 'bg-green-100 p-1 rounded-full' : 'grow'
                  }`}
                  onClick={() => {
                    setExpand((expand) => !expand);
                  }}
                >
                  <div className={`${isSelfMessage() ? 'px-2' : ''}`}>
                    {chatMessage.payload?.content || msg.message}
                  </div>
                </div>
              )}
              {!expand &&
                chatMessage.payload.type === 'emoji' &&
                chatMessage.payload.unified && (
                  <div
                    className={`${!expand ? 'line-clamp-1' : ''} ${
                      isSelfMessage()
                        ? 'bg-green-100 p-1 px-5 rounded-full'
                        : ''
                    }`}
                  >
                    <span>{chatMessage.payload.emoji}</span>
                  </div>
                )}
            </div>
            {expand && (
              <div>
                <Icon
                  as={expand ? LuChevronUp : LuChevronDown}
                  fontSize={'xl'}
                  bgColor={'gray.500'}
                  textColor={'white'}
                  // className={`rounded-full p-1 cursor-pointer ${isSelfMessage() ? expand ? 'opacity-100' : 'opacity-10 hover:opacity-80' : 'opacity-10'}`}
                  className="rounded-full p-1 cursor-pointer m-1"
                  onClick={() => {
                    setExpand((expand) => !expand);
                  }}
                />
              </div>
            )}
          </div>
          {expand && (
            <div>
              <div className="px-3">
                {/* {chatMessage.toProfile?.nickname && <div className="text-sm pb-1 text-blue-500">{`@${chatMessage.toProfile?.nickname}`}</div>} */}
                {chatMessage.payload.type === 'text' && shadowChatMessage && (
                  <div className="text-gray-700">
                    <div className="ml-9">
                      {shadowChatMessage.payload?.content}
                    </div>
                    <div
                      className="text-xs cursor-pointer underline"
                      onClick={() => {
                        window.location.href = `${evalChatMessage(
                          chatMessage.payload?.content
                        )}`;
                      }}
                    >
                      {evalChatMessage(chatMessage.payload?.content)}
                    </div>
                    {isSelfMessage() && (
                      <div
                        className="text-right text-xs text-gray-500 cursor-pointer"
                        onClick={() => {
                          setEdit((edit) => !edit);
                        }}
                      >
                        Edit
                      </div>
                    )}
                    {edit && shadowChatMessage && (
                      <InputGroup size={'lg'} className="mt-5">
                        <Input
                          value={`${shadowChatMessage.message}`}
                          onChange={(e) => {
                            setShadowChatMessage({
                              ...shadowChatMessage,
                              message: e.target.value,
                            });
                          }}
                        />
                        <InputRightAddon
                          children={
                            <img src={sendIcon} alt="send" width={30} />
                          }
                          className="cursor-pointer"
                          onClick={() => {
                            const payload: IChatMessagePayload = {
                              type: 'text',
                              content: shadowChatMessage.message,
                            };
                            setShadowChatMessage({
                              ...shadowChatMessage,
                              payload: payload,
                            });
                            setEdit(false);
                            privateApi.updateChatroomMessage(
                              shadowChatMessage.chatroomId,
                              shadowChatMessage.message,
                              JSON.stringify(payload),
                              `${shadowChatMessage.requestId}`
                            );
                          }}
                        />
                      </InputGroup>
                    )}
                  </div>
                )}
                {chatMessage.payload.type === 'emoji' &&
                  chatMessage.payload.emoji && (
                    <span>{chatMessage.payload.emoji}</span>
                  )}
                {(chatMessage.payload.type === 'img' ||
                  chatMessage.payload.type === 'encodedImage') && (
                  <div>
                    {chatMessage.payload.type === 'img' && (
                      <img
                        className="flex flex-row-revers rounded-xl w-1/2 md:w-1/4 lg:w-1/6 cursor-pointer"
                        src={Utils().normalizeImagePath(
                          chatMessage.payload.src,
                          projectCode
                        )}
                        onClick={() => {
                          if (chatMessage?.payload?.src) {
                            setSelectedImage(
                              Utils().normalizeImagePath(
                                chatMessage.payload.src,
                                projectCode
                              )
                            );
                          }
                        }}
                      />
                    )}
                    {chatMessage.payload.type === 'encodedImage' &&
                    decodedImage ? (
                      <img
                        className="flex flex-row-revers rounded-xl w-1/2 md:w-1/4 lg:w-1/6 cursor-pointer"
                        src={decodedImage}
                        onClick={() => {
                          if (chatMessage?.payload?.src) {
                            setSelectedImage(decodedImage);
                          }
                        }}
                      />
                    ) : (
                      <div className="text-gray-500">
                        系統發生錯誤，無法正常顯示圖片。
                      </div>
                    )}
                    {isSelfMessage() && (
                      <div
                        className="text-xs px-3 text-gray-500 underline underline-offset-2 cursor-pointer"
                        onClick={() => {
                          if (onDeleteChatMessage) {
                            onDeleteChatMessage(chatMessage);
                          }
                        }}
                      >
                        {t('deleteTheImage')}
                      </div>
                    )}
                  </div>
                )}
                {!chatMessage.payload && msg.message}
              </div>
              <div className="text-xs text-right mt-1 px-3 py-1 text-[#a5a58d] bg-[#6b705c] rounded-b-2xl">
                {Utils().datetimeFormatter(chatMessage.dateAdd) ||
                  Utils().datetimeFormatter(new Date())}
              </div>
              {/* <div className="bg-stone-50 rounded-b p-1.5 flex justify-between items-center">
                <div>
                  <Icon
                    as={LuUserSquare}
                    fontSize={'3xl'}
                    bgColor={'green.200'}
                    textColor={'white'}
                    className="rounded-full p-1 cursor-pointer"
                    onClick={() => {
                      navigate(`/${projectCode.toLowerCase()}/s?c${chatMessage.profile?.simpleCode}`)
                    }}
                  />
                </div>
                <div>
                  <div className="text-xs text-gray-500 text-right mt-1">By {chatMessage.profile?.nickname || chatMessage.nickname}</div>
                  <div className="text-xs text-gray-500 text-right mt-1">{Utils().datetimeFormatter(chatMessage.dateAdd) || Utils().datetimeFormatter(new Date())}</div>
                </div>
              </div> */}
            </div>
          )}
          <Modal
            isOpen={selectedImage !== undefined}
            onClose={() => setSelectedImage(undefined)}
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>&nbsp;</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <img src={selectedImage} />
              </ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="teal"
                  variant="outline"
                  onClick={() => setSelectedImage(undefined)}
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      </>
    );
  }

  if (msg.messageType === 'O') {
    return (
      <div className={`p-3 text-right`}>
        <div className="text-sm">
          {msg.message.includes('emoji') ? (
            <div className="flex flex-row-revers justify-end">
              <span>{JSON.parse(msg.message)['emoji']}</span>
            </div>
          ) : msg.message.includes('img') ? (
            <div className="flex flex-row-revers justify-end">
              <img
                className="rounded-xl w-1/2 md:w-1/4 lg:w-1/6 cursor-pointer"
                src={Utils().normalizeImagePath(
                  JSON.parse(msg.message).src,
                  projectCode
                )}
                onClick={() => {
                  setSelectedImage(
                    Utils().normalizeImagePath(
                      JSON.parse(msg.message).src,
                      projectCode
                    )
                  );
                }}
              />
            </div>
          ) : (
            <span className="bg-purple-200 p-2 rounded-md inline-block text-left">
              {msg.message}
            </span>
          )}
        </div>
        <div className="text-xs text-gray-500 mt-2">
          {moment(msg.generateTime).format('YYYY-MM-DD HH:mm')}
        </div>
        <Avatar src={fromAvator} size="xs"></Avatar>
      </div>
    );
  } else {
    return (
      <div className={`p-2 text-left`}>
        <Flex
          className={`p-2 shadow-lg rounded-lg ${
            msg.message.includes(nicknameWrapper()) ? 'bg-red-100' : ''
          }`}
        >
          <Avatar
            className="cursor-pointer"
            src={toAvator}
            onClick={() => {
              if (link) {
                navigate(link);
              }
            }}
          />
          <VStack alignItems={'start'} className="ml-3 pt-1">
            <div className="text-sm">
              {msg.message.includes('emoji') ? (
                <span>{JSON.parse(msg.message)['emoji']}</span>
              ) : msg.message.includes('img') ? (
                <img
                  className="flex flex-row-revers rounded-xl w-1/2 md:w-1/4 lg:w-1/6"
                  src={Utils().normalizeImagePath(
                    JSON.parse(msg.message).src,
                    projectCode
                  )}
                />
              ) : (
                <div>
                  <Badge
                    colorScheme="green"
                    className="cursor-pointer"
                    onClick={() => {
                      if (msg.profile && msg.profile.nickname) {
                        setNickname(msg.profile.nickname);
                        if (onClickNickname) {
                          onClickNickname(msg.profile);
                        }
                      }
                    }}
                  >
                    {msg.nickname}
                  </Badge>{' '}
                  {msg.message}
                </div>
              )}
            </div>
            <div className="text-xs text-gray-500">
              {moment(msg.generateTime).format('YYYY-MM-DD HH:mm')}
            </div>
          </VStack>
        </Flex>
      </div>
    );
  }
};
