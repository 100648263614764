interface HintBlockProps {
  firstLine: string,
  secondLine?: string,
  thirdLine?: string
}
export const HintBlock = ({firstLine, secondLine, thirdLine}: HintBlockProps) => {
  return (
    <div className='pl-1 mt-1 text-xs text-slate-700'>
      <div>{firstLine}</div>
      {secondLine && <div className='text-xs text-slate-500'>{secondLine}</div>}
      {thirdLine && <div className='text-xs text-slate-400'>{thirdLine}</div>}
    </div>
  )
}