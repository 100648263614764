import { ICargo, IChatMessage, ICreatorProfile, IProfile } from "../interfaces";

export const usePrivateApi = () => {
  return {
    getProjectCode() {
      return window.location.pathname.split('/')[1].toUpperCase();
    },
    getGeneralHeader() {
      if (!window.localStorage.getItem('token')) {
        window.location.href = `/${this.getProjectCode().toLowerCase()}/login`;
        return;
      }
      if (window.localStorage.getItem('projectCode')) {
        if (
          window.localStorage.getItem('projectCode') !== this.getProjectCode()
        ) {
          window.location.href = `/${this.getProjectCode().toLowerCase()}/login`;
        }
      }
      return {
        'Content-Type': 'application/json',
        'X-Access-Token': window.localStorage.getItem('token') || 'DummyToken',
        'X-Member-ID': window.localStorage.getItem('memberId') || '',
      };
    },
    getMemberId() {
      return window.localStorage.getItem('memberId');
    },
    publicGet(endPoint: string, cb?: Function, apiVersion: number = 6) {
      fetch(`/api/v${apiVersion}/public${endPoint}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          return res.status === 200 ? res.json() : { returnCode: res.status };
        })
        .then((cargo) => {
          cargo.success = cargo.returnCode === '000000';
          if (cb !== undefined) {
            cb(cargo);
          }
        })
        .catch((error) => {
          // throw new Error(error);
          console.error(error);
        });
    },
    publicPost(
      endPoint: string,
      body: any,
      cb?: Function,
      apiVersion: number = 6
    ) {
      fetch(`/api/v${apiVersion}/public${endPoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          return res.status === 200 ? res.json() : { returnCode: res.status };
        })
        .then((cargo) => {
          if (cb !== undefined) {
            cb(cargo);
          }
        })
        .catch((error) => {
          // throw new Error(error);
          console.error(error);
        });
    },
    privateGet(endPoint: string, cb?: Function, apiVersion: number = 6) {
      fetch(`/api/v${apiVersion}/private${endPoint}`, {
        method: 'GET',
        headers: this.getGeneralHeader(),
      })
        .then((res) => {
          return res.status === 200 ? res.json() : { returnCode: res.status };
        })
        .then((cargo) => {
          cargo.success = cargo.returnCode === '000000';
          if (cb !== undefined) {
            cb(cargo);
          }
        })
        .catch((error) => {
          // throw new Error(error);
          console.error(error);
        });
    },
    privateMethod(
      endPoint: string,
      method: string,
      body: any,
      cb?: Function,
      apiVersion: number = 6
    ) {
      fetch(`/api/v${apiVersion}/private${endPoint}`, {
        method: method.toUpperCase(),
        headers: this.getGeneralHeader(),
        body: JSON.stringify(body),
      })
        .then((res) => {
          return res.status === 200 ? res.json() : { returnCode: res.status };
        })
        .then((cargo) => {
          if (cb !== undefined) {
            cargo.success = cargo.returnCode === '000000';
            cb(cargo);
          }
        })
        .catch((error) => {
          // throw new Error(error);
          console.error(error);
        });
    },
    privatePost(
      endPoint: string,
      body: any,
      cb?: Function,
      apiVersion: number = 6
    ) {
      this.privateMethod(endPoint, 'POST', body, cb, apiVersion);
    },
    privatePatch(
      endPoint: string,
      body: any,
      cb?: Function,
      apiVersion: number = 6
    ) {
      this.privateMethod(endPoint, 'PATCH', body, cb, apiVersion);
    },
    privatePut(
      endPoint: string,
      body: any,
      cb?: Function,
      apiVersion: number = 6
    ) {
      this.privateMethod(endPoint, 'PUT', body, cb, apiVersion);
    },
    privateDelete(
      endPoint: string,
      body: any,
      cb?: Function,
      apiVersion: number = 6
    ) {
      this.privateMethod(endPoint, 'DELETE', body, cb, apiVersion);
    },
    acceptFriend(memberNo: string, cb?: Function) {
      this.privatePut(
        `/sns/friends/${this.getMemberId()}/accept/${memberNo}`,
        {},
        cb
      );
    },
    activateCard(
      amount: number,
      stripeToken: string,
      description?: string,
      cb?: Function
    ) {
      this.privatePost(
        `/stripe/charge/e-card/${this.getMemberId()}`,
        { amount: amount * 100, stripeToken, description },
        cb
      );
    },
    activateVip(type: string, cb?: Function) {
      this.privatePost(
        `/core/profiles/activate/${type}/${this.getMemberId()}`,
        {},
        cb
      );
    },
    addUserBanner(image: string, cb?: Function) {
      this.privatePost(`/core/banners/${this.getMemberId()}`, { image }, cb);
    },
    addUserCharacter(idCharacter: Number, cb?: Function) {
      this.privatePost(
        `/sns/characters/${this.getMemberId()}/${idCharacter}`,
        {},
        cb
      );
    },
    beSubMember(simpleCode: string, cb?: Function) {
      this.privatePut(
        `/sns/hierarchy/${this.getMemberId()}/${simpleCode}`,
        {},
        cb
      );
    },
    bindCard(cardId: string, cb?: Function) {
      this.privatePatch(`/card/bindings/${this.getMemberId()}`, { cardId }, cb);
    },
    drawLotteryActivity(idLotteryTicket: Number, cb?: Function) {
      this.privatePost(
        `/sns/activity-lottery-tickets/draw/${idLotteryTicket}`,
        {},
        cb
      );
    },
    deliveryFlame(memberNo: string, cb?: Function) {
      this.privatePost(
        `/sns/flame/delivery/${this.getMemberId()}/${memberNo}`,
        {},
        cb
      );
    },
    delChatroomMessage(
      idChatroomMessageOrRequestId?: number | string,
      all?: Boolean,
      cb?: Function
    ) {
      if (idChatroomMessageOrRequestId) {
        this.privateDelete(
          `/sns/chatroom/messages/${this.getMemberId()}/${idChatroomMessageOrRequestId}?all=${all}`,
          {},
          cb
        );
      }
    },
    deleteChatroomMessagesByChatroomId(chatroomId: string, cb?: Function) {
      this.privateDelete(`/sns/chatroom/messages/${chatroomId}`, {}, cb);
    },
    delGuestbook(idGuestbook: number, cb?: Function) {
      this.privateDelete(
        `/sns/guestbooks/${this.getMemberId()}/${idGuestbook}`,
        {},
        cb
      );
    },
    delUserBanner(idBanner: Number, cb?: Function) {
      this.privateDelete(
        `/core/banners/${idBanner}/${this.getMemberId()}`,
        {},
        cb
      );
    },
    delUserCharacter(idCharacter: Number, cb?: Function) {
      this.privateDelete(
        `/sns/characters/${this.getMemberId()}/${idCharacter}`,
        {},
        cb
      );
    },
    followMember(memberNo: string, cb?: Function) {
      this.privatePost(`/sns/follow/${this.getMemberId()}/${memberNo}`, {}, cb);
    },
    lineBinding(code: string, redirectUrl: string, cb: Function) {
      this.privatePost(
        `/partner/line/openid/bind/${this.getMemberId()}`,
        { code, redirectUrl },
        cb
      );
    },
    lineUnbinding(cb: Function) {
      this.privateDelete(
        `/partner/line/openid/unbind/${this.getMemberId()}`,
        {},
        cb
      );
    },
    getAvailableActivityTickets(activityId: number, cb?: Function) {
      this.privateGet(
        `/sns/activity-lottery-tickets/search/available/${activityId}`,
        cb
      );
    },
    getBindingRecord(cb?: Function) {
      this.privateGet(`/card/binding/${this.getMemberId()}`, cb, 7);
    },
    getCardContact(title: string, cb?: Function) {
      this.privateGet(`/card/contact/${this.getMemberId()}/${title}`, cb);
    },
    getCardContacts(cb?: Function) {
      this.privateGet(`/card/contacts/${this.getMemberId()}`, cb);
    },
    getCardFolder(cb?: Function) {
      this.privateGet(`/card/folder/${this.getMemberId()}`, cb);
    },
    getChatroomConnection(
      fromMemberNo: string,
      toMemberNo: string,
      cb?: Function
    ) {
      this.privateGet(
        `/sns/chatroom/connections/${this.getMemberId()}/${fromMemberNo}/${toMemberNo}`,
        cb
      );
    },
    getChatroomMessages(
      chatroomId: string,
      pageSize?: number,
      memberNo?: string | null,
      cb?: Function
    ) {
      const params = new URLSearchParams();
      params.append('pageSize', `${pageSize || 500}`);
      if (memberNo) {
        params.append('memberNo', `${memberNo}`);
      }
      this.privateGet(
        `/sns/chatroom/${chatroomId}/messages?${params}`,
        (cargo: ICargo) => {
          const chatMessages: IChatMessage[] = cargo.info;
          for (let i = 0; i < chatMessages.length; ++i) {
            chatMessages[i].payload = JSON.parse(`${chatMessages[i].payload}`);
          }
          if (cb) {
            cargo.info = chatMessages;
            cb(cargo);
          }
        }
      );
    },
    getChatMessageDetail(idChatroomMessage: number | string, cb?: Function) {
      this.privateGet(
        `/sns/chatroom/messages/${this.getMemberId()}/${idChatroomMessage}`,
        cb
      );
    },
    getChatMessages(referralCode: string, cb?: Function) {
      this.privateGet(
        `/sns/messages/${this.getMemberId()}/${referralCode}`,
        cb
      );
    },
    getChatroomsMessages(cb?: Function) {
      this.privateGet(
        `/sns/chatrooms/${this.getMemberId()}/messages`,
        (cargo: ICargo) => {
          const chatMessages: IChatMessage[] = cargo.info;
          for (let i = 0; i < chatMessages.length; ++i) {
            chatMessages[i].payload = JSON.parse(`${chatMessages[i].payload}`);
          }
          if (cb) {
            cargo.info = chatMessages;
            cb(cargo);
          }
        }
      );
    },
    getChildrenProfile(cb?: Function) {
      this.privateGet(`/core/referrals/childrens/${this.getMemberId()}`, cb);
    },
    getChildrenProfileWithRererralCode(referralCode: string, cb?: Function) {
      this.privateGet(
        `/core/profiles/children/${this.getMemberId()}/${referralCode}`,
        cb
      );
    },
    getCoinInfo(cb?: Function) {
      this.privateGet(`/core/pvs/${this.getMemberId()}`, cb);
    },
    getDna(memberNo: string, cb?: Function) {
      this.privateGet(`/family/dna/${memberNo}`, cb);
    },
    getDnaPair(dnaCategory: string, cb?: Function) {
      this.privateGet(`/family/pair/${dnaCategory}/${this.getMemberId()}`, cb);
    },
    getEthTransactions(cb?: Function) {
      this.privateGet(`/partner/eth/transactions/${this.getMemberId()}`, cb);
    },
    getFavoriteList(cb?: Function) {
      this.privateGet(`/core/favorite/members/${this.getMemberId()}`, cb);
    },
    getFavoriteMe(cb?: Function) {
      this.privateGet(`/core/favorite/members/me/${this.getMemberId()}`, cb);
    },
    getFriendCount(memberNo: string, cb?: Function) {
      this.publicGet(`/sns/friends/count/${memberNo}`, cb);
    },
    getFriendList(cb?: Function) {
      this.privateGet(`/sns/friends/${this.getMemberId()}`, cb);
    },
    getFriendStatus(memberNo: string, cb?: Function) {
      this.privateGet(`/sns/friends/${this.getMemberId()}/${memberNo}`, cb);
    },
    getGasTransaction(cb?: Function) {
      this.privateGet(`/core/exchanges/gas/${this.getMemberId()}`, cb);
    },
    getGasTopupTransaction(cb?: Function) {
      this.privateGet(`/core/exchanges/cc/gas/${this.getMemberId()}`, cb);
    },
    getGuestbooks(cb?: Function) {
      this.privateGet(`/sns/guestbooks/${this.getMemberId()}`, cb);
    },
    getGuestbooksHistories(memberNo: string, cb?: Function) {
      this.privateGet(`/sns/guestbooks/${this.getMemberId()}/${memberNo}`, cb);
    },
    getLineLoginBindingStatus(cb?: Function) {
      this.privateGet(`/partner/line/openid/bind/${this.getMemberId()}`, cb);
    },
    getLineNotifyStatus(cb?: Function) {
      this.privateGet(
        `/partner/line/notify/available/${this.getMemberId()}`,
        cb
      );
    },
    getLotteryPrizeByActivityId(activityId: number, cb?: Function) {
      this.privateGet(`/game/lottery-prizes/activity/${activityId}`, cb);
    },
    getMyFollow(cb?: Function) {
      this.privateGet(`/sns/follows/${this.getMemberId()}`, cb);
    },
    getMessages(cb?: Function) {
      this.privateGet(`/sns/messages/${this.getMemberId()}`, cb);
    },
    getMyAssets(cb?: Function) {
      this.privateGet(`/game/assets/${this.getMemberId()}`, cb);
    },
    getMyReferral(cb?: Function) {
      this.privateGet(`/core/members/referral/${this.getMemberId()}`, cb);
    },
    getNnegoTransaction(cb?: Function) {
      this.privateGet(`/core/exchanges/nnego/${this.getMemberId()}`, cb);
    },
    getNftAssets(cb?: Function) {
      this.privateGet(`/nft/assets/${this.getMemberId()}`, cb);
    },
    getPersonalProfile(cb?: Function) {
      this.privateGet(`/core/profiles/${this.getMemberId()}`, cb);
    },
    getProfile(cb?: Function) {
      this.privateGet(`/core/profiles/${this.getMemberId()}`, cb);
    },
    getPv(cb?: Function) {
      this.privateGet(`/core/pvs/${this.getMemberId()}`, cb);
    },
    getReferralCode(cb?: Function) {
      this.privateGet(`/core/members/referral/${this.getMemberId()}`, cb);
    },
    getRefNo(memberNo: string, cb?: Function) {
      this.privateGet(
        `/core/card/bindings/refNo/${this.getMemberId()}/${memberNo}`,
        cb
      );
    },
    getUnreadGuestbookCount(cb?: Function) {
      this.privateGet(`/sns/guestbooks/unread/count/${this.getMemberId()}`, cb);
    },
    getUnreadMessageCount(cb?: Function) {
      this.privateGet(`/sns/messages/unread/count/${this.getMemberId()}`, cb);
    },
    getUnreadChatroomMessageCount(cb?: Function) {
      this.privateGet(
        `/sns/chatrooms/messages/unread/${this.getMemberId()}/count`,
        cb
      );
    },
    getUnreadFriendMessageCount(referralCode: string, cb?: Function) {
      this.privateGet(
        `/sns/messages/unread/count/${this.getMemberId()}/${referralCode}`,
        cb
      );
    },
    getUsdtTransactions(cb?: Function) {
      this.privateGet(`/partner/usdt/transactions/${this.getMemberId()}`, cb);
    },
    getUserCharacters(cb?: Function) {
      this.privateGet(`/sns/characters/${this.getMemberId()}`, cb);
    },
    getWallet(token: string, cb?: Function) {
      this.privateGet(`/core/wallets/${token}/${this.getMemberId()}`, cb);
    },
    giftAsset(idAsset: number, memberNo: string, cb?: Function) {
      this.privatePost(
        `/games/assets/gift/${this.getMemberId()}/${idAsset}/${memberNo}`,
        {},
        cb
      );
    },
    giftNft(nftId: string, otp: string, simpleCode: string, cb?: Function) {
      this.privatePost(
        `/nft/assets/gift/${this.getMemberId()}/${nftId}/${simpleCode}`,
        { otp },
        cb
      );
    },
    giveCard(simpleCode: string, cb?: Function) {
      this.privatePost(
        `/card/folder/${this.getMemberId()}/${simpleCode}`,
        {},
        cb
      );
    },
    isFavoriteMember(memberNo: string, cb?: Function) {
      this.privateGet(
        `/core/favorite/members/${this.getMemberId()}/${memberNo}`,
        cb
      );
    },
    isFollowMember(memberNo: string, cb?: Function) {
      this.privateGet(`/sns/follow/${this.getMemberId()}/${memberNo}`, cb);
    },
    leaveMessage(sharingCode: string | null, message: string, cb?: Function) {
      if (sharingCode !== null) {
        this.privatePost(
          `/sns/guestbooks/${sharingCode}/${this.getMemberId()}`,
          { message },
          cb
        );
      }
    },
    layoffsSubMember(memberNo: string, cb?: Function) {
      this.privateDelete(
        `/sns/hierarchy/${this.getMemberId()}/${memberNo}`,
        {},
        cb
      );
    },
    lineNotifyBinding(code: string | null, redirectUrl: string, cb?: Function) {
      this.privatePost(
        `/partner/line/notify/${this.getMemberId()}`,
        { code, redirectUrl },
        cb
      );
    },
    purchaseAsset(idProducts: number, otp: string, cb?: Function) {
      this.privatePost(
        `/games/assets/trading/${this.getMemberId()}/${idProducts}/gas`,
        { otp },
        cb
      );
    },
    purchaseAssetByStripe(
      amount: number,
      stripeToken: string,
      idProducts: number,
      cb?: Function
    ) {
      this.privatePost(
        `/games/assets/trading/${this.getMemberId()}/${idProducts}/stripe`,
        { amount, stripeToken },
        cb
      );
    },
    purchaseCard(otp: string, cb?: Function) {
      this.privatePost(
        `/card/bindings/purchase/${this.getMemberId()}`,
        { otp },
        cb
      );
    },
    purchaseDna(
      dnaCategory: string,
      number: number,
      otp: string,
      cb?: Function
    ) {
      this.privatePost(
        `/family/pair/${dnaCategory}/${this.getMemberId()}/${number}`,
        { otp },
        cb
      );
    },
    purchaseLotteryTicket(activityId: Number, otp: string, cb?: Function) {
      this.privatePost(
        `/sns/activity-lottery-tickets/${activityId}`,
        { otp },
        cb
      );
    },
    purchasePair(
      dnaCategory: string,
      number: number,
      stripeToken: string,
      description?: string,
      cb?: Function
    ) {
      this.privatePost(
        `/stripe/charge/pair/${dnaCategory}/${this.getMemberId()}/${number}`,
        { amount: number * 100, stripeToken, description },
        cb
      );
    },
    rejectFriend(memberNo: string, cb?: Function) {
      this.privatePut(
        `/sns/friends/${this.getMemberId()}/reject/${memberNo}`,
        {},
        cb
      );
    },
    returnNft(nftId: string, cb?: Function) {
      this.privatePut(
        `/nft/assets/return/${this.getMemberId()}/${nftId}`,
        {},
        cb
      );
    },
    requestNnegoToUsdt(
      address: string,
      amount: number,
      otp: string,
      cb?: Function
    ) {
      this.privatePost(
        `/core/transfer/non-negotiable-usdt/${this.getMemberId()}`,
        { address, amount, otp },
        cb
      );
    },
    requestToBeTheFriend(memberNo: string, cb?: Function) {
      this.privatePost(
        `/sns/friends/${this.getMemberId()}/${memberNo}`,
        {},
        cb
      );
    },
    sendActivityEdm(activityId: number, amount: number, cb?: Function) {
      this.privatePost(`/sns/activities/edm/${activityId}`, { amount }, cb);
    },
    sendActivityEdmTest(activityId: number, amount: number, cb?: Function) {
      this.privatePost(
        `/sns/activities/edm/test/${activityId}`,
        { amount },
        cb
      );
    },
    sendChatroomMessage(
      chatroomId: string,
      message: string,
      payload: string | null,
      requestId: string,
      cb?: Function
    ) {
      this.privatePost(
        `/sns/chatrooms/${chatroomId}/messages/${this.getMemberId()}`,
        { message, payload, requestId },
        cb
      );
    },
    sendChatroomMessageThread(
      idChatroomMessage: number | string,
      message: string,
      isPrivate: boolean,
      cb?: Function
    ) {
      this.privatePost(
        `/sns/chatroom/messages/threads/${this.getMemberId()}`,
        { idChatroomMessage, message, isPrivate },
        cb
      );
    },
    sendChatroomPrivateMessage(
      chatroomId: string,
      toMemberNo: string,
      message: string,
      payload: string,
      isPrivate: boolean,
      requestId: string,
      cb?: Function
    ) {
      this.privatePost(
        `/sns/chatrooms/${chatroomId}/messages/${this.getMemberId()}`,
        { message, toMemberNo, payload, isPrivate, requestId },
        cb
      );
    },
    sendMessage(referralCode: string, message: string, cb?: Function) {
      this.privatePost(
        `/sns/messages/${this.getMemberId()}/${referralCode}`,
        { message },
        cb
      );
    },
    sendOtp(cb?: Function) {
      this.privatePost(
        `/core/otp/${this.getMemberId()}`,
        {},
        (cargo: ICargo) => {
          if (cargo.returnCode === '100003') {
            window.location.href = `/${
              window.location.pathname.split('/')[1]
            }/login`;
          }
          if (cb) {
            cb(cargo);
          }
        }
      );
    },
    topupGas(
      amount: number,
      stripeToken: string,
      description?: string,
      cb?: Function
    ) {
      this.privatePost(
        `/stripe/charge/gas/${this.getMemberId()}`,
        { amount: amount * 100, stripeToken, description },
        cb
      );
    },
    tradingNftByGas(nftId: string, otp: string, cb?: Function) {
      this.privatePost(
        `/nft/assets/trading/${this.getMemberId()}/${nftId}/gas`,
        { otp },
        cb
      );
    },
    tradingNftByNnego(nftId: string, otp: string, cb?: Function) {
      this.privatePost(
        `/nft/assets/trading/${this.getMemberId()}/${nftId}/nnego`,
        { otp },
        cb
      );
    },
    tradingNftByStripe(
      amount: number,
      stripeToken: string,
      nftId: string,
      cb?: Function
    ) {
      this.privatePost(
        `/nft/assets/trading/${this.getMemberId()}/${nftId}/stripe`,
        { amount, stripeToken },
        cb
      );
    },
    tradingNftByUsdt(nftId: string, otp: string, cb?: Function) {
      this.privatePost(
        `/nft/assets/trading/${this.getMemberId()}/${nftId}/usdt`,
        { otp },
        cb
      );
    },
    transferGas(
      simpleCode: string,
      amount: number | string,
      otp: string,
      cb?: Function
    ) {
      this.privatePost(
        `/core/transfer/gas/${this.getMemberId()}`,
        { simpleCode: simpleCode, amount: Number(amount), otp: otp },
        cb
      );
    },
    transferNnego(
      simpleCode: string,
      amount: number | string,
      otp: string,
      cb?: Function
    ) {
      this.privatePost(
        `/core/transfer/non-negotiable/${this.getMemberId()}`,
        { simpleCode: simpleCode, amount: Number(amount), otp: otp },
        cb
      );
    },
    transferUsdt(
      simpleCode: string,
      amount: number | string,
      otp: string,
      cb?: Function
    ) {
      this.privatePost(
        `/core/transfer/usdt/${this.getMemberId()}`,
        { simpleCode: simpleCode, amount: Number(amount), otp: otp },
        cb
      );
    },
    unFollowMember(memberNo: string, cb?: Function) {
      this.privateDelete(
        `/sns/follow/${this.getMemberId()}/${memberNo}`,
        {},
        cb
      );
    },
    updateActivityCheckedIn(
      activityId: number,
      memberNo: string,
      cb?: Function
    ) {
      this.privatePut(
        `/sns/member-activities/${activityId}/checked-in/${memberNo}`,
        cb
      );
    },
    updateAvator(avator: string, cb?: Function) {
      this.privatePatch(`/core/profiles/${this.getMemberId()}`, { avator }, cb);
    },
    updateBirthday(birthday: Date, cb?: Function) {
      this.privatePatch(
        `/core/profiles/${this.getMemberId()}`,
        { birthday },
        cb
      );
    },
    updateBannerSize(
      idBanner: number,
      width: number,
      height: number,
      cb?: Function
    ) {
      this.privatePatch(
        `/core/banners/size/${idBanner}`,
        { width, height },
        cb
      );
    },
    updateCardContact(title: string, content: string, cb?: Function) {
      this.privatePost(
        `/card/contact/${this.getMemberId()}`,
        { title, content },
        cb
      );
    },
    updateCardFavorite(idCardFolder: number, cb?: Function) {
      this.privatePut(
        `/card/folder/favorite/${this.getMemberId()}/${idCardFolder}`,
        {},
        cb
      );
    },
    updateChatroomMessage(
      chatroomId: string,
      message: string,
      payload: string | null,
      requestId: string,
      cb?: Function
    ) {
      this.privatePut(
        `/sns/chatrooms/${chatroomId}/messages/${requestId}`,
        { message, payload, requestId },
        cb
      );
    },
    updateCreatorProfile(profile: ICreatorProfile, cb?: Function) {
      this.privatePut(
        `/creator/profile/${this.getMemberId()}`,
        {
          introduction: profile.introduction,
          websiteUrl: profile.websiteUrl,
          company: profile.company,
          title: profile.title,
          location: profile.location,
          additionalLinkUrl1: profile.additionalLinkUrl1,
          additionalLinkUrl2: profile.additionalLinkUrl2,
          additionalLinkUrl3: profile.additionalLinkUrl3,
          additionalLinkUrl4: profile.additionalLinkUrl4,
          additionalLinkUrl5: profile.additionalLinkUrl5,
          additionalLinkLogo1: profile.additionalLinkLogo1,
          additionalLinkLogo2: profile.additionalLinkLogo2,
          additionalLinkLogo3: profile.additionalLinkLogo3,
          additionalLinkLogo4: profile.additionalLinkLogo4,
          additionalLinkLogo5: profile.additionalLinkLogo5,
          additionalLinkTitle1: profile.additionalLinkTitle1,
          additionalLinkTitle2: profile.additionalLinkTitle2,
          additionalLinkTitle3: profile.additionalLinkTitle3,
          additionalLinkTitle4: profile.additionalLinkTitle4,
          additionalLinkTitle5: profile.additionalLinkTitle5,
        },
        cb,
        7
      );
    },
    updateFriendStatus(memberNo: string, status: string, cb?: Function) {
      this.privatePut(
        `/private/sns/friends/${this.getMemberId()}/${memberNo}`,
        { status },
        cb
      );
    },
    updateMemberFavorite(memberNo: string, cb?: Function) {
      this.privatePut(
        `/core/favorite/members/${this.getMemberId()}`,
        { memberNo },
        cb
      );
    },
    updateGeolocation(
      latitude: number,
      longitude: number,
      geohash: string,
      cb?: Function
    ) {
      this.privatePut(
        `/geolocation/${this.getMemberId()}`,
        { latitude, longitude, geohash },
        cb,
        7
      );
    },
    updatePassword(oldPassword: string, newPassword: string, cb?: Function) {
      this.privatePut(
        `/core/members/password/${this.getMemberId()}`,
        { oldPassword, newPassword },
        cb
      );
    },
    updateProfile(profile: IProfile, cb?: Function) {
      this.privatePatch(
        `/core/profiles/${this.getMemberId()}`,
        {
          nickname: profile.nickname,
          gender: profile.gender,
          phone: profile.phone,
          mobile: profile.mobile,
          defaultAddress: profile.defaultAddress,
        },
        cb
      );
    },
    updateProfileLink(profile: IProfile, cb?: Function) {
      this.privatePatch(
        `/core/profiles/${this.getMemberId()}`,
        {
          skypeId: profile.skypeId,
          wechatId: profile.wechatId,
          instagramId: profile.instagramId,
          telegramId: profile.telegramId,
          twitterId: profile.twitterId,
          dcardId: profile.dcardId,
        },
        cb
      );
    },
    uploadImageFile(projectCode: string, fileOfBlob: Blob, cb?: Function) {
      const formData = new FormData();
      formData.append('file', fileOfBlob);

      fetch(
        `/api/v6/private/core/files/${projectCode.toLocaleLowerCase()}/${this.getMemberId()}`,
        {
          method: 'POST',
          headers: {
            'X-Access-Token': window.localStorage.getItem('token') || 'Dummy',
          },
          body: formData,
        }
      )
        .then((res) => res.json()) // you can do something with response
        .catch((error) => console.error('Error:', error))
        .then((cargo: ICargo) => {
          if (cb !== undefined) {
            cb(cargo);
          }
        });
    },
    uploadPersonalFile(fileOfBlob: Blob, cb?: Function) {
      const formData = new FormData();
      formData.append('file', fileOfBlob);

      fetch(`/api/v6/private/core/files/${this.getMemberId()}`, {
        method: 'POST',
        headers: {
          'X-Access-Token': window.localStorage.getItem('token') || 'Dummy',
        },
        body: formData,
      })
        .then((res) => res.json()) // you can do something with response
        .catch((error) => console.error('Error:', error))
        .then((cargo: ICargo) => {
          if (cb !== undefined) {
            cb(cargo);
          }
        });
    },
    visit(sharingCode: string | null, cb?: Function) {
      if (sharingCode !== null && window.localStorage.getItem('token')) {
        this.privatePost(
          `/sns/visitors/${this.getMemberId()}/${sharingCode}`,
          {},
          cb
        );
      }
    },
  };
};
