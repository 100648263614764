import { useState } from "react";
import { IBanner } from "../../interfaces"
import { PhotosWallImageBox } from "./PhotosWallImageBox"
import { useTranslation } from "react-i18next";

interface PhotosWallProps {
  photos: IBanner[],
  isHalfLayout?: boolean
}
export const PhotosWall = ({ photos, isHalfLayout }: PhotosWallProps) => {
  const { t } = useTranslation();
  const initSize = 10;
  const [loadMore, setLoadMore] = useState<Boolean>(false);
  return (
    <>
      <div
        className={`gap-3 ${
          isHalfLayout
            ? 'columns-2 lg:columns-3'
            : 'columns-2 md:columns-4 lg:columns-6'
        }`}
      >
        {photos
          .sort((a, b) => 0.5 - Math.random())
          // .sort((a, b) => (b.messageCount || 0) - (a.messageCount || 0))
          .slice(0, loadMore ? photos.length : initSize)
          .map((photo) => {
            return <PhotosWallImageBox key={photo.idBanner} photo={photo} />;
          })}
      </div>
      {!loadMore && photos.length > initSize && (
        <div
          className="p-3 border text-center rounded-md shadow my-3 cursor-pointer"
          onClick={() => {
            setLoadMore(true);
          }}
        >
          {t('loadingMore')}
        </div>
      )}
    </>
  );
}