
import { Wrap, WrapItem } from '@chakra-ui/react';
import zhTW from '../assets/flags/Taiwan-01.png';
import enUS from '../assets/flags/United States-01.png';
import i18next from 'i18next';

export const LanguageSelector = () => {
  return (
    <>
      <Wrap>
        <WrapItem className='cursor-pointer' onClick={() => {
          i18next.changeLanguage('zh-TW')
          window.localStorage.setItem('language', 'zh-TW')
        }}>
          <img src={zhTW} alt="zh-TW" width={48} />
        </WrapItem>
        <WrapItem className='cursor-pointer' onClick={() => {
          i18next.changeLanguage('en-US')
          window.localStorage.setItem('language', 'en-US')
        }}>
          <img src={enUS} alt="en-US" width={48} />
        </WrapItem>
      </Wrap>
    </>
  )
}