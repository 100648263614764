import { useState } from 'react';
import {
  Switch, 
  FormControl, 
  FormLabel,
  Spacer
} from '@chakra-ui/react'
import { LockIcon } from '@chakra-ui/icons'

export const CharacterSwitch = (props: any) => {
  const [checked, setChecked] = useState(props.initValue)
  let lockers = []
  for (let i = 0 ; i < props.level ; ++i) {
    lockers.push(<LockIcon key={`lv${i}`} color={'gray.500'}/>)
  }
  
  return (
    <>
      <FormControl display='flex' alignItems='center' className='my-5'>
        <FormLabel mb='0'>
          {props.title}
        </FormLabel>
        {lockers}
        <Spacer />
        <Switch
          isReadOnly={props.readOnly}
          isChecked={checked}
          onChange={(e) => {
            setChecked(!checked)
            props.onValueChange(e)
          }}
          size="lg"
          colorScheme={"yellow"}
        />
      </FormControl>
    </>
  )
}