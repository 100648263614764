import { useNavigate } from "react-router-dom";
import { useProjectCode } from "../../hooks/useProjectCode";
import { IProfile, IUserCharacter } from "../../interfaces";
import { Utils } from "../../utils";
import { Button, Center, Image, Spinner } from '@chakra-ui/react'
import { IoIosMore } from 'react-icons/io';
import { PublicCrudApi } from "../../apis/public-crud-api";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { profileAtom } from "../../stores/profile";

interface MarketPhotoGridProps {
  title: string;
  badgeClass?: string;
  users?: IUserCharacter[];
  profiles?: IProfile[];
  isLoading?: boolean;
  moreBtn?: boolean;
  qualifyOnly?: boolean;
}
export const MarketPhotoGrid = ({
  title,
  badgeClass,
  users,
  profiles,
  isLoading,
  moreBtn = true,
  qualifyOnly = true,
}: MarketPhotoGridProps) => {
  const projectCode = useProjectCode();
  const navigate = useNavigate();
  const profileApi = PublicCrudApi(
    'core',
    `profiles/${projectCode}/multi-gender`
  );
  const myProfile = useRecoilValue(profileAtom);
  const [profileList, setProfileList] = useState<IProfile[]>(profiles || []);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [photoBlur, setPhotoBlur] = useState<boolean>(!myProfile?.qualify);

  useEffect(() => {
    if (profiles) {
      setProfileList(profiles);
    }
  }, [profiles])

  return (
    <>
      <div className="border rounded-lg px-3 pb-3 pt-1 mb-3 shadow">
        <span
          className={`${badgeClass} select-none`}
          onDoubleClick={() => {
            setPhotoBlur(false);
          }}
        >
          {title}
        </span>
        {isLoading && (
          <Center>
            <Spinner />
          </Center>
        )}
        <div className="grid grid-cols-6 md:grid-cols-8 lg:grid-cols-12 gap-2 mt-2">
          {users &&
            users.map((user) => {
              if (!user.avator) return null;
              return (
                <Image
                  key={user.simpleCode}
                  src={Utils().normalizeImagePath(user.avator, projectCode)}
                  className={`rounded cursor-pointer ${
                    photoBlur ? 'blur-sm brightness-75' : ''
                  }`}
                  onClick={() => {
                    navigate(
                      `/${projectCode.toLowerCase()}/s?c=${user.simpleCode}`
                    );
                  }}
                />
              );
            })}
          {profileList &&
            profileList.map((profile, index) => {
              if (qualifyOnly && !profile.avator) return null;
              if (qualifyOnly && !profile.qualify) return null;
              if ((qualifyOnly && (profile.blocked) || profile.blur)) return null;
              return (
                <Image
                  key={`${profile.simpleCode}_${index}`}
                  src={Utils().avatorUrlBuilder(profile)}
                  className={`rounded cursor-pointer ${
                    photoBlur ? ' blur-sm brightness-75' : ''
                  }`}
                  onClick={() => {
                    navigate(
                      `/${projectCode.toLowerCase()}/s?c=${profile.simpleCode}`
                    );
                  }}
                />
              );
            })}
          {moreBtn && (
            <div className="bg-slate-100 rounded place-self-center">
              <Button
                className="p-3"
                isLoading={isProcessing}
                onClick={() => {
                  if (profiles && profiles[0] && profiles[0].multiGender) {
                    setProcessing(true);
                    profileApi
                      .getById(
                        profiles[0].multiGender,
                        new URLSearchParams({
                          pageSize: '99999',
                        })
                      )
                      .then((data) => {
                        setProfileList(data as IProfile[]);
                        setProcessing(false);
                      });
                  }
                }}
              >
                <IoIosMore />
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};