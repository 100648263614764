import { ExternalLinkIcon } from "@chakra-ui/icons"
import { Box, Divider, Flex, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import { t } from "i18next"
import { DateTime } from "luxon"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useApi } from "../api"
import { BottomNavigation } from "../components/BottomNavigation"
import { Logo } from "../components/Logo"
import { TakeUpDialog } from "../components/TakeUpDialog"
import { TaskDialog } from "../components/TaskDialog"
import { ICargo, IMission, ITask } from "../interfaces"

export const TaskPage = () => {
  const { projectCode, tab } = useParams()
  const api = useApi(projectCode)
  const [tabIndex, setTabIndex] = useState(Number(tab))
  const [listItems, setListitems] = useState<[]>()
  const [missionListItems, setMissionListItems] = useState<[]>()
  const [reviewListItems, setReviewListItems] = useState<[]>()
  // const [missions, setMissions] = useState<IMission[]>([])
  // const [reviews, setReviews] = useState<IMission[]>([])
  const navigate = useNavigate()

  const buildMissionList = useCallback((missions: any) => {
    const items = missions.map((mission: IMission) => {
      mission.statusDescription = getStatusDescription(mission.status)
      return (
        <div key={mission.idMission} className='mb-6 divide-y divide-dashed' onClick={() => {
          navigate(`/${projectCode}/mission/${mission.idMission}`);
        }}>
          <div>
            <Flex>
              <div className='font-semibold text-lg'>{mission['subject']}</div>
              <Spacer></Spacer>
              <div className='text-gray-400 text-sm p-1 border rounded cursor-pointer'>
                {mission.statusDescription} <ExternalLinkIcon></ExternalLinkIcon>
              </div>
            </Flex>
            <div className='text-xs text-gray-400'>{DateTime.fromISO(mission['timelog']).toISODate()}</div>
            <Divider></Divider>
            <div className='text-gray-500' style={{whiteSpace: 'pre-wrap'}}>{mission['taskDescription']}</div>
            <Divider></Divider>
            <div className='font-semibold text-lg mt-4'>{t('missionReply')}</div>
            <div className='text-gray-500'>{mission['description'] && mission['description'].trim().length > 0 ? mission['description'] : t('noMissionDescription')}</div>
            <div className='text-right text-white'>
            </div>
          </div>
          <Divider className="mt-2"></Divider>
          <Divider className="mt-1"></Divider>
        </div>
      )
    })
    setMissionListItems(items);
  }, [navigate, projectCode])

  const loadMyMission = useCallback(() => {
    api.getMyMissions((cargo: ICargo) => {
      // setMissions(cargo.info)
      buildMissionList(cargo.info)
    })
  }, [api, buildMissionList])

  const buildReviewList = useCallback((missions: any) => {
    const items = missions.map((mission: IMission) => {
      mission.statusDescription = getStatusDescription(mission.status)
      return (
        <div key={mission.idMission} className='mb-6 divide-y divide-dashed' onClick={() => {
          navigate(`/${projectCode}/review/${mission.idMission}`);
        }}>
          <div>
            <Flex>
              <div className='font-semibold text-lg'>{mission['subject']}</div>
              <Spacer></Spacer>
              <div className='text-gray-400 text-sm p-1 border rounded cursor-pointer'>
                {mission.statusDescription} <ExternalLinkIcon></ExternalLinkIcon>
              </div>
            </Flex>
            <div className='text-xs text-gray-400'>{DateTime.fromISO(mission['timelog']).toISODate()}</div>
            <Divider></Divider>
            <div className='text-gray-500'>{mission['description'] && mission['description'].trim().length > 0 ? mission['description'] : t('noMissionDescription')}</div>
            <div className='text-right text-white'>
            </div>
          </div>
          <Divider className="mt-2"></Divider>
          <Divider className="mt-1"></Divider>
        </div>
      )
    })
    setReviewListItems(items);
  }, [navigate, projectCode])

  const loadMyReview = useCallback(() => {
    api.getReviewMissions((cargo: ICargo) => {
      // setReviews(cargo.info)
      buildReviewList(cargo.info)
    })
  }, [api, buildReviewList])

  const getStatusDescription = (status: string) => {
    let statusDescription = ''
    if (status === 'P') { statusDescription = t('processing')}
    if (status === 'F') { statusDescription = t('reviewing')}
    if (status === 'C') { statusDescription = t('confirmed')}
    return statusDescription
  }

  useEffect(() => {
    setTabIndex(Number(tab))
    if (!listItems) {
      api.getTasks((cargo: ICargo) => {
        const items = cargo.info.map((task: ITask) =>
          <div key={task.idTask} className='mb-6 divide-y divide-dashed'>
            <div>
              <div className='font-semibold text-lg'>{task['subject']}</div>
              <div className='text-xs text-gray-400'>{DateTime.fromISO(task['timelog']).toISODate()}</div>
              <Divider></Divider>
              <div className='text-gray-500 whitespace-pre-wrap'>
                {task['description']} ...
              </div>
              <div className='text-right text-white'>
                <TakeUpDialog
                  idTask={task.idTask}
                  subject={task.subject}
                  description={task.description}
                  timelog={task.timelog}
                  onSuccess={(mission: IMission) => {
                    loadMyMission()
                  }}
                ></TakeUpDialog>
              </div>
            </div>
            <Divider className="mt-2"></Divider>
            <Divider className="mt-1"></Divider>
          </div>
        )
        setListitems(items)
      })
    }
    if (!missionListItems) {
      loadMyMission()
    }
    if (!reviewListItems) {
      loadMyReview()
    }
  }, [api, listItems, loadMyMission, loadMyReview, missionListItems, reviewListItems, tab]);

  return (
    <>
      <Flex>
        <Box maxW='36'>
          <h1 className="text-3xl font-bold">
            <Logo></Logo>
          </h1>
        </Box>
        <Spacer />
        <Box p='4'>
          <TaskDialog></TaskDialog>
        </Box>
      </Flex>
      <Tabs isFitted variant='enclosed' className='shadow rounded-b-xl' defaultIndex={tabIndex}>
        <TabList mb='1em'>
          <Tab onClick={() => {
            setTabIndex(0)
            navigate(`/${projectCode}/task/0`);
          }}>{t('allTask')}</Tab>
          <Tab onClick={() => {
            setTabIndex(1)
            navigate(`/${projectCode}/task/1`);
            loadMyMission()
          }}>{t('myTask')}</Tab>
          <Tab onClick={() => {
            setTabIndex(2)
            navigate(`/${projectCode}/task/2`);
            loadMyReview()
          }}>{t('review')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {listItems}
          </TabPanel>
          <TabPanel>
            {missionListItems}
          </TabPanel>
          <TabPanel>
            {reviewListItems}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <BottomNavigation projectCode={projectCode}></BottomNavigation>
    </>
  )
}