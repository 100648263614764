import { InfoOutlineIcon } from "@chakra-ui/icons"
import { Popover, PopoverTrigger, Avatar, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, PopoverFooter, ButtonGroup, Button, useDisclosure } from "@chakra-ui/react"
import { Utils } from "../../utils"
import { ISubMember } from "../../interfaces"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

interface SubMemberBoxProps {
  subMember: ISubMember,
  actionPopup: boolean,
  onSale?: () => void,
  onFree?: () => void
}

export const SubMemberBox = ({
  subMember,
  actionPopup,
  onSale,
  onFree,
}: SubMemberBoxProps) => {
  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [freeConfirmed, setFreeConfirmed] = useState<boolean>(false);
  if (!actionPopup) {
    return (
      <Avatar
        className="m-1 cursor-pointer"
        key={subMember.profile.idProfile}
        src={Utils().avatorUrlBuilder(subMember.profile)}
        name={subMember.profile.nickname}
        onClick={() => {
          navigate(
            `/${subMember.profile.projectCode?.toLowerCase()}/share/${
              subMember.profile.simpleCode
            }`
          );
        }}
      />
    );
  }
  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Avatar
          className="m-1 cursor-pointer"
          key={subMember.profile.idProfile}
          src={Utils().avatorUrlBuilder(subMember.profile)}
          name={subMember.profile.nickname}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader className="flex items-center">
          <InfoOutlineIcon className="mr-2" />
          {subMember.profile.nickname}
        </PopoverHeader>
        <PopoverBody>
          <div className="p-3 whitespace-break-spaces text-stone-600 max-h-48 overflow-y-scroll">
            {subMember.profile.introduction}
          </div>
        </PopoverBody>
        <PopoverFooter display={'flex'} justifyContent={'end'}>
          <ButtonGroup size="sm">
            {!confirmed && (
              <Button
                colorScheme="gray"
                onClick={() => {
                  setConfirmed(true);
                }}
              >
                市集拍賣
              </Button>
            )}
            {confirmed && (
              <Button
                colorScheme="red"
                onClick={() => {
                  if (onSale) {
                    onSale();
                  }
                  onClose();
                }}
              >
                確認拍賣
              </Button>
            )}
            {!freeConfirmed && (
              <Button
                colorScheme="gray"
                onClick={() => {
                  setFreeConfirmed(true);
                }}
              >
                放他自由
              </Button>
            )}
            {freeConfirmed && (
              <Button
                colorScheme="red"
                onClick={() => {
                  if (onFree) {
                    onFree();
                  }
                  onClose();
                }}
              >
                確認自由
              </Button>
            )}
            <Button
              colorScheme="blue"
              onClick={() => {
                navigate(
                  `/${subMember.profile.projectCode?.toLowerCase()}/share/${
                    subMember.profile.simpleCode
                  }`
                );
              }}
            >
              個人首頁
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};