import { Icon } from "@chakra-ui/icons";
import { IPhoto } from "../../interfaces/IPhoto";
import { Utils } from "../../utils";
import { RiDeleteBin3Line } from 'react-icons/ri';
import { useEffect, useState } from "react";
import { PrivateCrudApi } from "../../apis/private-crud-api";

interface ImageBoxProps {
  photo: IPhoto;
  secretKey?: string;
  onDeleted?: (photo: IPhoto) => void;
}
export const ImageBox = ({ photo, secretKey, onDeleted }: ImageBoxProps) => {
  const [decodedImage, setDecodedImage] = useState<string>();
  const photoApi = PrivateCrudApi('sns', 'photos')

  const deletePhoto = () => {
    if (!photo.idPhoto) return;
    
    photoApi.delete(photo.idPhoto);
    if (onDeleted) {
      onDeleted(photo);
    }
  }

  const decodeImage = (url: string) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
        const pixelArray: number[][] = [];
        for (let i = 0; i < imageData.length; i += 4) {
          pixelArray.push([
            imageData[i], // Red
            imageData[i + 1], // Green
            imageData[i + 2], // Blue
            imageData[i + 3], // Alpha
          ]);
        }
        const targetImageArray = recovery(
          pixelArray,
          keyToOrderArr(pixelArray.length, `${secretKey}`)
        );
        setDecodedImage(
          imageArrayToBase64(targetImageArray, img.width, img.height)
        );
      }
    };
    const path = Utils().normalizeImagePath(url);
    if (path) {
      img.src = path;
    }
  };

  const imageArrayToBase64 = (
    imageArray: number[][],
    width: number,
    height: number
  ): string => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      const imageData = ctx.createImageData(width, height);
      for (let i = 0; i < width * height; i++) {
        const index = i * 4;
        imageData.data[index] = imageArray[i][0]; // Red
        imageData.data[index + 1] = imageArray[i][1]; // Green
        imageData.data[index + 2] = imageArray[i][2]; // Blue
        imageData.data[index + 3] = 255; // Alpha (opaque)
      }
      ctx.putImageData(imageData, 0, 0);
      return canvas.toDataURL('image/png');
    }
    return '';
  };

  const stringToHash = (str: string): number =>
    Array.from(str).reduce((hash, char) => {
      hash = (hash << 5) - hash + char.charCodeAt(0);
      return hash | 0; // Convert to 32bit integer
    }, 0);

  const recovery = (
    shuffledArray: number[][],
    orderArray: number[]
  ): number[][] => {
    const originalArray: number[][] = new Array(shuffledArray.length);
    orderArray.forEach((index, i) => {
      originalArray[index] = shuffledArray[i];
    });
    return originalArray;
  };

  const seedRandom = (seed: number): (() => number) => {
    const mask = 0xffffffff;
    let m_w = (123456789 + seed) & mask;
    let m_z = (987654321 - seed) & mask;

    return () => {
      m_z = (36969 * (m_z & 65535) + (m_z >>> 16)) & mask;
      m_w = (18000 * (m_w & 65535) + (m_w >>> 16)) & mask;
      let result = ((m_z << 16) + (m_w & 65535)) >>> 0;
      result /= 4294967296;
      return result;
    };
  };

  const keyToOrderArr = (length: number, key: string): number[] => {
    const hash = stringToHash(key);
    const rng = seedRandom(hash);
    const orderArr = Array.from({ length }, (_, index) => index);

    orderArr.forEach((_, i) => {
      const j = Math.floor(rng() * (i + 1));
      [orderArr[i], orderArr[j]] = [orderArr[j], orderArr[i]];
    });

    return orderArr;
  };

  useEffect(() => {
    decodeImage(photo.filePath)
  }, [])

  return (
    <div className="mb-3 relative">
      {secretKey ? (
        decodedImage && (
          <img
            className={`w-full rounded-lg cursor-pointer`}
            src={decodedImage}
            alt={`${photo.description}`}
            onClick={() => {}}
          />
        )
      ) : (
        <img
          className={`w-full rounded-lg cursor-pointer`}
          src={Utils().normalizeImagePath(photo.filePath)}
          alt={`${photo.description}`}
          onClick={() => {}}
        />
      )}
      <span
        className="absolute bottom-0 right-0 p-3 cursor-pointer"
        onClick={deletePhoto}
      >
        <Icon
          as={RiDeleteBin3Line}
          className="rounded-full p-0.5 opacity-30 hover:opacity-80"
          fontSize={'xl'}
          bgColor={'black'}
          textColor={'white'}
        />
      </span>
    </div>
  );
};
