import { UnlockIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Spacer, Textarea } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Translation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useApi } from "../api"
import { BottomNavigation } from "../components/BottomNavigation"
import { Logo } from "../components/Logo"
import { ICargo, IMission } from "../interfaces"

export const ReviewPage = () => {
    const { projectCode, idMission } = useParams()
    const navigate = useNavigate()
    const api = useApi(projectCode)
    const [isLoading, setIsLoading] = useState(false)
    const [subject, setSubject] = useState('')
    const [description, setDescription] = useState('')
    const [remark, setRemark] = useState('')
    const [mission, setMission] = useState<IMission>()
    
    useEffect(() => {
        if (idMission && !mission) {
            api.getMyMission(idMission, (cargo: ICargo) => {
                if(cargo.returnCode === '000000') {
                    setMission(cargo.info);
                    setSubject(cargo.info['subject'])
                    setDescription(cargo.info['description'] === null ? '' : cargo.info['description'])
                    setRemark(cargo.info['remark'] === null ? '' : cargo.info['remark'])
                }
            })
        }
    }, [api, idMission, mission]);
    
    const submit = () => {
        setIsLoading(true)
        if (idMission) {
            api.confirmMission(Number(idMission), remark, (cargo: ICargo) => {
                setIsLoading(false)
            })
        }
    }
    return (
        <>
            <Flex>
                <Box maxW='36'>
                    <h1 className="text-3xl font-bold">
                        <Logo></Logo>
                    </h1>
                </Box>
                <Spacer />
                <Box p='4'>
                    {
                        mission?.status === 'P' && <Button
                            rightIcon={<UnlockIcon />}
                            colorScheme='teal'
                            variant='outline'
                            onClick={() => {
                                if (idMission) {
                                    setIsLoading(true)
                                    api.submitMission(Number(idMission), description, (cargo: ICargo) => {
                                        setIsLoading(false)
                                        mission.status = 'F'
                                        setMission(mission)
                                    })
                                }
                            }}
                        >
                            <Translation>
                                {t => (t('submitToReview'))}
                            </Translation>
                        </Button>
                    }
                </Box>
            </Flex>
            <div className='my-3 font-bold p-3'>{subject}</div>
            <div className='p-3 shadow-lg rounded-lg'>
            <div className='p-3 whitespace-pre-wrap'>{ description }</div>
            </div>
            <div className='p-3 shadow-lg rounded-lg'>
                {
                    mission?.status === 'F' ? <Textarea
                    onChange={(e) => {
                        setRemark(e.target.value)
                    }}
                    value={remark}
                    rows={10}
                    placeholder='Please provide your feedback'
                /> : <div className='p-3 whitespace-pre-wrap'>{ remark }</div>
                }
            </div>
            <div className='pt-3'>
                <Flex>
                    <Button
                        isLoading={isLoading}
                        colorScheme={'white'}
                        shadow="lg"
                        color={'green.900'}
                        width="100%"
                        onClick={() => {
                            navigate(`/${projectCode}/task/2`)
                        }}
                    >
                        <Translation>
                            {t => (t('back'))}
                        </Translation>
                    </Button>
                    {
                        mission?.status === 'F' && <Button
                            isLoading={isLoading}
                            colorScheme={'white'}
                            shadow="lg"
                            color={'green.900'}
                            width="100%"
                            onClick={submit}
                        >
                            <Translation>
                                {t => (t('submit'))}
                            </Translation>
                        </Button>
                    }
                </Flex>
            </div>
            <BottomNavigation projectCode={projectCode}></BottomNavigation>
        </>
    )
}