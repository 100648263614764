import { MessageInput } from "./MessageInput";

import { useEffect, useState } from "react";

import { Drawer, DrawerOverlay, DrawerContent, DrawerHeader, Flex, Spacer, CloseButton, DrawerBody, DrawerFooter, Button, Avatar, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { ImageUploader } from "../ImageUploader";
import { ICargo, IProfile } from "../../interfaces";
import { Icon } from '@chakra-ui/react'
import { LuUserCheck2, LuSmile, LuImage, LuLock, LuUnlock, LuIterationCw, LuView, LuEyeOff, LuEye, LuMessageCircle, LuBell, LuBellOff } from "react-icons/lu";
import { Utils } from "../../utils";
import { useProjectCode } from "../../hooks/useProjectCode";
import { HintBlock } from "../HintBlock";
import { useRecoilValue } from "recoil";
import { profileAtom } from "../../stores/profile";

interface SendMessageBoxProps {
  onSend?: (message: string) => void;
  onKycFilter?: (value: boolean) => void;
  onPrivateMsgFilter?: (value: boolean) => void;
  messageTo?: IProfile;
  onCancelMessageTo?: () => void;
  onDisableRollToBottom?: (value: boolean) => void;
  onClickHome?: (profile: IProfile) => void;
  onClickPrivateChatroom?: (profile: IProfile) => void;
  onClickNotification?: (value: boolean) => void;
  imageNeedKyc?: boolean;
  secretKey?: string;
  showPrivateIcon?: boolean;
}
export const SendMessageBox = ({
  onSend,
  onKycFilter,
  onPrivateMsgFilter,
  onDisableRollToBottom,
  messageTo,
  onCancelMessageTo,
  onClickHome,
  onClickPrivateChatroom,
  onClickNotification,
  imageNeedKyc,
  secretKey,
  showPrivateIcon,
}: SendMessageBoxProps) => {
  const toast = useToast();
  const profile = useRecoilValue(profileAtom);
  const projectCode = useProjectCode();
  const [showDrawer, setDrawer] = useState<boolean>(false);
  const [disableRollToBottom, setDisableRollToBottom] = useState<boolean>(
    window.localStorage.getItem('disableRollToBottom') === 'true' || false
  );
  const [showKycOnly, setKycOnly] = useState<boolean>(
    window.localStorage.getItem('showKycOnly') === 'true' || false
  );
  const [enableNotification, setNotification] = useState<boolean>(
    window.localStorage.getItem('enableNotification') === 'true' || false
  );
  const [isPrivate, setPrivate] = useState<boolean>(
    window.localStorage.getItem('setIsPrivate') === 'true' || false
  );
  const [showPrivateMsg, setShowPrivateMsg] = useState<boolean>(
    window.localStorage.getItem('showPrivateMsg') === 'true' || false
  );

  useEffect(() => {
    window.localStorage.setItem('showKycOnly', `${showKycOnly}`);
    if (onKycFilter) {
      onKycFilter(showKycOnly);
    }
  }, [showKycOnly]);

  useEffect(() => {
    window.localStorage.setItem('showPrivateMsg', `${showPrivateMsg}`);
    if (onPrivateMsgFilter) {
      onPrivateMsgFilter(showPrivateMsg);
    }
  }, [showPrivateMsg]);

  useEffect(() => {
    window.localStorage.setItem(
      'disableRollToBottom',
      `${disableRollToBottom}`
    );
    if (onDisableRollToBottom) {
      onDisableRollToBottom(disableRollToBottom);
    }
  }, [disableRollToBottom]);

  useEffect(() => {
    window.localStorage.setItem('enableNotification', `${enableNotification}`);
    if (onClickNotification) {
      onClickNotification(enableNotification);
    }
  }, [enableNotification]);

  useEffect(() => {
    window.localStorage.setItem('setIsPrivate', `${isPrivate}`);
  }, [isPrivate]);

  useEffect(() => {
    if (!messageTo) return;
    window.localStorage.setItem('messageTo', JSON.stringify(messageTo));
  }, [messageTo]);

  return (
    <>
      <div className="border rounded p-1">
        <MessageInput
          onSend={(message) => {
            if (onSend) {
              onSend(message);
            }
          }}
        />
        <div className="py-1"></div>
        <div className="flex justify-between px-2">
          <div className="flex gap-2">
            <Icon
              as={LuIterationCw}
              fontSize={'2xl'}
              bgColor={!disableRollToBottom ? 'green.400' : 'gray.400'}
              textColor={'white'}
              className="rounded-full p-0.5 cursor-pointer"
              onClick={() => {
                setDisableRollToBottom(!disableRollToBottom);
              }}
            />
            {/* <Icon
              as={enableNotification ? LuBell : LuBellOff}
              fontSize={'2xl'}
              bgColor={enableNotification ? 'green.400' : 'gray.400'}
              textColor={'white'}
              className="rounded-full p-0.5 cursor-pointer"
              onClick={() => {
                const nextState = !enableNotification;
                if (nextState) {
                  Notification.requestPermission().then((result) => {
                    if (result === 'granted') {
                      setNotification(nextState)
                    }
                  });
                } else {
                  setNotification(nextState)
                }
              }}
            /> */}
            {showPrivateIcon && <Icon
              as={showPrivateMsg ? LuEye : LuEyeOff}
              fontSize={'2xl'}
              bgColor={showPrivateMsg ? 'green.400' : 'gray.400'}
              textColor={'white'}
              className="rounded-full p-0.5 cursor-pointer"
              onClick={() => {
                setShowPrivateMsg(!showPrivateMsg);
              }}
            />}
            {onKycFilter && (
              <Icon
                as={LuUserCheck2}
                fontSize={'2xl'}
                bgColor={showKycOnly ? 'green.400' : 'gray.400'}
                textColor={'white'}
                className="rounded-full p-0.5 cursor-pointer"
                onClick={() => {
                  setKycOnly(!showKycOnly);
                }}
              />
            )}
            <Icon
              as={LuImage}
              fontSize={'2xl'}
              textColor={'gray.500'}
              className="cursor-pointer"
              onClick={() => {
                if (imageNeedKyc) {
                  if (profile?.kycStatus && profile?.kycStatus > 0) {
                    setDrawer(true);
                  } else {
                    toast({
                      title: '功能限制',
                      description: '本功能須先通過聖火驗證',
                      status: 'error',
                      duration: 9000,
                      isClosable: true,
                    });
                  }
                } else {
                  setDrawer(true);
                }
              }}
            />
          </div>
          {messageTo && (
            <div className="gap-2 flex items-center">
              <Icon
                as={LuView}
                fontSize={'2xl'}
                bgColor={'gray.400'}
                textColor={'white'}
                className="rounded-full p-1 cursor-pointer"
                onClick={() => {
                  if (onClickHome) {
                    onClickHome(messageTo);
                  }
                }}
              />
              <Icon
                as={LuMessageCircle}
                fontSize={'2xl'}
                bgColor={'gray.400'}
                textColor={'white'}
                className="rounded-full p-1 cursor-pointer mr-3"
                onClick={() => {
                  if (onClickPrivateChatroom) {
                    onClickPrivateChatroom(messageTo);
                  }
                }}
              />
              <Icon
                as={isPrivate ? LuLock : LuUnlock}
                fontSize={'2xl'}
                bgColor={isPrivate ? 'red.600' : 'gray.400'}
                textColor={'white'}
                className="rounded-full p-1 cursor-pointer"
                onClick={() => {
                  setPrivate((isPrivate) => !isPrivate);
                }}
              />
              <Avatar
                src={Utils().normalizeImagePath(messageTo.avator, projectCode)}
                size={'xs'}
                className="cursor-pointer"
                onClick={() => {
                  if (onCancelMessageTo) {
                    onCancelMessageTo();
                  }
                  window.localStorage.removeItem('messageTo');
                }}
              />
            </div>
          )}
        </div>
        <Drawer
          placement={'bottom'}
          onClose={() => setDrawer(false)}
          isOpen={showDrawer}
        >
          <DrawerOverlay />
          <DrawerContent className="rounded-t">
            <DrawerHeader borderBottomWidth="1px">
              <Flex>
                <Spacer></Spacer>
                <CloseButton onClick={() => setDrawer(false)} />
              </Flex>
            </DrawerHeader>
            <DrawerBody>
              <ImageUploader
                onUploaded={(cargo: ICargo) => {
                  const file: string = cargo.info['file'];
                  setDrawer(false);
                  if (onSend) {
                    onSend(JSON.stringify({ type: secretKey ? 'encodedImage' : 'img', src: file }));
                  }
                }}
                secretKey={secretKey}
              />
              <HintBlock firstLine="圖片上傳完畢前，請勿關閉畫面" />
            </DrawerBody>
            <DrawerFooter borderTopWidth="1px">
              <Button variant="outline" onClick={() => setDrawer(false)}>
                {t('close')}
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </div>
    </>
  );
};