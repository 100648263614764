import { useState } from 'react';
import { useParams } from "react-router-dom";
import { Translation } from 'react-i18next';
import { t } from "i18next"
import {
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
} from '@chakra-ui/react'
import { useApi } from '../api'
import { ICargo } from '../interfaces';
import { DateTime } from 'luxon';
import {v4 as uuidv4} from 'uuid';

export const TakeUpDialog = (props: any) => {
  const { projectCode } = useParams()
  const api = useApi(projectCode)

  const OverlayConfig = () => (
    <ModalOverlay
      bg='none'
      backdropFilter='auto'
      backdropInvert='80%'
      backdropBlur='2px'
    />
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [overlay, setOverlay] = useState(<OverlayConfig />)

  const [description, setDescription] = useState(`${props.description} ...`)

  const getDescription = () => {
    api.takeUpTask(props.idTask, (cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        setDescription(cargo.info['description'])
        cargo.info['idMission'] = uuidv4()
        props.onSuccess(cargo.info);
      }
    })
  }

  return (
    <>
      <div>
        <span className='border p-2 rounded-lg bg-black text-xs cursor-pointer' onClick={() => {
        setOverlay(<OverlayConfig />)
        onOpen()
      }}>
          {t('takeUp')}
        </span>
      </div>
      <Modal size={"xs"} isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent>
          <ModalHeader>
            <Translation>
              {t => (t('task'))}
            </Translation>
            <div className='text-xs text-gray-400'>{DateTime.fromISO(props.timelog).toISODate()}</div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={6}>
            <div className='font-bold'>{t('subject')}</div>
            <div className='text-gray-500'>{props.subject}</div>
            <div className='mt-5 font-bold'>{t('description')}</div>
            <div className='text-gray-500 whitespace-pre-wrap'>{description}</div>
            <div className='text-center mt-5'>
              <Button onClick={getDescription}>
                {t('unlockTheDescription')}
              </Button>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className='text-xs text-gray-400'>{t('unlockNotice')}</div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}