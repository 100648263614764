import { get, post, put, remove } from './makeRequest';

export const PublicCrudApi = (module: string, resource: string) => {
  return {
    getAll: async <T>() => {
      return await get<T>(`/api/v6/public/${module}/${resource}`);
    },
    getById: async <T>(id: number | string, params?: URLSearchParams) => {
      if (params) {
        return await get<T>(`/api/v6/public/${module}/${resource}/${id}?${params}`);
      }
      return await get<T>(`/api/v6/public/${module}/${resource}/${id}`);
    },
    countById: async <T>(id: number | string) => {
      return await get<T>(`/api/v6/public/${module}/${resource}/${id}/count`);
    },
    create: async <T>(newObj: T) => {
      return await post<T>(`/api/v6/public/${module}/${resource}`, {...newObj});
    },
    update: async <T>(id: number, newObj: T) => {
      return await put<T>(`/api/v6/public/${module}/${resource}/${id}`, {...newObj});
    },
    delete: async (id: number) => {
      return await remove(`/api/v6/public/${module}/${resource}/${id}`);
    }
  }
}