import { useEffect, useState } from "react"
import { StarGray } from "./StarGray"
import { StarYellow } from "./StarYellow"
import { Icon } from "@chakra-ui/icons"
import { LuBan } from "react-icons/lu"

interface RatingBoxProps {
  onRating?: (rating: number) => void
  initValue?: number
}
export const RatingBox = ({ onRating, initValue }: RatingBoxProps) => {
  const [rating, setRating] = useState<number|undefined>(initValue ? initValue : undefined);
  const [ratingArr, setRatingArr] = useState<boolean[]>([false, false, false, false, false]);
  const [blocked, setBlocked] = useState<boolean>(false);

  useEffect(() => {
    if (rating === undefined) return;
    const arr = [];
    for (let i = 0; i < ratingArr.length ; ++i) {
      arr.push(i <= rating)
    }
    setRatingArr(arr);
    if (onRating) {
      onRating(rating + 1);
    }
  }, [rating])

  return (
    <div className="mt-3 flex justify-center">
      <div className="flex items-center space-x-1">
        <Icon
          as={LuBan}
          fontSize={'lg'}
          bgColor={blocked ? 'red.500' : 'gray.400'}
          textColor={'white'}
          className="rounded-full mr-3 mt-0.5 cursor-pointer"
          onClick={() => {
            setBlocked(true)
            setRating(-1)
            setRatingArr([false, false, false, false, false])
          }}
        />
        {
          ratingArr.map((value, index) => {
            return <span 
              key={index}
              onClick={() => {
                if (onRating) {
                  setRating(index)
                }
                setBlocked(false)
              }}
              className={`p-1 ${onRating ? 'cursor-pointer' : ''}`}
            >
              {value ? <StarYellow /> : <StarGray />}
            </span>              
          })
        }
      </div>
    </div>
  )
}