import { get, post, put, remove, patch } from './makeRequest';

export const PrivateCrudApi = <T>(module: string, resource: string) => {
  return {
    getAll: async <T>() => {
      return await get<T>(`/api/v6/private/${module}/${resource}`);
    },
    getById: async <T>(id: number | string) => {
      return await get<T>(`/api/v6/private/${module}/${resource}/${id}`);
    },
    create: async <T>(newObj: T) => {
      return await post<T>(`/api/v6/private/${module}/${resource}`, {
        ...newObj,
      });
    },
    update: async <T>(id: number | string, newObj: T) => {
      return await put<T>(`/api/v6/private/${module}/${resource}/${id}`, {
        ...newObj,
      });
    },
    partialUpdate: async <T>(id: number | string, newObj: T) => {
      return await patch<T>(`/api/v6/private/${module}/${resource}/${id}`, {
        ...newObj,
      });
    },
    delete: async (id: number) => {
      return await remove(`/api/v6/private/${module}/${resource}/${id}`);
    },
    deleteMapping: async (id1: number, id2: number) => {
      return await remove(
        `/api/v6/private/${module}/${resource}/${id1}/${id2}`
      );
    },
  };
}